import { ReactComponent as Casper } from "./casper.svg";
import { ReactComponent as Unstoppable } from "./unstopable.svg";
import { ReactComponent as Unisat } from "./unisat.svg";
import { ReactComponent as Binance } from "./binance.svg";
import { ReactComponent as Leather } from "./leather.svg";
import { ReactComponent as Polygon } from "./polygon.svg";
import { ReactComponent as NearDark } from "./near-dark.svg";
import { ReactComponent as NearLight } from "./near-light.svg";
import { ReactComponent as XrplSidechainDark } from "./xrpl-sidechain-dark.svg";
import { ReactComponent as XrplSidechainLight } from "./xrpl-sidechain-light.svg";
import { ReactComponent as Linae } from "./linae.svg";
import { ReactComponent as LinaeDark } from "./linaeDark.svg";
import { ReactComponent as Base } from "./base-logo-in-blue.svg";

const WalletIcons = {
    Casper,
    Unstoppable,
    Unisat,
    Binance,
    Leather,
    Polygon,
    NearDark,
    NearLight,
    XrplSidechainDark,
    XrplSidechainLight,
    Linae,
    LinaeDark,
    Base
};

export default WalletIcons;
