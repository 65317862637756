import { BoxProps } from "@chakra-ui/react";
import { IShopDesign, ShopThemeEnum } from "lib/models/shop";

interface IThemes {
    [propname: string]: {
        box: BoxProps
        active: {
            box: string
            textColor: string
        }
    }
}
namespace productSizesModel {
    export const themes = (shopDesign: IShopDesign): IThemes => {
        const defaults: IThemes = {
            dark: {
                box: { border: `1px solid ${shopDesign.textColorParagraphs}` },
                active: {
                    box: shopDesign?.foreground || "#FFF",
                    textColor: "#333"
                }
            },
            light: {
                box: { border: "1px solid #333" },
                active: {
                    box: "#333",
                    textColor: "#fff"
                }
            }
        }
        return {
            [ShopThemeEnum.THEME_1]: defaults.dark,
            [ShopThemeEnum.THEME_2]: defaults.dark,
            [ShopThemeEnum.THEME_SKALE]: defaults.light,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: defaults.light,
            [ShopThemeEnum.THEME_CASPER]: defaults.light,
            [ShopThemeEnum.THEME_POLYGON]: defaults.light,
        }
    }
}

export default productSizesModel