import WalletIcons from 'assets/icons/wallets/WalletIcons';
import { useProfile } from 'hooks/useProfile/useProfile';
import { WALLET_TYPES } from 'lib/models/user';

export interface Iwallets {
    [propname: string]: {
        wallet: string
        chains: {
            [propname: string]: {
                caption: string
                method: Function
                Icon: {
                    dark: any
                    light: any
                }
            }
        }
    }
}

function useWeb3Hook() {
    const { loginCasperWallet, loginViaUnstoppable, loginViaStacks, EVMLogin, loginViaXumm } = useProfile();

    const wallets: Iwallets = {
        leather: {
            wallet: 'Leather',
            chains: {
                stack: {
                    caption: 'Stacks',
                    method: async () => await loginViaStacks(),
                    Icon: { dark: WalletIcons.Leather, light: WalletIcons.Leather },
                }
            }
        },
        casper: {
            wallet: 'Casper',
            chains: {
                casper: {
                    caption: 'Casper Wallet - Casper',
                    method: async () => await loginCasperWallet(),
                    Icon: { dark: WalletIcons.Casper, light: WalletIcons.Casper },
                }
            }
        },
        multiwallet: {
            wallet: 'Multi wallet',
            chains: {
                unstoppabledomains: {
                    caption: 'Unstoppabledomains',
                    method: async () => await loginViaUnstoppable(),
                    Icon: { dark: WalletIcons.Unstoppable, light: WalletIcons.Unstoppable },
                }
            }
        },
        xumm: {
            wallet: 'Xumm',
            chains: {
                xrpl: {
                    caption: 'XRPL',
                    method: async () => await loginViaXumm(),
                    Icon: { dark: WalletIcons.XrplSidechainDark, light: WalletIcons.XrplSidechainLight }
                }
            }
        },
        metamask: {
            wallet: 'Metamask',
            chains: {
                base: {
                    caption: 'Base',
                    method: async () => await EVMLogin(WALLET_TYPES.BASE),
                    Icon: { dark: WalletIcons.Base, light: WalletIcons.Base }
                },
                polygon: {
                    caption: 'Polygon',
                    method: async () => await EVMLogin(WALLET_TYPES.POLYGON),
                    Icon: { dark: WalletIcons.Polygon, light: WalletIcons.Polygon }
                },
                sidechain: {
                    caption: 'XRPL',
                    method: async () => await EVMLogin(WALLET_TYPES.XRPLSIDECHAIN),
                    Icon: { dark: WalletIcons.XrplSidechainDark, light: WalletIcons.XrplSidechainLight }
                },
                binance: {
                    caption: 'Binance',
                    method: async () => await EVMLogin(WALLET_TYPES.BINANCE),
                    Icon: { dark: WalletIcons.Binance, light: WalletIcons.Binance }
                },
                near: {
                    caption: 'Near',
                    method: async () => await EVMLogin(WALLET_TYPES.NEAR),
                    Icon: { dark: WalletIcons.NearDark, light: WalletIcons.NearLight }
                },
                linae: {
                    caption: 'Linea',
                    method: async () => await EVMLogin(WALLET_TYPES.LINEA),
                    Icon: { dark: WalletIcons.LinaeDark, light: WalletIcons.Linae }
                }
            }
        }
    }

    return { wallets }
}

export default useWeb3Hook