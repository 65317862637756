import { Box, useDisclosure } from '@chakra-ui/react';
import AppTypography from 'components/common/typography/AppTypography';
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate';
import { useProfile } from 'hooks/useProfile/useProfile';
import { useShopStore } from 'lib/stores/shop/shop';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import HeaderBoxHover from '../parts/modal/HeaderBoxHover';
import NFTModal from './parts/modal/NFTModal';

function ProfileHeader() {
    const { profile, logout } = useProfile()
    const { shopDesign } = useShopStore()
    const { route } = useCustomNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const items = useMemo(() => {
        const address = profile?.walletAddress || profile?.publicKey
        return [
            {
                caption: `${address?.slice(0, 4)}...${address?.slice(-4)}`,
            },
            {
                caption: 'Purchase History',
                link: route('purchase-history')
            },
            // {
            //     caption: 'My NFTs',
            //     onClick: onOpen
            // },
            {
                caption: 'Logout',
                onClick: logout
            }
        ]
    }, [profile])

    return (
        <>
            <HeaderBoxHover>
                {items.map((el, key) => {
                    const Tag = el.link ? Link : Box
                    return (
                        <Tag
                            key={key}
                            to={el?.link || ''}
                            cursor={el?.link || el?.onClick ? "pointer" : 'auto'}
                            {...el.onClick && { onClick: el.onClick }}
                            style={{
                                background: shopDesign.backgroundBody,
                                border: '1px solid ' + shopDesign.textColorParagraphs,
                                padding: "8px",
                                borderRadius: "8px"
                            }}
                        >
                            <AppTypography textAlign="center">{el.caption}</AppTypography>
                        </Tag>
                    )
                })}
            </HeaderBoxHover>
            <NFTModal isOpen={isOpen} close={onClose} />
        </>
    )
}

export default ProfileHeader