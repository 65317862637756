import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import PrivacyLists from "../../parts/list/PrivacyLists";
import privacyNavbarModel from "../../model";
import privacyModel from "pages/_refactors/privacy/model";
import { useShopStore } from "lib/stores/shop/shop";
import { useLocation } from "react-router-dom";

const MobilePrivacyNavbar = ({ hashtag }: { hashtag: string }) => {
    const shop = useShopStore();
    const { isOpen, onToggle } = useDisclosure();
    const { navItems } = privacyNavbarModel;
    const getDesign = useCallback(() => privacyModel.getColors(shop.shopTheme, shop.shopData), [shop.shopTheme, shop.shopData]);
    
    return (
        <Accordion width={"100%"} allowToggle index={isOpen ? 0 : 1} color={getDesign()?.texts} position={"sticky"} top={0} bg={getDesign()?.background}>
            <AccordionItem>
                <h2>
                    <AccordionButton onClick={onToggle}>
                        <Box as="span" flex="1" textAlign="left">
                            {hashtag}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel ml={"14px"} pb={4}>
                    <PrivacyLists hashtag={hashtag} onClickLink={onToggle} navItems={navItems} />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default MobilePrivacyNavbar;
