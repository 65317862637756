import { Flex, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import AppButton from 'components/common/form/button/AppButton';
import AppTypography from 'components/common/typography/AppTypography';
import { useToastify } from 'context/toastify/ToastifyContext';
import { useProfile } from 'hooks/useProfile/useProfile';
import { useShopStore } from 'lib/stores/shop/shop';
import productPageContext from 'pages/_refactors/product/context';
import React, { useCallback, useContext, useState } from 'react'
import mintToMerchContext from '../../../context';

function MintWallets() {
  const { states: { product } } = useContext(productPageContext)
  const [Loading, setLoading] = useState(false)
  const { states: { source }, methods: { updateStates } } = useContext(mintToMerchContext)
  const { profile } = useProfile()
  const { errorToast } = useToastify()
  const { shopDesign: { backgroundBody } } = useShopStore()

  const submit = useCallback(async () => {
    try {
      setLoading(true)
      const src = source.toLowerCase()
      if ((src !== "upload file") && (profile?.walletType?.toLowerCase() !== src)) throw Error(`Please connect ${src} wallet`)
      updateStates('step', 'position')
    } catch (error: any) {
      errorToast(error?.message)
      setLoading(false)
    }
  }, [source, profile])
  
  return (
    <VStack align="stretch" spacing="20px">
      <RadioGroup onChange={(e: any) => updateStates('source', e)} value={source}>
        <VStack align="stretch" spacing="10px">
          {product?.m2m_services.map((el: any, key: number) => (
            <Flex justifyContent="space-between" backgroundColor={backgroundBody} padding="10px 15px" borderRadius="8px">
              <Radio value={el.name}><AppTypography key={key} fontSize="16px">{el.name}</AppTypography></Radio>
            </Flex>
          ))}
        </VStack>
      </RadioGroup>
      <Flex justifyContent="flex-end"><AppButton paddingRight="20px" paddingLeft="20px" isLoading={Loading} onClick={submit} isDisabled={!Boolean(source.length)}>Next</AppButton></Flex>
    </VStack>
  )
}

export default MintWallets