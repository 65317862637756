import { VStack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { IOrdersData } from "../../interface";
import PurchaseHistoryOrderItem from "./parts/orderItem/PurchaseHistoryOrderItem";

const PurchaseHistoryOrders = ({ orders }: { orders: IOrdersData }) => {
    return (
        <VStack w={'full'}>
            {!orders?.data.length ?
                <AppTypography>No orders found in your history.</AppTypography> :
                orders?.data.map((order) => (
                    <PurchaseHistoryOrderItem key={order?.orderId} orderItem={order} />
                ))}
        </VStack>
    );
};

export default PurchaseHistoryOrders;
