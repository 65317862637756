import { Flex, Icon, VStack } from '@chakra-ui/react';
import AppButton from 'components/common/form/button/AppButton';
import StripeComponent from 'components/common/stripe/StripeComponent';
import AppTypography from 'components/common/typography/AppTypography';
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate';
import useAppCart from 'hooks/_refactor/cart/useAppCart';
import useAppCheckout from 'hooks/_refactor/checkout/useAppCheckout';
import { useShopStore } from 'lib/stores/shop/shop';
import useAppStore from 'lib/stores/_refactor/cart/cartStore';
import { isDarkColor } from 'lib/utils/app/helpers';
import checkoutPageContext from 'pages/_refactors/checkout/context';
import CheckoutAddress from 'pages/_refactors/checkout/parts/steps/address/CheckoutAddress';
import CheckoutEmail from 'pages/_refactors/checkout/parts/steps/email/CheckoutEmail';
import CheckoutPayment from 'pages/_refactors/checkout/parts/steps/payment/CheckoutPayment';
import CheckoutShipping from 'pages/_refactors/checkout/parts/steps/shipping/CheckoutShipping';
import { useContext, useMemo, useState } from 'react';
import { FaChevronDown } from "react-icons/fa6";
import paymentDefaultStepsContext from './context';

function PaymentDefaultSteps() {
    const { mehtods: { payment }, states } = useContext(checkoutPageContext)
    const { gated, IsCartDigital } = useAppCheckout()
    const { shopNavigate } = useCustomNavigate()
    const { shopDesign: { foreground, backgroundBody } } = useShopStore()
    const [Step, setStep] = useState('')
    const { clear } = useAppCart();
    const { states: { cart } } = useAppStore();

    const updateStep = (value: any) => setStep(value)

    const items = useMemo(() => [
        {
            title: 'Contact Information',
            key: 'contact',
            component: <CheckoutEmail onSuccess={() => updateStep('address')} />,
            condition: true
        },
        ...!IsCartDigital ? [
            {
                title: 'Address',
                key: 'address',
                component: <CheckoutAddress onSuccess={() => updateStep('shipping')} />,
                condition: gated.email
            },
            {
                title: 'Shipping',
                key: 'shipping',
                component: <CheckoutShipping onSuccess={() => updateStep('payment')} />,
                condition: gated.address
            },
        ] : [],
        {
            title: 'Payment Details',
            key: 'payment',
            component: <CheckoutPayment />,
            condition: gated.shipping && gated.email
        },
    ], [gated, IsCartDigital, cart])

    const discard = async () => {
        try {
            await clear()
        } catch (error: any) { }
    }

    return (
        <paymentDefaultStepsContext.Provider value={{ updateStep: (value: any) => setStep(value) }}>
            <Flex direction={"column"} gap={{ base: "16px", md: "32px" }}>
                {items.map((el, key) => (
                    <VStack key={key} align="stretch" transition='.4s' opacity={el.condition ? '1' : '.5'} spacing="20px" backgroundColor={foreground || "#0a0a0a"} borderRadius="8px" padding={{ base: "15px", md: "20px", lg: "30px" }}>
                        <Flex alignItems="center" cursor="pointer" onClick={() => el.condition ? setStep(prev => prev === el.key ? '' : el.key) : {}} justifyContent='space-between'>
                            <AppTypography fontSize={{ base: "1.1rem", md: "1.2rem", lg: "1.4rem" }} fontWeight="600">{key + 1}. {el.title}</AppTypography>
                            <Icon as={FaChevronDown} transition=".3s" fill="#FFF" width="14px" height="14px" transform={`rotate(${el.key === Step ? '180deg' : '0'})`} />
                        </Flex>
                        {el.key === Step && el.condition ? el.component : null}
                    </VStack>
                ))}
                {!(states.clientSecret && states.payment === 'STRIPE') ? (
                    <Flex justifyContent="end" gap="20px" alignItems="center">
                        <AppTypography fontSize={{ base: 'xs', md: 'md' }} cursor="pointer" textDecoration="underline" onClick={discard}>Discard</AppTypography>
                        <AppButton onClick={() => shopNavigate('checkout/cart')} variant='outline'>Go Back</AppButton>
                        <AppButton onClick={() => payment()} isLoading={states.loading}>Checkout</AppButton>
                    </Flex>
                ) : states.clientSecret ? <StripeComponent clientSecret={states.clientSecret} appea={isDarkColor(backgroundBody) ? 'night' : "stripe"} /> : null}
            </Flex>
        </paymentDefaultStepsContext.Provider>
    )
}

export default PaymentDefaultSteps