import * as React from "react";
import { Button, Spinner } from "@chakra-ui/react";

const CasperBasicButton = ({
  width,
  maxWidth,
  isLight,
  click,
  children,
  loading,
  borderRadius,
  isDisabled,
}: {
  width: any;
  maxWidth: any;
  isLight: boolean;
  click: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  borderRadius?: string;
  isDisabled?: boolean;
}) => {
  return (
    <Button
      w={width}
      maxW={maxWidth}
      p={{ base: "4px 8px", md: "8px 16px" }}
      color={isLight ? "#27262B" : "#FFFFFF"}
      bg={isLight ? "#FFFFFF" : "#27262B"}
      border="1px solid"
      borderRadius={borderRadius ? borderRadius : "4px"}
      fontFamily="Manrope"
      fontSize={{ base: "12px", md: "16px" }}
      fontWeight="400"
      borderColor="#27262B"
      isDisabled={isDisabled}
      onClick={click}
    >
      {loading ? (
        <Spinner
          size="sm"
          thickness="2px"
          color={isLight ? "#27262B" : "#FFFFFF"}
        />
      ) : (
        <>{children ? children : <></>}</>
      )}
    </Button>
  );
};

export default CasperBasicButton;
