import { Box, Flex, Image } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { IOrderSingleItem } from "pages/_refactors/history/interface";

const OrderItem = ({ item }: { item: IOrderSingleItem }) => {
    return (
        <Flex align={"flex-start"} gap={"12px"}>
            <Box flexShrink={0}>
                <Image src={item.image} w="48px" h="48px" />
            </Box>
            <Flex flexDirection={{ base: "column", md: "row" }} justifyContent={"space-between"} alignItems={"start"} gap={{ base: "10px" }} flex={1}>
                <AppTypography fontSize={{ base: "14px", md: "16px" }} fontWeight={500} width={{ md: "50%" }}>
                    {`${item.title || ""} ${item.size?.title ? `- ${item.size?.title}` : ""} ${item.color?.title ? `- ${item.color?.title}` : ""}`}
                </AppTypography>
                <AppTypography fontSize={{ base: "14px", md: "16px" }} fontWeight={400} width={{ md: "15%" }}>{`x${item.quantity}`}</AppTypography>
                <AppTypography fontSize={{ base: "14px", md: "16px" }} fontWeight={400}>{`$ ${item.price.toFixed(2)} USD`}</AppTypography>
            </Flex>
        </Flex >
    );
};

export default OrderItem;
