import { Flex, Stack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { useContext } from "react";
import paymentSuccessContext from "../../context";
import CopyRow from "./parts/hash/DeployHash";

const PaymentSuccessSummary = () => {
    const { order: { details: { orderId, chain, deployHash, address, paymentType } } } = useContext(paymentSuccessContext);

    const details = [
        { name: "Order ID", content: orderId, isCopyRow: false },
        { name: "Shipping Address", content: address, isCopyRow: false },
        { name: "Payment Type", content: paymentType, isCopyRow: false },
        { name: "Deploy Hash", content: deployHash, isCopyRow: true, hashType: chain },
    ];

    return (
        <Flex direction={"column"} gap={"24px"}>
            <AppTypography w={"full"} textAlign={"left"} fontSize={{ base: "16px", md: "18px" }} fontWeight="bold">
                Order Summary
            </AppTypography>
            <Flex direction={"column"} gap={"18px"}>
                {details?.map((detail) => (
                    detail?.content && (
                        <Stack key={detail?.name} direction={{ base: "column", md: "row" }} w="full" justify="start" align={{ base: 'start', md: "center" }} fontSize={{ base: "14px", md: "16px" }}>
                            <AppTypography width={{ base: undefined, md: "25%" }}>
                                {detail?.name}:
                            </AppTypography>
                            <AppTypography width={{ base: undefined, md: "75%" }} maxW={{ base: '100%', md: undefined }} textAlign="left">
                                {detail.isCopyRow && detail?.hashType ? <CopyRow hash={detail?.content?.toString()} hashType={detail?.hashType} /> : detail.content}
                            </AppTypography>
                        </Stack>
                    )
                ))}
            </Flex>
        </Flex>
    );
};

export default PaymentSuccessSummary;
