import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { theme } from "./style/chakraTheme";
import { ChakraProvider } from "@chakra-ui/react";
import * as MicroStacks from "@micro-stacks/react";
import ToastifyProvider from "./context/toastify/ToastifyContext";
import LoadingPage from "./pages/loading-page/LoadingPage";
import { appDeveloment } from "lib/utils/app/variables";
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient()

root.render(
  <QueryClientProvider client={queryClient}>
    <ToastifyProvider>
      <ChakraProvider theme={theme}>
        <Suspense fallback={<LoadingPage />}>
          <MicroStacks.ClientProvider
            appName="droplinked"
            appIconUrl="APP_ICON.png"
            network={appDeveloment ? "testnet" : "mainnet"}
          >
            <RouterProvider router={router} />
          </MicroStacks.ClientProvider>
        </Suspense>
      </ChakraProvider>
    </ToastifyProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
