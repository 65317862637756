export async function unisatLogin() {
    if (typeof window.unisat === 'undefined') {
        window.open("https://unisat.io", "_blank");
        throw "Please install unisat wallet";
    }
    let accounts = await window.unisat.requestAccounts();
    if (accounts.length === 0) {
        throw "No Account";
    }
    let address = accounts[0];
    let res = await window.unisat.getNetwork();
    let signature = await window.unisat.signMessage("Please sign this message to let droplinked access to your PublicKey and validate your identity.");
    let publicKey = await window.unisat.getPublicKey();
    return { address, network: res, signature, publicKey };
}