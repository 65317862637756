// react
import { useCustomParams } from "hooks/useCustomParams/useCustomParams";

// zustand
import { convertToShopModel } from "lib/models/shop";
import { getShopDataPublic } from "apis/shopApi";
import { useMemo, useState } from "react";
import { useShopStore } from "lib/stores/shop/shop";
import { useApi } from "hooks/useApi/useApi";
import { useProfile } from "hooks/useProfile/useProfile";

export const PAYMENT_TYPES = {
  STRIPE: "STRIPE",
  CASPER_SIGNER: "CASPER_SIGNER",
  CASPER: "CASPER",
  STACKS: "STACKS",
};
// this hook provides for integrate to shop data
export function useShop() {
  const [loading, setLoading] = useState<boolean>(false);
  const { getApi } = useApi();

  const { shopName } = useCustomParams();

  const setShopData = useShopStore((state) => state.setShopData);
  const setLoadingData = useShopStore((state) => state.setLoading);

  const updateShopData = async (shopname?: string) => {
    setLoading(true);
    setLoadingData(true);
    const shop = await getApi(getShopDataPublic(shopname ? shopname : shopName));
    setLoading(false);
    setLoadingData(false);
    setShopData(convertToShopModel(shop));
    return shop
    // update profile data after changing shop
  };

  return {
    loading,
    updateShopData,
  };
}
