import { ShopThemeEnum } from "lib/models/shop"
import PaymentCustom from "pages/_refactors/checkout/pages/payment/themes/custom/PaymentCustom"
import PaymentDefault from "pages/_refactors/checkout/pages/payment/themes/default/PaymentDefault"
import hiroSectionModel from "pages/_refactors/homepage/parts/hiro/model"
import HiroSectionCasper from "pages/_refactors/homepage/parts/hiro/themes/casper/HiroSectionCasper"
import HiroSectionDefault from "pages/_refactors/homepage/parts/hiro/themes/default/HiroSectionDefault"

const themePages = {
    homepage: {
        hiro: {
            [ShopThemeEnum.THEME_1]: <HiroSectionDefault />,
            [ShopThemeEnum.THEME_2]: <HiroSectionDefault />,
            [ShopThemeEnum.THEME_SKALE]: hiroSectionModel.themeImages.skale,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: hiroSectionModel.themeImages.unstoppable,
            [ShopThemeEnum.THEME_CASPER]: <HiroSectionCasper />,
            [ShopThemeEnum.THEME_POLYGON]: <HiroSectionDefault />,
        }
    },
    checkout: {
        payment: {
            [ShopThemeEnum.THEME_1]: <PaymentDefault />,
            [ShopThemeEnum.THEME_2]: <PaymentDefault />,
            [ShopThemeEnum.THEME_SKALE]: <PaymentCustom />,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: <PaymentCustom />,
            [ShopThemeEnum.THEME_CASPER]: <PaymentCustom />,
            [ShopThemeEnum.THEME_POLYGON]: <PaymentCustom />,
        }
    }
}

export default themePages