import { Flex } from '@chakra-ui/react'
import AppButton from 'components/common/form/button/AppButton'
import StripeComponent from 'components/common/stripe/StripeComponent'
import { useToastify } from 'context/toastify/ToastifyContext'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import { usePayment } from 'hooks/usePayment/usePayment'
import useAppCart from 'hooks/_refactor/cart/useAppCart'
import { PaymentsTypeEnum } from 'lib/models/shop'
import { useUserStore } from 'lib/stores/user/userStore'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React, { useCallback, useState } from 'react'

function CheckoutMainButtons() {
    const { shopNavigate } = useCustomNavigate()
    const { states: { cart } } = useAppStore()
    const { clear } = useAppCart()
    const { submitPayment, clientSecret } = usePayment()
    const { user } = useUserStore()
    const [Loading, setLoading] = useState(false)
    const { errorToast } = useToastify()

    const pay = useCallback(async () => {
        try {
            setLoading(true)
            await submitPayment(PaymentsTypeEnum.STRIPE)
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            errorToast(error?.response?.data?.data?.message || error || "Ops somthing wrong")
        }
    }, [cart, user])

    const discard = async () => {
        try {
            await clear()
            shopNavigate('checkout/cart')
        } catch (error: any) {
            errorToast(error.message)
        }
    }

    return (
        <>
            <Flex justifyContent="space-between" gap="20px">
                <AppButton width="200px" onClick={discard} variant="outline">Discard</AppButton>
                <AppButton width="200px" onClick={pay} isLoading={Loading}>Pay</AppButton>
            </Flex>
            {clientSecret && <StripeComponent clientSecret={clientSecret} appea={"stripe"} />}
        </>
    )
}

export default CheckoutMainButtons