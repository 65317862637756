import { Flex, Image, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { useShopStore } from 'lib/stores/shop/shop'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import { ICartStoreItem } from 'lib/stores/_refactor/cart/interfaces'
import React from 'react'
import checkoutShippingProductModel from './model'

interface IProps {
    groupId: string
}
function CheckoutShippingProduct({ groupId }: IProps) {
    const { states: { cart } } = useAppStore()
    const { themes } = checkoutShippingProductModel
    const { shopTheme } = useShopStore()

    return (
        <VStack align="stretch">
            {cart?.items.filter((item) => item.groupId === groupId).map((el: ICartStoreItem, key: number) => (
                <Flex key={key} gap="6px" {...themes[shopTheme].flex}>
                    <Image src={el.product.m2m_preview || el.product.image} width={{ base: "70px", lg: "25%" }} />
                    <AppTypography {...themes[shopTheme].typo} width="75%">{el.product.title}</AppTypography>
                </Flex>
            ))}
        </VStack>
    )
}

export default CheckoutShippingProduct