import { IShopDesign, ShopThemeEnum } from "lib/models/shop"
import { IhomepageDefaultStateThemes } from "./context"

namespace homepageContentDefaultModel {
  export const themes = (shopTheme: any, shopDesign: IShopDesign): IhomepageDefaultStateThemes => {
    let result: IhomepageDefaultStateThemes = {
      sidebarBoxes: { backgroundColor: "#FFF", border: "1px solid #DDD" },
      textColor: '#333'
    }

    if ([ShopThemeEnum.THEME_1, ShopThemeEnum.THEME_2].includes(shopTheme)) {
      result = {
        sidebarBoxes: { backgroundColor: shopDesign.foreground || "#0a0a0a" },
        textColor: shopDesign.textColorParagraphs || '#FFF'
      }
    }

    return result
  }
}

export default homepageContentDefaultModel