import { VStack } from '@chakra-ui/react'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'
import React from 'react'

function CheckoutListAddressLoading() {
  return (
    <VStack align="stretch">
        <AppSkeleton height="15px"></AppSkeleton>
        <AppSkeleton height="15px"></AppSkeleton>
        <AppSkeleton height="15px"></AppSkeleton>
    </VStack>
  )
}

export default CheckoutListAddressLoading