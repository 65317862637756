import { Flex, Input, Icon, FlexProps } from '@chakra-ui/react'
import { useShopStore } from 'lib/stores/shop/shop';
import React, { useCallback, useMemo } from 'react'
import { MdAdd } from "react-icons/md";
import { VscChromeMinimize } from "react-icons/vsc";
import AppInput from '../form/maker/parts/input/AppInput';
import AppLoading from '../loading/AppLoading';
import appQuantityModel from './model';

interface IProps {
  onChange: any
  value: any
  props?: FlexProps
  loading?: boolean
}
function AppQuantity({ onChange, value, props, loading }: IProps) {
  const { shopTheme, shopDesign } = useShopStore();
  const { themes } = appQuantityModel
  const minAvailable = useMemo(() => value >= 2, [value])
  const add = useCallback(() => onChange(parseInt(value) + 1), [value])
  const min = useCallback(() => minAvailable && onChange(parseInt(value) > 1 ? parseInt(value) - 1 : parseInt(value)), [value])

  return (
    <Flex alignItems="center" justifyContent="space-between" width="120px" padding="0 10px" {...themes(shopDesign)[shopTheme].box} {...props}>
      <Icon as={MdAdd} width="15px" onClick={add} cursor={loading ? 'auto' : "pointer"} height="15px" {...themes(shopDesign)[shopTheme].icons} />
      <Flex height="40px" alignItems="center">
        {loading ? <AppLoading width="16px" height="16px" /> : (
          <AppInput variant="none" maxLength={4} placeholder="0" isReadOnly background="none !important" border="none" textAlign="center" color="red" padding="0" {...themes(shopDesign)[shopTheme].input} value={value || ''} onChange={e => /^\d+(\.\d+)?$/.test(e.target.value) && onChange(parseInt(e.target.value))} />
        )}
      </Flex>
      <Icon as={VscChromeMinimize} width="15px" onClick={min} opacity={minAvailable ? '1' : '.5'} height="15px" cursor={loading || !minAvailable ? 'auto' : "pointer"} {...themes(shopDesign)[shopTheme].icons} />
    </Flex>
  )
}

export default AppQuantity