import { createApiReq } from "./api-utils";

export const getOrdersApi = (page: number) => {
  return createApiReq(`order?page=${page}`, true, null);
};

export const getOrdersByIdApi = (id: string) => {
  return createApiReq(`order/public/${id}`, true, null);
};

export const postPublicOrder = (deploy_hash: string, orderID: string, chain: string) => {
  return createApiReq(`order/public/payment/${chain}`, false, {
    deploy_hash,
    orderID,
  });
};

export const postOrder =(deploy_hash: string, orderID: string, chain: string) => {
  const body = {
    deploy_hash,
    orderID,
  };
  return createApiReq(`order/payment/${chain}`, true, body);
};