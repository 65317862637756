import { Box, Flex, RadioGroup, VStack } from '@chakra-ui/react'
import AppButton, { IAppButton } from 'components/common/form/button/AppButton'
import AppSeperator from 'components/common/seperator/AppSeperator'
import AppTypography from 'components/common/typography/AppTypography'
import { useToastify } from 'context/toastify/ToastifyContext'
import { useProfile } from 'hooks/useProfile/useProfile'
import useAppCart from 'hooks/_refactor/cart/useAppCart'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'
import { useShopStore } from 'lib/stores/shop/shop'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React, { useCallback, useEffect, useState } from 'react'
import checkoutShippingModel from './model'
import CheckoutShippingProduct from './parts/product/CheckoutShippingProduct'
import CheckoutShippingRadio from './parts/radio/CheckoutShippingRadio'

interface IProps {
    onSuccess: Function
    buttons?: {
        props?: IAppButton,
        children: JSX.Element
    }[]
}

interface IStates {
    values: any
    loading: boolean
}
function CheckoutShipping({ onSuccess, buttons }: IProps) {
    const { states: { cart } } = useAppStore()
    const { addToCart } = useAppCart()
    const { profile } = useProfile()
    const { errorToast } = useToastify()
    const [States, setStates] = useState<IStates>({
        values: {},
        loading: false
    })
    const { themes } = checkoutShippingModel
    const { shopTheme } = useShopStore()
    const { isDefaultTheme } = useAppTheme()

    const setValues = <T extends keyof typeof States>(key: T, value: typeof States[T]) => setStates((prev: any) => ({ ...prev, [key]: value }))

    useEffect(() => {
        if (cart && cart?.shippings) {
            const items: any = {}
            cart.shippings.forEach(element => {
                element.data.forEach((item, key) => {
                    if ((cart.shippings.length && item.selected) || key === 0) {
                        items[element.type] = {
                            groupId: element.groupId,
                            shipmentId: item.id
                        }
                    }
                })
            })
            setValues('values', items)
        }
    }, [cart, profile])

    const submit = useCallback(async () => {
        try {
            setValues('loading', true)
            if (!Object.keys(States.values).length) throw Error('Please choose shipping method')
            await addToCart({ field: 'shipping', value: Object.values(States.values) })
            onSuccess()
            setValues('loading', false)
        } catch (error: any) {
            setValues('loading', false)
            errorToast(error?.message || 'Somthing wrong');
        }
    }, [States.values, cart, profile])

    return (
        <VStack align="stretch" spacing="20px">
            {cart?.shippings.length ? cart?.shippings.map((el, key: number) => (
                <VStack align="center" gap="20px">
                    {(key > 0 && !isDefaultTheme) || isDefaultTheme ? <AppSeperator /> : null}
                    <Flex key={key} gap="30px" flexDirection={{ base: "column", lg: "row" }}>
                        <Box width={{ base: "100%", lg: "35%" }}><CheckoutShippingProduct groupId={el.groupId} /></Box>
                        <VStack align="stretch" spacing="13px" width={{ base: "100%", lg: "65%" }}>
                            {el.data.map((item, key) => (
                                <RadioGroup key={key} onChange={e => setStates((prev) => ({ ...prev, values: { ...prev.values, [el.type]: { groupId: el.groupId, shipmentId: e } } }))} value={States.values?.[el.type]?.shipmentId}>
                                    <VStack spacing="20px" align="stretch">
                                        <CheckoutShippingRadio option={item} active={States.values?.[el.type]?.shipmentId === item.id} key={key} />
                                    </VStack>
                                </RadioGroup>
                            ))}
                        </VStack>
                    </Flex>
                </VStack>
            )) : null}
            <AppTypography fontSize="14px">All products are printed on demand and can take between 3 and 4 weeks to get shipped.</AppTypography>
            <Flex justifyContent="space-between" gap="20px" direction="row-reverse">
                <AppButton {...themes[shopTheme].buttons} isLoading={States.loading} onClick={submit}>Submit</AppButton>
                {buttons ? buttons.map((e, key) => <AppButton {...themes[shopTheme].buttons} key={key} {...e.props}>{e.children}</AppButton>) : null}
            </Flex>
        </VStack>
    )
}

export default CheckoutShipping