import { BoxProps, TextProps } from "@chakra-ui/react"
import { ShopThemeEnum } from "lib/models/shop"

interface Itheme {
    box?: BoxProps
    title?: TextProps
    caption?: TextProps
    active?: BoxProps
}

interface IthemesOutput {
    [propname: string]: Itheme
}

namespace checkoutShippingRadioModel {
    const theme: IthemesOutput = {
        default: {
            box: {
                border: "1px solid #777",
                borderRadius: "4px",
                padding: "10px 20px"
            },
            title: {
                fontSize: "12px"
            },
            caption: {
                fontSize: "12px"
            }
        },
        custom: {
            box: {
                backgroundColor: "#FFF",
                border: "2px solid rgb(239, 236, 220)",
                padding: { base: "14px", sm: "24px" },
                borderRadius: "18px"
            },
            title: {
                fontSize: { base: "12px", sm: "16px" },
                fontWeight: "700"
            },
            caption: {
                fontSize: "14px",
                fontWeight: "600"
            },
            active: {
                borderColor: "#333"
            }
        },
    }
    export const themes: IthemesOutput = ({
        [ShopThemeEnum.THEME_1]: theme.default,
        [ShopThemeEnum.THEME_2]: theme.default,
        [ShopThemeEnum.THEME_SKALE]: theme.custom,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: theme.custom,
        [ShopThemeEnum.THEME_CASPER]: theme.custom,
        [ShopThemeEnum.THEME_POLYGON]: theme.custom,
    })
}

export default checkoutShippingRadioModel