import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'

interface IProps {
    text: string
}
function ProductDetailTitles({ text }: IProps) {
    return <AppTypography fontSize={{ base: "16px", sm: "18px" }} fontWeight="bold">{text}</AppTypography>
}

export default ProductDetailTitles