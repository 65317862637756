import { Box, Flex, Image, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { useShopStore } from 'lib/stores/shop/shop'
import React, { useMemo } from 'react'

function HiroSectionDefault() {
  const { shopData: { backgroundImage, template_options, backgroundText, fullWidthHero, shopDesign: { hiroTextColor, hiroLayout } } } = useShopStore()

  const text = useMemo(() => <AppTypography fontSize={{ base: "14px", md: "20px" }} fontWeight="bold" color={hiroTextColor} {...template_options?.['--dlk-wrp']?.['--dlk-wrp-hiro']?.['--dlk-wrp-hiro-caption']}>{backgroundText}</AppTypography>, [hiroTextColor, backgroundText])
  const image = useMemo(() => backgroundImage && <Image width={fullWidthHero ? "100%" : "auto"} height='500px' maxWidth="100%" {...fullWidthHero && { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", objectFit: "cover" }} {...template_options?.['--dlk-wrp']?.['--dlk-wrp-hiro']?.['--dlk-wrp-hiro-image']} src={backgroundImage} />, [backgroundImage, fullWidthHero])
  const style = useMemo(() => template_options?.['--dlk-wrp']?.['--dlk-wrp-hiro']?.['--dlk-wrp-hiro-styles'], [template_options])

  const handleLayout = useMemo(() => {
    switch (hiroLayout) {
      case 'right':
        return (
          <Flex justifyContent="space-between" height="100%" padding={{ base: "30px 0", md: "0" }} position="relative" flexDirection="row-reverse" {...style}>
            {image}
            <Box position="absolute" top="50%" width="50%" transform="translateY(-50%)" left="7%" textAlign="left">{text}</Box>
          </Flex>
        )
      case 'left':
        return (
          <Flex justifyContent="space-between" height="100%" padding={{ base: "30px 0", md: "0" }} position="relative" alignItems="center" {...style}>
            {image}
            <Box position="absolute" top="50%" width="50%" transform="translateY(-50%)" right="7%">{text}</Box>
          </Flex>
        )
      case 'center':
        return (
          <VStack justifyContent="center" height="100%" padding={{ base: "30px 0", md: "0" }} {...style}>
            {image}
            <Box position="absolute" top="50%" left="50%" textAlign="center" width="80%" transform="translate(-50%, -50%)">{text}</Box>
          </VStack>
        )
      case 'right_text':
        return (
          <Box justifyContent="center" height="100%" position="relative" {...style}>
            {image}
            <Box position="absolute" top="50%" width="50%" transform="translateY(-50%)" right="7%" textAlign="right">{text}</Box>
          </Box>
        )
      case 'left_text':
        return (
          <Box justifyContent="center" height="100%" position="relative" {...style}>
            {image}
            <Box position="absolute" top="50%" width="50%" transform="translateY(-50%)" left="7%">{text}</Box>
          </Box>
        )
      case 'center_text':
        return (
          <Box justifyContent="center" height="100%" position="relative" {...style}>
            {image}
            <Box position="absolute" top="50%" left="50%" textAlign="center" width="80%" transform="translate(-50%, -50%)">{text}</Box>
          </Box>
        )

      default:
        return <span></span>
    }
  }, [hiroLayout, backgroundImage, hiroTextColor, template_options, backgroundText, fullWidthHero])

  return <Box minHeight="100px" height={{ base: "300px", sm: "400px", lg: "500px" }} position="relative" overflow="hidden">{handleLayout}</Box>
}

export default HiroSectionDefault