import { ShopThemeEnum } from "lib/models/shop"

namespace checkoutListAddresModel {
    export const themes = {
        [ShopThemeEnum.THEME_1]: "#FFF",
        [ShopThemeEnum.THEME_2]: "#FFF",
        [ShopThemeEnum.THEME_SKALE]: "#333",
        [ShopThemeEnum.THEME_UNSTOPPABLE]: "#333",
        [ShopThemeEnum.THEME_CASPER]: "#333",
        [ShopThemeEnum.THEME_POLYGON]: "#333",
    }
}

export default checkoutListAddresModel