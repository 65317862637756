import { VStack } from "@chakra-ui/react";
import { IPurchaseHistorySingleOrder } from "apis/orders/interface";
import { getSingleCustomerOrderByIdService } from "apis/orders/services";
import AppModal from "components/common/modal/AppModal";
import AppSeperator from "components/common/seperator/AppSeperator";
import { useToastify } from "context/toastify/ToastifyContext";
import { useEffect, useState } from "react";
import orderModalContext from "./context";
import purchaseHistoryOrderModalModel from "./model";
import PurchaseHistoryPaymentDetails from "./parts/details/PurchaseHistoryPaymentDetails";
import PurchaseHistoryOrderItems from "./parts/items/PurchaseHistoryOrderItems";
import OrderModalSkeleton from "./parts/loading/OrderModalSkeleton";
import PurchaseHistoryOrderSummary from "./parts/summary/PurchaseHistoryOrderSummary";

interface IPurchaseHistoryOrderItemModal {
    isOpen: boolean;
    onClose: Function;
    orderId: string;
}
const PurchaseHistoryOrderItemModal = ({ isOpen, onClose, orderId }: IPurchaseHistoryOrderItemModal) => {
    const { errorToast } = useToastify()
    const [modalHeader, setModalHeader] = useState({ title: "", description: "", color: "" })
    const [isLoading, setLoading] = useState(false)
    const [orderDetails, setOrderDetails] = useState<IPurchaseHistorySingleOrder>()

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                setLoading(true)
                const data = await getSingleCustomerOrderByIdService(orderId)
                setOrderDetails(data.data.data)
                const modalHeaderData = purchaseHistoryOrderModalModel.getOrderStatusDesign(data.data.status)
                setModalHeader(modalHeaderData)
            } catch (error) {
                errorToast((error as Error).message)
                onClose()
            }
            finally {
                setLoading(false)
            }
        }
        fetchOrderDetails()
    }, [])

    return (
        <AppModal
            isOpen={isOpen}
            onClose={onClose}
            title={`${modalHeader.title}\n${modalHeader.description}`}
            props={{
                content: {
                    maxW: { base: '90%', md: "80%" },
                    p: { base: '32px 0px', sm: "32px 24px", md: "64px 48px" },
                },
                text: { color: modalHeader.color, fontSize: { base: "14px", md: "18px" }, width: "80%", textAlign: "justify" }
            }}>
            {isLoading ? <OrderModalSkeleton /> :
                <orderModalContext.Provider value={orderDetails}>
                    <AppSeperator mb={"24px"} />
                    <VStack spacing={"24px"} align="stretch">
                        <PurchaseHistoryOrderSummary />
                        <AppSeperator />
                        <PurchaseHistoryOrderItems />
                        <AppSeperator />
                        <PurchaseHistoryPaymentDetails />
                    </VStack>
                </orderModalContext.Provider>
            }
        </AppModal>
    );
};

export default PurchaseHistoryOrderItemModal;