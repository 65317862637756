import React, { useCallback } from 'react'
import { IaddCartService, IdeleteCartItemService, IupdateCartParams, IupdateCartService, IdeleteCartParams, IaddEmailToCartService, IgetCartService, IdiscountCartService, IdeleteCartService, IaddCartM2mService } from 'apis/cart/interface'
import { addCartService, addEmailToCartService, createCartService, deleteCartItemService, deleteCartService, discountCartService, getCartService, updateCartService } from 'apis/cart/service'
import { useMutation } from 'react-query'
import { useShopStore } from 'lib/stores/shop/shop'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import modelUseAppCart from './model'
import { addressAttachCartService } from 'apis/address/addressServices'
import { IaddressAttachCartService } from 'apis/address/interfaces'
import { shippingAttachToCartService } from 'apis/shipping/addressServices'
import { IshippingAttachToCartService } from 'apis/shipping/interfaces'
import { useProfile } from 'hooks/useProfile/useProfile'
import { capitalizeFirstLetter } from 'lib/utils/app/helpers'

interface Iadd {
    quantity: number
    skuID: string
    product: any
    m2m?: IaddCartM2mService
}

interface Ifetch {
    quantity: number
    skuID: string
    product: any
    m2m?: IaddCartM2mService
}

interface IaddToCart {
    field: 'address' | 'email' | 'shipping'
    value: any
}

interface Idiscount {
    code: string
}
function useAppCart() {
    const fetchService = useMutation((params: IgetCartService) => getCartService(params))
    const addService = useMutation((params: IaddCartService) => addCartService(params))
    const createService = useMutation(() => createCartService())
    const updateService = useMutation((params: IupdateCartService) => updateCartService(params))
    const deleteItemService = useMutation((params: IdeleteCartItemService) => deleteCartItemService(params))
    const addEmailService = useMutation((params: IaddEmailToCartService) => addEmailToCartService(params))
    const addAddressService = useMutation((params: IaddressAttachCartService) => addressAttachCartService(params))
    const addShippingService = useMutation((params: IshippingAttachToCartService) => shippingAttachToCartService(params))
    const discountService = useMutation((params: IdiscountCartService) => discountCartService(params))
    const deleteService = useMutation((params: IdeleteCartService) => deleteCartService(params))
    const { shopData } = useShopStore()
    const { methods: { updateState }, states: { cart } } = useAppStore()
    const model = modelUseAppCart
    const { profile } = useProfile()

    const anonymous = !Boolean(profile)

    const updateCartStore = (data: any) => updateState({ state: 'cart', value: data })

    const create = () => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const { data } = await createService.mutateAsync()
                resolve(data?.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    const add = async ({ quantity, skuID, product, m2m }: Iadd) => {
        try {
            const ruleset = product?.ruleSet
            if (ruleset && ruleset?.gated && (!profile?.walletAddress || profile?.walletType !== ruleset.type)) throw Error('You should be login ' + capitalizeFirstLetter(ruleset.type))
            const cartID = anonymous ? cart?._id || await (await create())._id : null
            const { data } = await addService.mutateAsync({ cartID: cartID, anonymous, params: { quantity, skuID, shopID: shopData._id, ...ruleset && { wallet: profile?.walletAddress }, ...m2m && { m2m_data: m2m } } })
            updateCartStore(data?.data)
            return data?.data
        } catch (error: any) {
            throw error
        }
    }

    const fetch = async (cartID?: string) => {
        try {
            if ((!cartID && (!cart || !cart?._id)) && anonymous) throw Error('You havent cart')
            const { data } = await fetchService.mutateAsync({ cartID: anonymous && cart ? cart._id : cartID ? cartID : null, anonymous })
            updateCartStore(data?.data)
        } catch (error: any) {
            throw error
        }
    }

    const deleted = async (params: IdeleteCartParams) => {
        try {
            if (!cart) throw Error('You havent cart')
            const { data } = await deleteItemService.mutateAsync({ anonymous, cartID: cart?._id, params })
            if (!data?.data?.items.length) clear(true)
            else updateCartStore(data?.data)
        } catch (error: any) {
            throw error
        }
    }

    const update = async (params: IupdateCartParams) => {
        try {
            if (!cart) throw Error('You havent cart')
            const { data } = await updateService.mutateAsync({ anonymous, shopID: shopData._id, cartID: cart?._id, params })
            updateCartStore(data?.data)
        } catch (error) {
            throw error
        }
    }

    const addToCart = async ({ field, value }: IaddToCart) => {
        try {
            let data = null
            if (!cart) throw Error('You havent cart')
            if (field === 'email') data = await (await addEmailService.mutateAsync({ cartID: cart?._id, params: { email: value } })).data?.data
            else if (field === 'address') data = await (await addAddressService.mutateAsync({ anonymous, cartID: cart?._id, params: { addressBookID: value } }))?.data?.data
            else if (field === 'shipping') data = await (await addShippingService.mutateAsync({ anonymous, cartID: cart?._id, params: { rates: value } }))?.data?.data
            updateCartStore(data)
        } catch (error) {
            throw error
        }
    }

    const discount = async ({ code }: Idiscount) => {
        try {
            if (!cart) throw Error('You havent cart')
            const { data } = await discountService.mutateAsync({ anonymous, ...anonymous && { cartId: cart?._id }, code })
            updateCartStore(data?.data)
        } catch (error) {
            throw error
        }
    }

    const clear = (store: boolean = false) => {
        return new Promise<any>(async (resolve, reject) => {
            try {                
                updateCartStore(null)
                if (!store) {
                    if (!cart || !cart._id) throw Error('You havent cart')
                    await deleteService.mutateAsync({ ...anonymous && { cartId: cart._id }, anonymous })
                }
                resolve(true)
            } catch (error) {
                reject(error)
            }
        })
    }

    const countItems = model.countItems(cart)

    return { add, fetch, deleted, clear, countItems, update, addToCart, discount }
}

export default useAppCart