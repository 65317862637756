import { Flex } from '@chakra-ui/react'
import AppContainer from 'components/common/container/AppContainer'
import { useToastify } from 'context/toastify/ToastifyContext'
import { usePayment } from 'hooks/usePayment/usePayment'
import useAppCheckout from 'hooks/_refactor/checkout/useAppCheckout'
import { PaymentsTypeEnum } from 'lib/models/shop'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import checkoutPageContext, { checkoutPageStates, IcheckoutPageStates } from './context'

function CheckoutPage() {
  const [States, setStates] = useState<IcheckoutPageStates>(checkoutPageStates)
  const { errorToast, successToast } = useToastify()
  const { submitPayment, clientSecret } = usePayment()
  const { gated } = useAppCheckout()
  const { states: { cart } } = useAppStore()

  const updateStates = (key: string, value: any) => setStates((prev: IcheckoutPageStates) => ({ ...prev, [key]: value }))

  const payment = async () => {
    try {
      if (!gated.address || !gated.email || !gated.shipping) throw Error('Please complete all step')
      if (!cart) throw Error('Havent cart')
      updateStates('loading', true)
      await submitPayment(States.payment)
      if (States.payment !== PaymentsTypeEnum.STRIPE) {
        successToast('Pay Successful')
        // shopNavigate('')
      }
      updateStates('loading', false)
    } catch (error: any) {
      console.log(error);
      updateStates('loading', false)
      if (error?.message.length) errorToast(error?.response?.data?.data?.message || 'Oops somthing wrong!')
    }
  }

  useEffect(() => {
    if (clientSecret) updateStates('clientSecret', clientSecret)
  }, [clientSecret])

  return (
    <checkoutPageContext.Provider value={{ states: States, mehtods: { updateStates, payment } }}>
      <Flex justifyContent="center" padding="30px 0">
        <AppContainer props={{ flexDir: "column" }}><Outlet /></AppContainer>
      </Flex>
    </checkoutPageContext.Provider>
  )
}

export default CheckoutPage