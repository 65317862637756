import { VStack } from "@chakra-ui/react";
import { useShopStore } from "lib/stores/shop/shop";
import React from "react";
import footerDefaultModel from "./parts/model";
import AppSeperator from "components/common/seperator/AppSeperator";
import FooterDefaultLicence from "./parts/licence/FooterLicence";
import FooterBrandLinks from "./parts/brand/FooterBrandLinks";

const FooterDefault = () => {
    const shop = useShopStore();
    const { shopData: { template_options } } = useShopStore()

    return (
        <VStack {...footerDefaultModel.design(shop?.shopTheme, shop?.shopData)} spacing="24px" fontSize={{ base: "16px", md: "12px", xl: "16px" }} {...template_options?.["--dlk-ftr"]?.["--dlk-ftr-styles"]}>
            <FooterBrandLinks />
            <AppSeperator w={'full'} color="red" />
            <FooterDefaultLicence />
        </VStack>
    );
};

export default FooterDefault;
