import { VStack, chakra } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import useAppStore from "lib/stores/_refactor/cart/cartStore";
import React from "react";

const CartPageSubtotal = () => {
    const { states: { cart } } = useAppStore();
    return (
        <VStack align={"end"} w={"full"} spacing={'24px'} fontSize={{ base: '12px', sm: '16px' }} textAlign={'right'}>
            <AppTypography>Subtotal: <chakra.span fontSize={{ base: '18px', sm: '24px' }} fontWeight={'bold'}>${cart?.totalCart.subtotal ? cart?.totalCart.subtotal.toFixed(2) : 0} USD</chakra.span></AppTypography>
            <AppTypography>Taxes and shipping calculated at checkout</AppTypography>
        </VStack>
    );
};

export default CartPageSubtotal;
