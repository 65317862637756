import { IApiServiceType } from "./api-model";
import { createApiReq } from "./api-utils";

export const getShopDataPublic = (shopName: string): IApiServiceType => {
  return createApiReq(`shop/public/${shopName}`, false, null);
};

export const getAvailablePaymentMethods = (): IApiServiceType => {
  return createApiReq(`shop/public/available-payment-methods`, false, null);
};
