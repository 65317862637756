import { Box, useDisclosure } from '@chakra-ui/react'
import AppModal from 'components/common/modal/AppModal'
import AppWallets from 'components/common/wallets/AppWallets'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'

interface IProps {
    icon: any
}
function HeaderIconProfile({ icon }: IProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isDefaultTheme } = useAppTheme()

    return (
        <>
            <Box onClick={onOpen}>{icon}</Box>
            <AppModal isOpen={isOpen} onClose={onClose} title="Select Wallet"><AppWallets onClick={onClose} /></AppModal>
        </>
    )
}

export default HeaderIconProfile