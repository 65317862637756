import { useNavigate } from "react-router-dom";
import { useCustomParams } from "hooks/useCustomParams/useCustomParams";
import { isCustomDomain } from "lib/utils/theme/theme-utils";

//this hook have been used for handle shop and user data
export function useCustomNavigate() {
    const { shopName } = useCustomParams();
    const navigate = useNavigate();

    const route = (path) => (isCustomDomain ? `/${path}` : `/${shopName}/${path}`);
    const shopNavigate = (path) => {
        navigate(route(path));
    };

    return {
        shopNavigate,
        route,
    };
}
