import { ShopThemeEnum } from "lib/models/shop";
import { useShopStore } from "lib/stores/shop/shop";
import { getComponentByTheme } from "lib/utils/app/component-util";

const ThemeBasedComponent = ({
  componentsList,
}: {
  componentsList: {
    theme: ShopThemeEnum;
    component: JSX.Element;
  }[];
}) => {
  const shopTheme = useShopStore((state) => state.shopTheme);
  return <>{getComponentByTheme(shopTheme, componentsList)}</>;
};

export default ThemeBasedComponent;
