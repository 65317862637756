import { Stack } from "@chakra-ui/react";
import { useShopStore } from "lib/stores/shop/shop";
import { useMemo } from "react";
import footerDefaultModel from "../model";
import FooterItems from "../parts/Items/FooterItems";
const FooterDefaultLicence = () => {
    const {shopData, shopTheme} = useShopStore();
    const licenceLinks = useMemo(() => footerDefaultModel.getLinks(shopTheme, shopData).licence, [shopTheme, shopData]);

    return (
        <Stack direction={{ base: "column", md: "row" }} align="start" w={"full"}>
            <FooterItems items={licenceLinks.texts} />
            <FooterItems items={licenceLinks.links} />
        </Stack>
    );
};

export default FooterDefaultLicence;
