import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

interface IProps {
    children: any
    props?: BoxProps
}
function CheckoutCard({ children, props }: IProps) {
    return (
        <Box width="100%" {...props}>{children}</Box>
    )
}

export default CheckoutCard