import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import { Im2mAddToCartService, IpodGenerateMockup, IpodGenerateTextService } from "apis/pod/interface";
import { m2mAddToCartService, podGenerateMockup, podGenerateOverlayService } from "apis/pod/services";
import AppButton from "components/common/form/button/AppButton";
import AppLoading from "components/common/loading/AppLoading";
import useAppCart from "hooks/_refactor/cart/useAppCart";
import { ICartStoreItem } from "lib/stores/_refactor/cart/interfaces";
import productPageContext from "pages/_refactors/product/context";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import mintToMerchContext from "../../../context";
import mintPositionModel from "../position/model";
import mintPreviewModel from "./model";

function MintPreview() {
  const { states: { sku, product, option: { quantity } } } = useContext(productPageContext);
  const { states: { position, artwork, removeBackground, source }, methods: { closed, updateStates } } = useContext(mintToMerchContext);
  const { findPosition, findExactVariantId } = mintPreviewModel;
  const { mutateAsync: mutateOverlay, data: overlay, isLoading: overlayLoading } = useMutation((params: IpodGenerateTextService) => podGenerateOverlayService(params));
  const { mutateAsync: mutateMockup, data: mockup, isLoading: mockupLoading } = useMutation((params: IpodGenerateMockup) => podGenerateMockup(params));
  const m2mAddToCart = useMutation((params: Im2mAddToCartService) => m2mAddToCartService(params));
  const { add, fetch } = useAppCart()
  const [Loading, setLoading] = useState(false)

  const global = useCallback(async () => {
    const m2mPositions = findPosition(product?.m2m_positions, position);
    const exactVariantId = findExactVariantId(m2mPositions?.variant_ids, sku?.externalID);
    const data = await mutateOverlay({ baseImageLink: m2mPositions?.url, overlayImageLink: artwork, bgremove: removeBackground })
    await mutateMockup({ variant_ids: [exactVariantId], placement: position, image_url: data?.data?.data, podBlankId: product?.pod_blank_product_id })
  }, [product, position, sku, quantity, removeBackground])
  const { isUploadFile } = mintPositionModel

  const addCart = async () => {
    try {
      setLoading(true)
      const cart: any = await add({ quantity, skuID: sku?._id, product, m2m: { m2m_position: position, print_url: artwork } })
      const itemId = cart.items.find((el: any) => el.skuID === sku._id)?._id
      if (!itemId) throw Error('Cant find this item cart')
      await m2mAddToCart.mutateAsync({ cartId: cart._id, itemId, params: { preview: mockup?.data?.data } })
      await fetch(cart._id)
      closed()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  useEffect(() => { !overlayLoading && global() }, [])

  return <>{mockupLoading || overlayLoading ? <AppLoading /> : (
    <VStack align="stretch">
      <Flex justifyContent="center"><Image src={mockup?.data?.data} width="300px" maxWidth="100%" /></Flex>
      <Flex justifyContent="space-between" direction="row-reverse">
        <AppButton paddingRight="20px" paddingLeft="20px" size="sm" isLoading={Loading} onClick={addCart}>Add to cart</AppButton>
        <AppButton paddingRight="20px" paddingLeft="20px" size="sm" onClick={() => updateStates('step', isUploadFile(source) ? 'position' : 'artwork')}>Back</AppButton>
      </Flex>
    </VStack>
  )}</>
}

export default MintPreview;
