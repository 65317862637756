import { Flex, Image, Link } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'
import casperTopbarModel from './model'
import { Link as LinkRouter } from 'react-router-dom'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'

function CasperTopbar() {
    const { links } = casperTopbarModel
    const { route } = useCustomNavigate()

    return (
        <Flex justifyContent="center" gap={{ base: "20px", xl: "80px" }} alignItems="center" padding="20px 0">
            <LinkRouter to={route('')}><Image src="/assets/images/casper-logo.svg" width="115px" /></LinkRouter>
            <Flex alignItems="center" gap={{ base: "10px", lg: "30px", xl: "40px" }} position="relative" top="5px">
                {links.map((el, key) => <Link href={el.href} key={key} target="_blank"><AppTypography fontSize=".8rem" textTransform="uppercase" fontWeight="bold">{el.title}</AppTypography></Link>)}
            </Flex>
        </Flex>
    )
}

export default CasperTopbar