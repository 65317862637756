import { Box, Button, Image, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'

function HiroSectionCasper() {
    return (
        <Box position="relative">
            <Image src='/assets/images/banner-casper.jpg' width="100%" />
            <VStack align="stretch" position="absolute" left="16%" top="50%" transform="translateY(-50%)">
                <AppTypography color="#FFF" fontWeight="800" fontSize={{ base: "14px", sm: "24px", md: "34px", lg: "64px" }} fontFamily="Montserrat">
                    Explore The<br />
                    CasperPunks<br />
                    Collection
                </AppTypography>
                <Box>
                    <Button backgroundColor="#161f2f" padding={{ base: "9px 10px", md: "13px 16px" }} height="auto" fontSize={{base: "10px", sm: "12px"}} fontWeight="800" color="#FFF" width="auto">GET YOUR TEE</Button>
                </Box>
            </VStack>
        </Box>
    )
}

export default HiroSectionCasper