import { Box, useDisclosure, } from '@chakra-ui/react';
import React from 'react'

interface IProps {
    icon: any
    content: any
}
function HeaderPopover({ icon, content }: IProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box position="relative" onMouseOver={onOpen} onMouseOut={onClose}>
            {icon}
            {isOpen && <Box position="absolute" zIndex="1" top="30px" right={{ base: 0, sm: "-10px" }} paddingTop="10px" width={{ base: "240px", sm: "300px" }}>{content}</Box>}
        </Box>
    );
}

export default HeaderPopover