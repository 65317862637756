import { Box, Flex, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import useAppCart from 'hooks/_refactor/cart/useAppCart'
import { useShopStore } from 'lib/stores/shop/shop'
import { Link, Navigate } from 'react-router-dom'
import GiftCardDefault from '../../../../parts/gift/GiftCardDefault'
import PurchaseDefault from '../../../../parts/purchase/PurchaseDefault'
import PaymentDefaultSteps from './parts/steps/PaymentDefaultSteps'
import PaymentTotalDefault from './parts/total/PaymentTotalDefault'

function PaymentDefault() {
  const { route } = useCustomNavigate()
  const { countItems } = useAppCart()
  const { shopDesign: { foreground } } = useShopStore()

  return countItems ? (
    <VStack align="stretch" width="100%" spacing="32px">
      <Flex justifyContent="space-between" alignItems="center">
        <AppTypography fontSize={{ base: "1.4rem", md: "1.6rem" }} fontWeight="bold">Checkout</AppTypography>
        <Link to={route('')}><AppTypography fontSize={{ base: ".9rem", md: "1em" }} textDecoration="underline">Continue shopping</AppTypography></Link>
      </Flex>
      <Flex justifyContent="space-between" flexDirection={{ base: "column", md: "row-reverse" }} gap={{ base: "16px", md: "32px" }}>
        <Flex direction={"column"} width={{ base: "100%", md: "50%" }} gap={{ base: "16px", md: "32px" }}>
          <Box backgroundColor={foreground} borderRadius="8px" padding={{ base: "15px", md: "20px", lg: "30px" }}><PurchaseDefault /></Box>
          <GiftCardDefault />
          <PaymentTotalDefault />
        </Flex>
        <VStack width={{ base: "100%", md: "50%" }} align="stretch"><PaymentDefaultSteps /></VStack>
      </Flex>
    </VStack>
  ) : <Navigate to={route('checkout/cart')} />
}

export default PaymentDefault