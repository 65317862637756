import { VStack } from '@chakra-ui/react'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'
import { useShopStore } from 'lib/stores/shop/shop'
import React from 'react'

interface IProps {
    children: any
}
function HeaderBoxHover({ children }: IProps) {
    const { shopDesign: { foreground } } = useShopStore()
    const { isDefaultTheme } = useAppTheme()

    return (
        <VStack align="stretch" boxShadow="0 4px 10px rgba(0,0,0,.1)" borderRadius="8px" backgroundColor={!isDefaultTheme ? '#FFF' : foreground} padding="20px" spacing={{ base: "10px", sm: "14px" }}>{children}</VStack>
    )
}

export default HeaderBoxHover