import { Flex, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React, { useMemo } from 'react'

function CheckoutMainTotal() {
    const { states: { cart } } = useAppStore()

    return (
        <Flex alignItems="center" gap="4px" justifyContent={{ base: "center", sm: "end" }}>
            <AppTypography fontSize={{ base: "16px", sm: "18px" }}>Total payment: </AppTypography>
            <AppTypography fontSize={{ base: "18px", sm: "24px" }} fontWeight="bold">${cart?.totalCart.totalPayment}</AppTypography>
        </Flex>
    )
}

export default CheckoutMainTotal