namespace privacyNavbarModel {
    export const navItems = [
        { name: "Access & Membership", key: "access" },
        { name: "Modifications", key: "modifications" },
        { name: "Content", key: "content" },
        { name: "Use of Services", key: "use-services" },
        { name: "Content and Services", key: "content-services" },
        { name: "Limited Warranty on Products", key: "limited" },
        { name: "Your rights in the case of lack of conformity", key: "rights" },
        { name: "Responsibility of Site members and visitors", key: "responsibility" },
        { name: "Payments and fees", key: "payments" },
        { name: "Taxes", key: "taxes" },
        { name: "Shipping", key: "shipping" },
        { name: "Description of products", key: "description" },
        { name: "Purchase of products", key: "purchase" },
        { name: "Delivery", key: "delivery" },
        { name: "Release", key: "release" },
        { name: "Indemnity", key: "indemnity" },
        { name: "Governing Law", key: "governing" },
        { name: "ARBITRATION AND JURY TRIAL WAIVER", key: "arbitration" },
        { name: "Privacy and Personal Data Processing", key: "privacy" },
        { name: "General", key: "general" },
    ];
    export const findHashtagFromNavItems = (hash: string) => navItems?.find((item) => item?.key === hash?.split("#")[1])?.name || '';
}
export default privacyNavbarModel;
