import { appDeveloment } from "lib/utils/app/variables";
import { CHAIN_TYPE } from "pages/_refactors/paymentSuccess/context";

namespace deployHashCodeModel {
    

    export const getDeployHashLink = (hash: string, chain: CHAIN_TYPE) => {
        const URL_MAPPINGS = {
            [CHAIN_TYPE.CASPER]: { main: `https://cspr.live/deploy/${hash}`, test: `https://testnet.cspr.live/deploy/${hash}` },
            [CHAIN_TYPE.STACKS]: { main: `https://explorer.hiro.so/txid/${hash}/?chain=mainnet`, test: `https://explorer.hiro.so/txid/${hash}/?chain=testnet` },
            [CHAIN_TYPE.XRPLSIDECHAIN]: { main: `https://evm-sidechain.xrpl.org/tx/${hash}`, test: `https://evm-sidechain.xrpl.org/tx/${hash}` },
            [CHAIN_TYPE.POLYGON]: { main: `https://polygonscan.com/tx/${hash}`, test: `https://mumbai.polygonscan.com/tx/${hash}` },
            [CHAIN_TYPE.BINANCE]: { main: `https://bscscan.com/tx/${hash}`, test: `https://testnet.bscscan.com/tx/${hash}` },
            [CHAIN_TYPE.BASE]: { main: `https://base.blockscout.com/tx/${hash}`, test: `https://base-goerli.blockscout.com/tx/${hash}` },
            [CHAIN_TYPE.LINEA]: { main: `https://lineascan.build/tx/${hash}`, test: `https://goerli.lineascan.build/tx/${hash}` },
            [CHAIN_TYPE.NEAR]: { main: `https://explorer.mainnet.aurora.dev/tx/${hash}`, test: `https://explorer.aurora.dev/tx/${hash}` },
            [CHAIN_TYPE.XUMM]: { main: `https://xrpscan.com/tx/${hash}`, test: `https://blockexplorer.one/xrp/testnet/tx/${hash}` },
            [CHAIN_TYPE.SKALE]: null,
            [CHAIN_TYPE.STRIPE]: null,
        };
        return appDeveloment ? URL_MAPPINGS[chain]?.test : URL_MAPPINGS[chain]?.main
    };
}

export default deployHashCodeModel;
