import { VStack } from "@chakra-ui/react";
import Supported from "./parts/supported/FooterCasperSupported";
import CasperCopyright from "./parts/copyright/Copyright";
import FooterCasperSocial from "./parts/social/FooterCasperSocial";
import FooterCasperLinks from "./parts/links/FooterCasperLinks";

const FooterCasperLicence = () => {
    return (
        <VStack spacing={'32px'} align={{ base: "center", lg: "end" }}>
            <Supported />
            <FooterCasperSocial />
            <FooterCasperLinks />
            <CasperCopyright />
        </VStack>
    );
};

export default FooterCasperLicence;
