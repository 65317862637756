import { Box, Divider, Flex, Image, VStack } from '@chakra-ui/react';
import AppTypography from 'components/common/typography/AppTypography';
import { useShopStore } from 'lib/stores/shop/shop';
import { useContext } from 'react';
import homepageDefaultContext from '../../context';
import SidebarSearch from './parts/search/SidebarSearch';
import HomepageSocial from './parts/social/SidebarSocial';

function HomepageSidebar() {
  const { shopData: { logo, description } } = useShopStore();
  const { States: { theme: { sidebarBoxes, textColor } } } = useContext(homepageDefaultContext)

  return (
    <VStack align="stretch" spacing="26px">
      <Flex {...sidebarBoxes} flexDirection="column" borderRadius="8px" gap="16px" padding={{ base: "20px", sm: "40px 20px" }}>
        <Flex justifyContent="center">
          <Box position={"relative"} width={{ base: "80px", sm: "130px" }} height={{ base: "80px", sm: "130px" }} borderRadius={"50%"} overflow={"hidden"} >
            <Image src={logo} width={"100%"} height={"auto"} objectFit={"cover"} position={"absolute"} top={"50%"} left={"50%"} transform={"translate(-50%, -50%)"} />
          </Box>
        </Flex>
        <AppTypography textAlign="center" fontWeight="bold" fontSize="18px">{description}</AppTypography>
        <Box><HomepageSocial /></Box>
      </Flex>
      <Divider borderColor={textColor} opacity=".15" />
      <SidebarSearch />
    </VStack>
  )
}

export default HomepageSidebar