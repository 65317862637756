import React, { useContext, useMemo } from 'react'
import productModel from 'pages/_refactors/homepage/parts/content/parts/products/parts/product/model'
import productPageContext from 'pages/_refactors/product/context'
import ProductDetailTitles from '../titles/ProductDetailTitles'
import { IoCheckmarkSharp } from "react-icons/io5";
import { Box, Flex, VStack, Icon } from '@chakra-ui/react';
import AppTypography from 'components/common/typography/AppTypography';
import { useShopStore } from 'lib/stores/shop/shop';

function ProductColors() {
    const { states: { product, option: { color } }, methods: { updateOption } } = useContext(productPageContext)
    const { shopDesign: { foreground }} = useShopStore()

    const colors = useMemo(() => productModel.getOptions({ skuIDs: product?.skuIDs, type: "color" }), [product])

    return (
        <>
            {colors.length ? (
                <VStack align="stretch">
                    <ProductDetailTitles text='Color' />
                    <AppTypography fontSize={{ base: "14px", sm: "16px" }}>{color}</AppTypography>
                    <Flex flexWrap="wrap" gap="10px">
                        {colors.map((el, key) => (
                            <Flex key={key} width={{ base: "30px", sm: "40px", lg: "50px" }} onClick={() => updateOption('color', el.caption)} height={{ base: "30px", sm: "40px", lg: "50px" }} justifyContent="center" alignItems="center" backgroundColor={el.value} border={foreground} padding={{ base: "0", sm: "10px 20px" }} cursor="pointer" borderRadius="100px">
                                {el.caption === color ? <Icon as={IoCheckmarkSharp} width="21px" height="21px" color={el.value === "#ffffff" ? "#333" : "#FFF"} /> : null}
                            </Flex>
                        ))}
                    </Flex>
                </VStack>
            ) : null}
        </>
    )
}

export default ProductColors