import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

import { IShop, IShopDesign } from "lib/models/shop";
import {
  convertToShopModel,
  IPaymentMethods,
  ShopThemeEnum,
  IShopDesignPrev,
} from "lib/models/shop";

interface IShopStore {
  shopData: IShop;
  paymentMethods: IPaymentMethods[];
  shopTheme: ShopThemeEnum;
  shopDesign: IShopDesign;
  loading: boolean;
  setShopData: (data: IShop) => void;
  clearShopData: () => void;
  setLoading: (arg: boolean) => void;
}

let store: any = (set: any): IShopStore => ({
  shopData: convertToShopModel({}),
  paymentMethods: [],
  shopTheme: ShopThemeEnum.THEME_2,
  loading: false,
  shopDesign: convertToShopModel({}).shopDesign,
  setLoading: (value) => set({ loading: value }),
  setShopData: (data: IShop) => {
    set({
      loading: false,
      shopTheme: data.theme,
      shopDesign: data.shopDesign,
      paymentMethods: data.paymentMethods,
      shopData: data,
    });
  },
  clearShopData: () => set({ shopData: convertToShopModel({}) }),
});

store = persist(store, { name: "new-shop" });
store = devtools(store);

export const useShopStore = create<IShopStore>(store);
