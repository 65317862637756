export enum ImsTypeEnum {
    SHOPIFY = "SHOPIFY",
    DROPLINKED = "DROPLINKED",
}

export enum ProductTypeEnum {
    DIGITAl = "DIGITAL",
    PRINT_ON_DEMAND = "PRINT_ON_DEMAND",
}

export enum ShopThemeEnum {
    THEME_1 = "theme-1",
    THEME_2 = "theme-2",
    THEME_SKALE = "theme-skale",
    THEME_UNSTOPPABLE = "theme-unstoppable",
    THEME_CASPER = "theme-casper",
    THEME_POLYGON = "THEME_POLYGON",
}

export enum SocialMediasEnum {
    INSTAGRAM = "INSTAGRAM",
    DISCORD = "DISCORD",
    TIKTOK = "TIKTOK",
    LINKDIN = "LINKDIN",
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
    WEB = "WEB",
}

export interface    IShopMedia {
    mediaType: SocialMediasEnum;
    address: string;
}

export enum PaymentsTypeEnum {
    STRIPE = "STRIPE",
    CASPER = "CASPER",
    STACKS = "STACKS",
    RIPPLE = "RIPPLE",
    XRPLSIDECHAIN = "XRPLSIDECHAIN",
    POLYGON = "POLYGON",
    BINANCE = "BINANCE",
    BASE = 'BASE',
    NEAR = 'NEAR',
    LINEA = 'LINEA'
}

export interface IPaymentMethods {
    isActive: boolean;
    type: PaymentsTypeEnum;
    destinationAddress?: string;
}

export interface IShopDesignPrev {
    textColor: string;
    logo: string;
    headerIcon: string;
    backgroundText: string;
    backgroundColor: string;
    backgroundImage: string;
    backgroundImageSecondary: string;
    fullWidthHero?: boolean;
}

export interface IShopDesign {
    fontfamily: FontFamilyEnum;
    headerBackground: string;
    hiroLayout: string;
    hiroTextColor: string;
    footerLinks: IFooterLink[];
    bannerLinks: IBannerLink[];
    iconHeaderColor: string;
    backgroundBody: string;
    foreground: string;
    textColorParagraphs: string;
    productListTitle: string;
}

enum FontFamilyEnum {
    Montserrat = "Montserrat",
    Manrope = "Manrope",
    Source_Serif_Pro = "Source Serif Pro",
    Fredoka_One = "Fredoka One",
    Nunito_Sans = "Nunito Sans",
    Allerta = "Allerta",
}

interface IFooterLink {
    caption: string;
    link: string;
}

interface IBannerLink {
    caption: string;
    link: string;
}

export interface ITemplateOptions {
    "--dlk-hdr": IDlkHeader;
    "--dlk-wrp": IDlkWrapper;
    "--dlk-ftr": IDlkFooter;
}

interface IDlkHeader {
    "--dlk-hdr-styles": any;
    "--dlk-hdr-container": any;
    "--dlk-hdr-logo": any;
    "--dlk-hdr-icons": IDlkHeaderIcons;
}

interface IDlkHeaderIcons {
    "--dlk-hdr-icons-styles": any;
    "--dlk-hdr-icons-profile": any;
    "--dlk-hdr-icons-cart": any;
    "--dlk-hdr-icons-notification": any;
}

interface IDlkWrapper {
    "--dlk-wrp-styles": any;
    "--dlk-wrp-hiro": IDlkHiro;
}

interface IDlkHiro {
    "--dlk-wrp-hiro-styles": any;
    "--dlk-wrp-hiro-image": any;
    "--dlk-wrp-hiro-caption": any;
}

interface IDlkFooter {
    "--dlk-ftr-styles": any;
    "--dlk-ftr-logo": any;
    "--dlk-ftr-description": any;
}

export interface IShop {
    addressBookID: string | null;
    backgroundColor: string;
    backgroundImage: string;
    backgroundImageSecondary: string;
    backgroundText: string;
    description: string;
    discordURL: string | null;
    headerIcon: string;
    imsType: ImsTypeEnum;
    instagramURL: string | null;
    logo: string;
    name: string;
    ownerID: string;
    tiktokURL: string | null;
    linkedinURL: string | null;
    facebookURL: string | null;
    paymentMethods: IPaymentMethods[];
    productsTags: string | null;
    textColor: string;
    theme: string;
    shopifyDomain: string | null;
    twitterURL: string | null;
    webURL: string | null;
    infoEmail: string | null;
    socialMedias: IShopMedia[];
    productSectionText: string;
    _id: string;
    fullWidthHero: boolean;
    design: IShopDesignPrev;
    shopDesign: IShopDesign;
    template_options: ITemplateOptions;
}

const defaultBgImage = "https://upload-file-flatlay.s3.us-west-2.amazonaws.com/32f35e8e174a78f5070143ef5a3731887925c4890e7a08180c9c0a8a089a8f8b.jpg_or.jpg";

const getSocialsMedia = (data: any) => {
    let resultArray = [];
    if (data?.linkedinURL)
        resultArray.push({
            mediaType: SocialMediasEnum.LINKDIN,
            address: `https://www.linkedin.com/in/${data?.linkedinURL}`,
        });
    if (data?.discordURL)
        resultArray.push({
            mediaType: SocialMediasEnum.DISCORD,
            address: `https://discord.com/invite/${data?.discordURL}`,
        });
    if (data?.instagramURL)
        resultArray.push({
            mediaType: SocialMediasEnum.INSTAGRAM,
            address: `https://www.instagram.com/${data?.instagramURL}`,
        });
    if (data?.tiktokURL)
        resultArray.push({
            mediaType: SocialMediasEnum.TIKTOK,
            address: `https://tiktok.com/@${data?.tiktokURL}`,
        });

    if (data?.facebookURL)
        resultArray.push({
            mediaType: SocialMediasEnum.FACEBOOK,
            address: `https://www.facebook.com/${data?.facebookURL}`,
        });

    if (data?.twitterURL)
        resultArray.push({
            mediaType: SocialMediasEnum.TWITTER,
            address: `https://twitter.com/${data?.twitterURL}`,
        });
    if (data?.webURL)
        resultArray.push({
            mediaType: SocialMediasEnum.WEB,
            address: `https://${data?.webURL}`,
        });

    return resultArray;
};

export const convertToShopModel: (dataa: any) => IShop = (dataa: any) => {
    let data;
    if(dataa.theme === ShopThemeEnum.THEME_1) data = {...dataa, theme: ShopThemeEnum.THEME_2}
    else { data = dataa }
    const socialMediasArray: IShopMedia[] = getSocialsMedia(data);
    const shop: IShop = {
        addressBookID: data?.addressBookID || null,
        backgroundColor: data?.backgroundColor || "#000",
        backgroundImage: data?.backgroundImage || defaultBgImage,
        backgroundImageSecondary: data?.backgroundImageSecondary || defaultBgImage,
        backgroundText: data?.backgroundText || "",
        description: data?.description || "",
        fullWidthHero: data?.fullWidthHero || false,
        discordURL: data?.discordURL || null,
        headerIcon: data?.headerIcon || "",
        imsType: data?.imsType || ImsTypeEnum.DROPLINKED,
        instagramURL: data?.instagramURL || null,
        logo: data?.logo || "",
        name: data?.name || "",
        ownerID: data?.ownerID || "",
        tiktokURL: data?.tiktokURL || null,
        linkedinURL: data?.linkedinURL || null,
        facebookURL: data?.facebookURL || null,
        paymentMethods: data?.paymentMethods || [],
        productsTags: data?.productsTags || null,
        textColor: data?.textColor || "#fff",
        theme: data?.theme || ShopThemeEnum.THEME_2,
        shopifyDomain: data?.shopifyDomain || "",
        twitterURL: data?.twitterURL || null,
        socialMedias: socialMediasArray,
        webURL: data?.webURL || null,
        infoEmail: data?.infoEmail || null,
        shopDesign: {
            fontfamily: data?.shopDesign?.fontfamily?.length > 0 ? data?.shopDesign?.fontfamily : "Nunito Sans",
            headerBackground: data?.shopDesign?.headerBackground.length > 0 ? data?.shopDesign?.headerBackground : '',
            hiroLayout: data?.shopDesign?.hiroLayout?.length > 0 ? data?.shopDesign?.hiroLayout : "center_text",
            hiroTextColor: data?.shopDesign?.hiroTextColor?.length > 0 ? data?.shopDesign?.hiroTextColor : "#8880fb",
            productListTitle: data?.shopDesign?.productListTitle?.length > 0 ? data?.shopDesign?.productListTitle : undefined,
            backgroundBody: data?.shopDesign?.backgroundBody?.length > 0 ? data?.shopDesign?.backgroundBody : "#11151A",
            foreground: data?.shopDesign?.foreground?.length > 0 ? data?.shopDesign?.foreground : "#262738",
            textColorParagraphs: data?.shopDesign?.textColorParagraphs?.length > 0 ? data?.shopDesign?.textColorParagraphs : "#F9F9F6",
            iconHeaderColor: data?.shopDesign?.iconHeaderColor?.length > 0 ? data?.shopDesign?.iconHeaderColor : "#584b4b",
            footerLinks: data?.shopDesign?.footerLinks?.length > 0 ? data?.shopDesign?.footerLinks : [],
            bannerLinks: data?.shopDesign?.bannerLinks?.length > 0 ? data?.shopDesign?.bannerLinks : [],
        },
        template_options: data?.template_options || null,
        productSectionText: data?.productSectionText || "",
        _id: data?._id || "",
        design: {
            textColor: data?.textColor || "#fff",
            logo: data?.logo || "",
            headerIcon: data?.headerIcon || "",
            backgroundText: data?.backgroundText || "",
            backgroundColor: data?.backgroundColor || "#000",
            backgroundImage: data?.backgroundImage || defaultBgImage,
            backgroundImageSecondary: data?.backgroundImageSecondary || defaultBgImage,
            fullWidthHero: data?.fullWidthHero,
        },
    };
    return shop;
};

export const convertToShopDataModel = (arg: any) => {
    let obj = arg ?? {};
    return {
        addressBookID: obj?.addressBookID || "",
        backgroundColor: obj?.backgroundColor || "#000",
        backgroundImage: obj?.backgroundImage || "https://upload-file-flatlay.s3.us-west-2.amazonaws.com/32f35e8e174a78f5070143ef5a3731887925c4890e7a08180c9c0a8a089a8f8b.jpg_or.jpg",
        backgroundImageSecondary: obj?.backgroundImageSecondary || "https://upload-file-flatlay.s3.us-west-2.amazonaws.com/32f35e8e174a78f5070143ef5a3731887925c4890e7a08180c9c0a8a089a8f8b.jpg_or.jpg",
        backgroundText: obj?.backgroundText || "",
        createdAt: obj?.createdAt || "",
        description: obj?.description || "",
        discordURL: obj?.discordURL || null,
        headerIcon: obj?.headerIcon || "",
        imsType: obj?.imsType || "DROPLINKED",
        imsTypeUpdated: obj?.imsTypeUpdated || false,
        instagramURL: obj?.instagramURL || null,
        logo: obj?.logo || "",
        name: obj?.name || "",
        ownerID: obj?.ownerID || "",
        tiktokURL: obj?.tiktokURL || null,
        linkedinURL: obj?.linkedinURL || null,
        facebookURL: obj?.facebookURL || null,
        paymentMethods: obj?.paymentMethods || [],
        productsTags: obj?.productsTags || [],
        textColor: obj?.textColor || "#fff",
        theme: obj?.theme || "theme-2",
        shopifyDomain: obj?.shopifyDomain || "",
        twitterURL: obj?.twitterURL || null,
        updatedAt: obj?.updatedAt || null,
        webURL: obj?.webURL || "",
        infoEmail: obj?.infoEmail || "",
        __v: obj?.__v || 0,
        _id: obj?._id || "",
    };
};
