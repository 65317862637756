import { createContext } from "react";

export interface IproductPageState {
    product: any
    option: {
        color: string | null
        size: string | null
        quantity: number
    }
    sku: any
    ruleset: {
        loading: boolean
        data: any
    }
}
export const productPageState: IproductPageState = {
    product: null,
    option: {
        color: null,
        size: null,
        quantity: 1
    },
    sku: null,
    ruleset: {
        loading: false,
        data: false
    }
}

interface IProps {
    states: IproductPageState
    methods: {
        updateState: Function
        updateOption: Function
    }
}

const productPageContext = createContext<IProps>({
    states: productPageState,
    methods: {
        updateOption: () => { },
        updateState: () => { }
    }
})

export default productPageContext