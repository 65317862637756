import { CasperClient, CasperServiceByJsonRPC } from "casper-js-sdk";
import { appDeveloment } from "lib/utils/app/variables";

let isTestnet = process.env.REACT_APP_CASPER_NETWORK == "Testnet";

export const apiUrl = `https://${
  appDeveloment ? "apiv3dev.droplinked.com" : "apiv3.droplinked.com"
}/http-req?method=post&url=http://${
  isTestnet ? "188.40.47.161" : "45.14.135.77"
}`;
export const metadata_uref = "metadatas";
export const request_objects_uref = "request_objects";
export const producer_requests_uref = "producer_requests";
export const publisher_requests_uref = "publiser_requests";
export const holders_uref = "holders";
export const owners_dict_uref = "owners";
export const producers_approved_dict_uref = "producers_approved";
export const publishers_approved_dict_uref = "publishers_approved";
export const publishers_rejected_dict_uref = "publisher_rejects";
export const approved_dict_uref = "approved";
export const token_id_by_metadata_hash = "token_id_by_hash";
export const network = isTestnet ? "casper-test" : "casper";
export const casperService = new CasperServiceByJsonRPC(apiUrl + ":7777/rpc");
export const casperClient = new CasperClient(apiUrl + ":7777/rpc");
