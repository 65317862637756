import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  // breakpoints: {
  //   sm: "481px",
  //   md: "768px",
  //   lg: "992px",
  //   xl: "1200px",
  // },
  colors: {
    white: {
      100: "#ffffff",
      200: "#ffffff",
      300: "#ffffff",
      400: "#ffffff",
      500: "#ffffff",
      600: "#cccccc",
      700: "#999999",
      800: "#666666",
      900: "#333333",
    },
  },
});
