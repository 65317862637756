import { Flex, VStack, Icon, Box, HStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import React, { useContext } from 'react'
import productPageContext from '../../context'
import ProductButton from './parts/button/ProductButton'
import ProductColors from './parts/colors/ProductColors'
import ProductGated from './parts/gated/ProductGated'
import ProductPrice from './parts/price/ProductPrice'
import ProductQuantity from './parts/quantity/ProductQuantity'
import ProductSizes from './parts/sizes/ProductSizes'

function ProductDetails() {
  const { states: { product} } = useContext(productPageContext)

  return (
    <VStack align="stretch" spacing={{ base: "10px", sm: "20px", lg: "25px" }}>
      <VStack align="stretch" spacing={{ base: "6px", sm: "8px", lg: "10px" }}>
        <AppTypography fontSize={{ base: "24px", sm: "30px", lg: "48px" }} fontWeight="800">{product?.title}</AppTypography>
        <ProductPrice />
        <Box><ProductGated /></Box>
      </VStack>
      <Box><ProductColors /></Box>
      <Box><ProductSizes /></Box>
      <Box><ProductQuantity /></Box>
      <Box><ProductButton /></Box>
    </VStack>
  )
}

export default ProductDetails