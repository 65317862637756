import { ShopThemeEnum } from "lib/models/shop"

namespace mintPositionModel {
    const defaults = {
        borderColor: "#FFF"
    }
    export const themes = {
        [ShopThemeEnum.THEME_UNSTOPPABLE]: { borderColor: "#999" },
        [ShopThemeEnum.THEME_SKALE]: { borderColor: "#999" },
        [ShopThemeEnum.THEME_POLYGON]: { borderColor: "#999" },
        [ShopThemeEnum.THEME_1]: defaults,
        [ShopThemeEnum.THEME_2]: defaults,
        [ShopThemeEnum.THEME_CASPER]: { borderColor: "#999" },
    }

    export const isUploadFile = (source: string) => source === 'Upload File'
}

export default mintPositionModel