import { ShopThemeEnum } from "lib/models/shop"

namespace mintPositionUploadModel {
    export const icon = (color: string) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3437_23946)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16129 14.25C1.80265 14.25 2.32258 14.7537 2.32258 15.375V19.125C2.32258 20.5747 3.53574 21.75 5.03226 21.75H18.9677C20.4643 21.75 21.6774 20.5747 21.6774 19.125V15.375C21.6774 14.7537 22.1973 14.25 22.8387 14.25C23.4801 14.25 24 14.7537 24 15.375V19.125C24 21.8174 21.747 24 18.9677 24H5.03226C2.25302 24 0 21.8174 0 19.125V15.375C0 14.7537 0.519927 14.25 1.16129 14.25Z" fill={color} />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.07813 7.78852L11.2078 0.342685C11.6453 -0.114228 12.3547 -0.114228 12.7922 0.342685L19.9219 7.78852C20.3594 8.24543 20.3594 8.98623 19.9219 9.44315C19.4844 9.90006 18.775 9.90006 18.3375 9.44315L13.1203 3.99463V17.625C13.1203 18.2712 12.6187 18.795 12 18.795C11.3813 18.795 10.8797 18.2712 10.8797 17.625V3.99463L5.66251 9.44315C5.225 9.90006 4.51565 9.90006 4.07813 9.44315C3.64062 8.98623 3.64062 8.24543 4.07813 7.78852Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_3437_23946">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default mintPositionUploadModel