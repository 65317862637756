import { isCustomDomain } from "lib/utils/theme/theme-utils";
import { useParams } from "react-router-dom";

/**
Custom Hook: useCustomParams
Description: This hook is used to return shopName and shopName change
Call the hook in a functional component: const { shopName, isCustomDomain } = useCustomParams();
*/

type UseCustomParamsHook = () => { shopName: string };

export const useCustomParams: UseCustomParamsHook = () => {
  const param = useParams();

  const getShopName = () => {
    switch (window.location.hostname) {
      // case "specialshoptest.droplinked.io":
      //   return "casper";
      case "shop.unstoppablrdomains.com":
        return "unstoppable";
      case "unstoppabledomains.droplinked.com":
        return "unstoppable";
      case "shop.unstoppabledomains.com":
        return "unstoppable";
      case "unstoppabledomain.droplinked.com":
        return "unstoppable";
      case "www.shop.unstoppabledomains.com":
        return "unstoppable";
      case "shop.casperpunks.io":
        return "casper";
      case "casper.droplinked.com":
        return "casper";
      case "localhost":
        return "casper";
      default:
        return "unstoppable";
    }
  };
  let shopName: string = isCustomDomain ? getShopName() : param.shopName?.toLowerCase() || "";
  return { shopName };
};
