import { Link, Stack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";

const routes = [
    { text: "TERMS OF US", to: "privacy" },
    { text: "PRIVACY POLICY", to: "return-policy" },
    { text: "FAQ", to: "return-policy" },
];
const FooterCasperLinks = () => {
    const { shopNavigate } = useCustomNavigate();
    return (
        <Stack direction={{ base: "column", lg: "row" }} align={{ base: "center", lg: "end" }}>
            {routes.map((route, index) => (
                <Link key={index} onClick={() => shopNavigate(route.to)}>
                    <AppTypography fontFamily="Montserrat" fontWeight="700" fontSize="15px" color="#FFF">{route.text}</AppTypography>
                </Link>
            ))}
        </Stack>
    );
};

export default FooterCasperLinks;
