import { BoxProps, DividerProps, FlexProps, TextProps } from "@chakra-ui/react"
import { ShopThemeEnum } from "lib/models/shop"

interface Itheme {
    seprator?: DividerProps
    box?: BoxProps
    text?: TextProps
}

interface IthemesOutput {
    [propname: string]: Itheme
}

namespace purchaseDefaultModel {
    const theme: IthemesOutput = {
        default: {
            text: {
                fontSize: "16px",
                fontWeight: "600"
            }
        },
        custom: {
            seprator: {
                borderColor: "#DDD"
            },
            box: {
                backgroundColor: "#FFF",
                borderRadius: "16px",
                padding: { base: "20px", sm: "32px" }
            },
            text: {
                fontSize: { base: "13px", sm: "15px" },
                fontWeight: "bold"
            }
        },
    }
    export const themes: IthemesOutput = ({
        [ShopThemeEnum.THEME_1]: theme.default,
        [ShopThemeEnum.THEME_2]: theme.default,
        [ShopThemeEnum.THEME_SKALE]: theme.custom,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: theme.custom,
        [ShopThemeEnum.THEME_CASPER]: theme.custom,
        [ShopThemeEnum.THEME_POLYGON]: theme.custom,
    })
}

export default purchaseDefaultModel