export interface IOrdersData {
    currentPage: number;
    data: IOrderItem[];
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    limit: number;
    nextPage: number | null;
    previousPage: number | null;
    totalDocuments: number;
    totalPages: number;
}

export interface IOrderItem {
    orderId: string;
    quantity: number;
    totalPrice: number;
    date: string;
}

interface ISelectedShipment {
    type: string;
    id: string;
    title: string;
    price: string;
    delivery_estimation: string;
}

interface IStatusHistory {
    status: string;
    type: ORDER_STATUS_ENUM;
}

export enum ORDER_STATUS_ENUM {
    PAYMENT_CONFIRMED = "PAYMENT_CONFIRMED",
    WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
    WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION",
    INITIALIZED_FOR_PAYMENT = "INITIALIZED_FOR_PAYMENT",
    PROCESSING = "PROCESSING",
    SENT = "SENT",
    CANCELED = "CANCELED",
    CANCELED_PAYMENT_TIMEOUT = "CANCELED_PAYMENT_TIMEOUT",
    REFUNDED = "REFUNDED",
    IN_CART = "IN_CART",
}

export interface IOrderSingleItem {
    productId: string;
    image: string;
    title: string;
    size: {
        title: string;
        value: string;
    };
    color: {
        title: string;
        value: string;
    };
    quantity: string;
    price: number;
}



export interface IOrderItemDetail {
    shipping: string;
    items: number;
    tax: number;
    totalCost: number;
    status: ORDER_STATUS_ENUM;
}

export interface IOrderData {
    items: IOrderSingleItem[];
    details: IOrderItemDetail;
}
