import { HStack } from "@chakra-ui/react";
import { ICartStoreItem } from "lib/stores/_refactor/cart/interfaces";
import CartPageQuantity from "../../../../../parts/quantity/CartPageQuantity";
import CartPageMobileItemDetailEach from "./parts/eachDetail/CartPageMobileItemDetailEach";

const CartPageMobileItemDetails = ({ cartItem }: { cartItem: ICartStoreItem }) => {
    return (
        <HStack justify={"space-between"} w={'full'} align='start'>
            <CartPageMobileItemDetailEach title="Quantity" value={<CartPageQuantity cartItem={cartItem} />} />
            <CartPageMobileItemDetailEach title="Cost Per Unit" value={`$${cartItem?.totals.priceItem ? cartItem?.totals.priceItem.toFixed(2) : 0} USD`} />
            <CartPageMobileItemDetailEach title="Total" value={`$${cartItem?.totals.subTotal ? cartItem?.totals.subTotal.toFixed(2) : 0} USD`} />
        </HStack>
    );
};

export default CartPageMobileItemDetails