import { Flex } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { useContext } from "react";
import orderModalContext from "../../context";
import PaymentDetailsTextComponent from "./parts/textComponent/PaymentDetailsTextComponent";

const PurchaseHistoryPaymentDetails = () => {
    const order = useContext(orderModalContext)

    return (
        <Flex direction={"column"} gap={"24px"}>
            <AppTypography width='full' textAlign={'left'} fontSize={{ base: "16px", md: "18px" }} fontWeight={'bold'}>Payment Details</AppTypography>
            <Flex direction={"column"} gap={"18px"}>
                <PaymentDetailsTextComponent title={"Items"} value={`$ ${order?.itemsAmount.toFixed(2)} USD`} />
                {order?.shipping !== undefined && <PaymentDetailsTextComponent title={"Shipping"} value={`$ ${order.shipping.toFixed(2)} USD`} />}
                {order?.orderTax !== undefined && <PaymentDetailsTextComponent title={"Order Tax"} value={`$ ${order.orderTax.toFixed(2)} USD`} />}
                <PaymentDetailsTextComponent title={"Total Cost"} value={`$ ${order?.totalCost.toFixed(2)} USD`} />
                {order?.totalCryptoPayment !== undefined && <PaymentDetailsTextComponent title={"Total Crypto Payment"} value={`$ ${order.totalCryptoPayment.toFixed(2)}`} />}
            </Flex>
            <AppTypography fontSize={"12px"}>
                Payment with {order?.paidWith}
            </AppTypography>
        </Flex>
    );
};

export default PurchaseHistoryPaymentDetails;
