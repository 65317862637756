import React, { useContext } from 'react'
import { Flex, Link } from '@chakra-ui/react'
import { useShopStore } from 'lib/stores/shop/shop';
import { FiInstagram, FiTwitter, FiGlobe, FiMail } from "react-icons/fi";
import { GrLinkedinOption, GrFacebookOption } from "react-icons/gr";
import { RxDiscordLogo } from "react-icons/rx";
import { BsTiktok } from "react-icons/bs";
import CustomIcon from 'components/common/customIcon/customIcon';
import homepageDefaultContext from '../../../../context';

function SidebarSocial() {
    const { States: { theme: { textColor } } } = useContext(homepageDefaultContext)
    const { shopData: { discordURL, instagramURL, facebookURL, infoEmail, linkedinURL, tiktokURL, twitterURL, webURL } } = useShopStore();

    const social = [
        {
            link: 'http://discord.gg/',
            value: discordURL,
            icon: RxDiscordLogo
        },
        {
            link: 'http://instagram.com/',
            value: instagramURL,
            icon: FiInstagram
        },
        {
            link: 'http://facebook.com/',
            value: facebookURL,
            icon: GrFacebookOption
        },
        {
            link: 'mailto:',
            value: infoEmail,
            icon: FiMail
        },
        {
            link: 'http://linkedin.com/',
            value: linkedinURL,
            icon: GrLinkedinOption
        },
        {
            link: 'http://tiktok.com/',
            value: tiktokURL,
            icon: BsTiktok
        },
        {
            link: 'http://twitter.com/',
            value: twitterURL,
            icon: FiTwitter
        },
        {
            link: 'http://',
            value: webURL,
            icon: FiGlobe
        },
    ]

    return (
        <Flex justifyContent="center">
            <Flex align="stretch" width="80%" justifyContent="center" gap={{ base: "16px", xl: "15px 24px" }} flexWrap="wrap">
                {social.filter(el => el.value).map((el, key) => (
                    <Link key={key} href={el.value ? el.link + el.value : ""} target="_blank"><CustomIcon as={el.icon} w="24px" h="24px" color={textColor} /></Link>
                ))}
            </Flex>
        </Flex>
    )
}

export default SidebarSocial