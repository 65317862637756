import { Box, Flex, VStack } from '@chakra-ui/react'
import React from 'react'
import HeaderIcons from '../../../../icons/HeaderIcons'
import NavigationCasper from '../../parts/navigation/NavigationCasper'
import CasperTopbar from '../../parts/topbar/CasperTopbar'
import { LuShoppingCart, LuUser2 } from "react-icons/lu";
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import { Link } from 'react-router-dom'

function HeaderDesktop() {
    const { route } = useCustomNavigate()

    return (
        <VStack align="stretch" >
            <CasperTopbar />
            <Flex justifyContent="space-between" alignItems="center" backgroundColor="#ad203e" paddingRight={{ base: "2%", lg: "4%", xl: "8%" }}>
                <Flex width="80%" alignItems="center" gap="3%">
                    <Flex backgroundColor="#161f2f" fontFamily="Montserrat, sans-serif" fontWeight="700" fontSize={{ base: "13px", lg: "16px", xl: "23px" }} textTransform="uppercase" color="#FFF" padding="17px 30px" width="25%" flexDirection="row-reverse"><Link to={route('')}>Marketplace</Link></Flex>
                    <NavigationCasper />
                </Flex>
                <Box>
                    <HeaderIcons
                        icons={{
                            cart: LuShoppingCart,
                            profile: LuUser2,
                        }}
                        color="#000"
                        props={{ icon: { width: { base: "35px", lg: "45px" }, height: { base: "35px", lg: "45px" } } }}
                    />
                </Box>
            </Flex>
        </VStack>
    )
}

export default HeaderDesktop