import { IShop, ShopThemeEnum } from "lib/models/shop";
import { ORDER_STATUS_ENUM } from "pages/_refactors/history/interface";

namespace purchaseHistoryModel {
    export const design = (shopTheme: ShopThemeEnum, { shopDesign }: IShop) => {
        const defaults = { background: shopDesign?.foreground, color: shopDesign?.textColorParagraphs };
        const light = { background: "#F9F9F6", color: "#262626" };
        const themes = {
            [ShopThemeEnum.THEME_UNSTOPPABLE]: light,
            [ShopThemeEnum.THEME_SKALE]: light,
            [ShopThemeEnum.THEME_POLYGON]: light,
            [ShopThemeEnum.THEME_1]: defaults,
            [ShopThemeEnum.THEME_2]: defaults,
            [ShopThemeEnum.THEME_CASPER]: light,
        };
        return themes[shopTheme];
    };
}

export default purchaseHistoryModel;
