import { Hide, Show, VStack } from "@chakra-ui/react";
import useAppCart from "hooks/_refactor/cart/useAppCart";
import CartPageButtonGroups from "./parts/buttons/CartPageButtonGroups";
import CartPageEmpty from "./parts/empty/CartPageEmpty";
import CartPageHeader from "./parts/header/CartPageHeader";
import CartPageDesktop from "./parts/screens/desktop/CartPageDesktop";
import CartPageMobile from "./parts/screens/mobile/CartPageMobile";
import CartPageSubtotal from "./parts/subtotal/CartPageSubtotal";

const CartPage = () => {
    const { countItems } = useAppCart();
    return (
        <VStack width={'full'} align={'stretch'} py={12} justifyContent="center" spacing={'32px'}>
            {countItems ? (
                <>
                    <CartPageHeader />
                    <Hide below="md"><CartPageDesktop /></Hide>
                    <Show breakpoint='(max-width: 768px)'><CartPageMobile /></Show>
                    <CartPageSubtotal />
                    <CartPageButtonGroups />
                </>
            ) : (
                <CartPageEmpty />
            )}
        </VStack>
    );
};

export default CartPage;
