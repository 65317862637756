import { Flex, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography';
import React, { useContext, useMemo } from 'react'
import paymentSuccessContext from '../../context';

function PaymentTotals() {
    const { order } = useContext(paymentSuccessContext)

    const total = useMemo(() => [
        {
            caption: 'Items',
            value: order?.details?.items
        },
        {
            caption: 'Shipping',
            value: order?.details?.shipping
        },
        {
            caption: 'Order Tax',
            value: order?.details?.tax
        },
        {
            caption: 'Discount / Gift card',
            value: order?.details?.giftCard
        },
        {
            caption: 'Total Cost',
            value: order?.details?.totalCost
        },
    ], [order])

    return (
        <VStack align="stretch" spacing="20px">
            <AppTypography fontSize="16px" fontWeight="600">Payment details</AppTypography>
            <VStack align="stretch" spacing="15px">
                {total.filter(el => el.value !== undefined).map((el, key) => (
                    <Flex key={key} justifyContent="space-between">
                        <AppTypography fontSize="14px">{el.caption}</AppTypography>
                        <AppTypography fontSize="14px">${parseFloat(el.value).toFixed(2)} USD</AppTypography>
                    </Flex>
                ))}
            </VStack>
        </VStack>
    )
}

export default PaymentTotals