import { Flex, Img, Radio, RadioGroup, VStack } from '@chakra-ui/react'
import AppSeperator from 'components/common/seperator/AppSeperator'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'

function PaymentCustomOptions() {
    return (
        <VStack align="stretch">
            <AppTypography fontSize={{ base: "18px", sm: "24px" }} fontWeight="bold">Payment Options</AppTypography>
            <VStack align="stretch" padding="24px" spacing="18px" backgroundColor="#FFF" border="2px solid rgb(239, 236, 220)" borderRadius="18px">
                <AppTypography fontSize={{ base: "16px", sm: "18px" }} fontWeight="bold">Online Payment</AppTypography>
                <AppSeperator borderColor="#DDD" />
                <RadioGroup value='stripe'>
                    <Flex justifyContent="space-between" gap="20px">
                        <Radio value='stripe'><AppTypography fontSize={{ base: "14px", sm: "16px" }}>Secure payment using Stripe</AppTypography></Radio>
                        <Img src="/assets/images/stripe.svg" width={{ base: "50px", sm: "80px" }} />
                    </Flex>
                </RadioGroup>
            </VStack>
        </VStack>
    )
}

export default PaymentCustomOptions