import productPageContext from 'pages/_refactors/product/context'
import React, { useContext } from 'react'
import { Box, Flex, Radio, RadioGroup, SimpleGrid, VStack } from '@chakra-ui/react'
import mintToMerchContext from '../../../context'
import AppButton from 'components/common/form/button/AppButton'
import mintPositionModel from './model'
import { useShopStore } from 'lib/stores/shop/shop'
import MintPositionUpload from './parts/upload/MintPositionUpload'
import MintPositionRemove from './parts/removebg/MintPositionRemove'
import productButtonModel from '../../../../button/model'

function MintPosition() {
    const { states: { product } } = useContext(productPageContext)
    const { states: { position, artwork, source }, methods: { updateStates } } = useContext(mintToMerchContext)
    const { themes, isUploadFile } = mintPositionModel
    const { shopTheme } = useShopStore();
    const { themeIsLoged } = productButtonModel

    return (
        <VStack align="stretch" spacing="30px">
            <RadioGroup onChange={(e) => updateStates('position', e)} value={position}>
                <SimpleGrid columns={2} spacing="12px">
                    {product?.m2m_positions.map((el: any, key: number) => (
                        <Box border="1px solid #FFF" {...themes[shopTheme]} borderRadius="8px" padding="10px">
                            <Radio value={el?.placement} width="100px" key={key}>{el?.placement}</Radio>
                        </Box>
                    ))}
                </SimpleGrid>
            </RadioGroup>
            {isUploadFile(source) ? <MintPositionUpload /> : null}
            <MintPositionRemove />
            <Flex justifyContent="space-between" direction="row-reverse">
                <AppButton size="sm" paddingRight="20px" paddingLeft="20px" isDisabled={!Boolean(position.length) || (isUploadFile(source) ? !Boolean(artwork.length) : false)} onClick={() => updateStates('step', isUploadFile(source) ? 'preview' : 'artwork')}>Next</AppButton>
                {!themeIsLoged(shopTheme) ? <AppButton paddingRight="20px" paddingLeft="20px" size="sm" onClick={() => updateStates('step', 'source')}>Back</AppButton> : null}
            </Flex>
        </VStack>
    )
}

export default MintPosition