import { SimpleGrid } from '@chakra-ui/react'
import { IgetProductsService } from 'apis/product/interface'
import { getProductsService } from 'apis/product/services'
import AppTypography from 'components/common/typography/AppTypography'
import { useCustomParams } from 'hooks/useCustomParams/useCustomParams'
import { useShopStore } from 'lib/stores/shop/shop'
import { useCallback, useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMutation } from 'react-query'
import homepageDefaultContext from '../../context'
import ProductsLoading from './parts/loading/ProductsLoading'
import Product from './parts/product/Product'

function HompageProducts() {
  const { States: { search } } = useContext(homepageDefaultContext)
  const { mutateAsync } = useMutation((params: IgetProductsService) => getProductsService(params))
  const initialState = { data: null, list: [], loading: false }
  const { shopData: { name } } = useShopStore()
  const [State, setState] = useState<any>(initialState)
  const setLoading = (value: boolean) => setState((prev: any) => ({ ...prev, loading: value }))

  const fetch = async (page: string) => {
    try {
      const query = await mutateAsync({ page, shopname: name, search })
      const result = query.data?.data

      setState((prev: any) => {
        const IDS = prev.list.map((product: any) => product._id)
        return {
          ...prev,
          loading: false,
          data: result,
          list: result?.data ? [...prev.list, ...result.data.filter((el: any) => !IDS.includes(el._id))] : []
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setLoading(true)
    setState(initialState)
    fetch('1')
  }, [search])

  return (
    <>
      {State.data ? State?.data?.data?.length && State.list ? (
        <InfiniteScroll
          dataLength={State.list.length}
          next={() => fetch(State.data.nextPage)}
          hasMore={State.data.hasNextPage}
          loader={<ProductsLoading marginTop={8} />}
        >
          <SimpleGrid columns={{ base: 2, sm: 3 }} spacingY={8} spacing={6}>
            {State.list.map((product: any, key: number) => (
              <Product key={key} product={product} />
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      ) : <AppTypography textAlign={"center"} fontSize={{ md: '24px', base: '18px' }}>Oops, looks like the product doesn't exist.</AppTypography>
        : <ProductsLoading />
      }
    </>
  );
}

export default HompageProducts