import { ICheckoutGated } from "hooks/_refactor/checkout/interface"

interface Icheckout {
    gated: ICheckoutGated
    shopNavigate: Function
    openEmailModal: Function
    isDefaultTheme: boolean
}
namespace cartPageButtonGroupsModel {
    export const checkout = ({ shopNavigate, gated, openEmailModal, isDefaultTheme }: Icheckout) => {
        if (isDefaultTheme) return shopNavigate('checkout/payment')
        else {
            if (!gated.email) return openEmailModal()
            else if (!gated.address) return shopNavigate('checkout/address')
            else if (!gated.shipping) return shopNavigate('checkout/shipping')
            else return shopNavigate('checkout/payment')
        }
    }
}

export default cartPageButtonGroupsModel