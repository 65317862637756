import React from 'react'
import { Spinner, SpinnerProps } from '@chakra-ui/spinner'
import { Flex } from '@chakra-ui/layout'
import { useShopStore } from 'lib/stores/shop/shop'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'

interface IProps extends SpinnerProps { }
function AppLoading(props: IProps) {
    const { shopDesign: { textColorParagraphs } } = useShopStore()
    const { isDefaultTheme } = useAppTheme()

    return <Flex justifyContent="center"><Spinner color={isDefaultTheme ? textColorParagraphs : '#333' } {...props} /></Flex>
}

export default AppLoading