import { Flex } from '@chakra-ui/react';
import { IshopDomainService } from 'apis/shop/interface';
import { shopDomainService } from 'apis/shop/service';
import AppLoading from 'components/common/loading/AppLoading';
import { useProfile } from 'hooks/useProfile/useProfile';
import { useShop } from 'hooks/useShop/useShop';
import useAppCart from 'hooks/_refactor/cart/useAppCart';
import { appDeveloment } from 'lib/utils/app/variables';
import { isCustomDomain } from 'lib/utils/theme/theme-utils';
import LoadingPage from 'pages/loading-page/LoadingPage';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

function InitialStore({ children }: any) {
  const [Loading, setLoading] = useState(true)
  const { updateShopData } = useShop();
  const { fetch, clear } = useAppCart();
  const { updateProfile } = useProfile();
  const { mutateAsync } = useMutation((params: IshopDomainService) => shopDomainService(params))
  const param = useParams()
  const { profile } = useProfile()
  const navigate = useNavigate()

  const getCart = async () => {
    try {
      await fetch();
    } catch (error) { }
  }

  const clearCart = async () => {
    try {
      await fetch();
    } catch (error) { }
  }

  const initials = (async () => {
    try {
      setLoading(true)
      let shopname = null
      if (isCustomDomain) shopname = await (await mutateAsync({ domain: window.location.hostname })).data?.data
      const data = await updateShopData(shopname?.name);
      if ((isCustomDomain && !shopname) || !data) throw new Error("Shop could not be found.", { cause: 404 })
      await updateProfile()
      await getCart()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if ((error as Error).cause === 404) navigate("/not-found")
    }
  })

  useEffect(() => {
    initials()
  }, [param?.shopName, isCustomDomain, appDeveloment])

  // // Clear cart when change shop
  // useEffect(() => { clearCart() }, [param?.shopName])

  useEffect(() => { getCart() }, [profile])

  return Loading ? <LoadingPage /> : children
}

export default InitialStore