import { Stack, useDisclosure, VStack } from "@chakra-ui/react";
import { IOrderItem } from "pages/_refactors/history/interface";
import PurchaseHistoryOrderItemModal from "./parts/modal/PurchaseHistoryOrderItemModal";
import PurchaseHistoryTextGroup from "./parts/textsGroup/PurchaseHistoryTextGroup";

const PurchaseHistoryOrderItem = ({ orderItem }: { orderItem: IOrderItem }) => {
    const { orderId, date, quantity, totalPrice } = orderItem;
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        <>
            <VStack w={"full"} maxW="820px" borderRadius="16px" border="2px solid" p={{ base: "14px", md: "64px 48px" }} cursor="pointer" onClick={onOpen}>
                <Stack direction={{ base: "column", md: "row" }} justify={"space-between"} w="full">
                    <PurchaseHistoryTextGroup title={"Date"} desc={date} />
                    <PurchaseHistoryTextGroup title={"Order ID"} desc={orderId} />
                </Stack>
                <Stack direction={{ base: "column", md: "row" }} justify={"space-between"} w="full">
                    <PurchaseHistoryTextGroup title={"Quantity"} desc={quantity} />
                    <PurchaseHistoryTextGroup title={"Total Price"} desc={`$${totalPrice.toFixed(2)} USD`} />
                </Stack>
            </VStack>
            {isOpen && <PurchaseHistoryOrderItemModal isOpen={isOpen} onClose={onClose} orderId={orderId} />}
        </>
    );
};

export default PurchaseHistoryOrderItem;
