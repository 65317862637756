export interface IM2MPositions {
    placement: string;
    url: string;
    variant_ids: number[];
}

namespace mintPreviewModel {
    export const findPosition = (m2mPositions: IM2MPositions[], selectedPosition: string) => m2mPositions.find((position: IM2MPositions) => position?.placement === selectedPosition) || m2mPositions[0];
    export const findExactVariantId = (variantIds: number[], externalId: string) => variantIds?.find((variant) => variant?.toString() === externalId) || variantIds[0];
}

export default mintPreviewModel;
