import { HStack } from '@chakra-ui/react'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'
import AppTypography from 'components/common/typography/AppTypography'
import productPageContext from 'pages/_refactors/product/context'
import React, { useContext } from 'react'

function ProductPrice() {
    const { states: { product, ruleset, sku } } = useContext(productPageContext)
    const discountPercentage = product?.ruleSet && !product?.ruleSet?.gated && ruleset && typeof ruleset.data?.discountPercentage === 'number' && ruleset.data?.discountPercentage

    return sku?.price ? ruleset.loading ? <AppSkeleton height="40px" /> : (
        <HStack alignItems="baseline" gap="10px">
            <AppTypography display="flex" alignItems="center" fontSize={{ base: "24px", sm: "30px", lg: "48px" }}>
                ${discountPercentage ? (sku?.price - (sku?.price / 100) * discountPercentage).toFixed(2) : sku?.price.toFixed(2)} USD
            </AppTypography>
            {discountPercentage && <AppTypography textDecor="line-through" opacity=".7" fontSize={{ base: "14px", sm: "20px", lg: "25px" }}>${sku?.price.toFixed(2)}</AppTypography>}
        </HStack>
    ) : null
}

export default ProductPrice