import AppModal, { IAppModal } from 'components/common/modal/AppModal';
import { ShopThemeEnum } from 'lib/models/shop';
import { useShopStore } from 'lib/stores/shop/shop';
import React, { useEffect, useMemo, useState } from 'react'
import productButtonModel from '../button/model';
import mintToMerchContext, { ImintToMerchStates, mintToMerchStates } from './context';
import MintArtwork from './parts/steps/artwork/MintArtwork';
import MintPosition from './parts/steps/position/MintPosition';
import MintPreview from './parts/steps/preview/MintPreview';
import MintWallets from './parts/steps/wallets/MintWallets';

function MintToMerch({ onClose, isOpen }: IAppModal) {
    const [States, setStates] = useState<ImintToMerchStates>(mintToMerchStates)
    const { shopTheme } = useShopStore()
    const { themeIsLoged } = productButtonModel

    const updateStates = (key: string, value: any) => setStates((prev) => ({ ...prev, [key]: value }))

    const steps = {
        source: {
            component: <MintWallets />,
            title: 'Artwork Source'
        },
        position: {
            component: <MintPosition />,
            title: 'Position'
        },
        artwork: {
            component: <MintArtwork />,
            title: 'Artwork'
        },
        preview: {
            component: <MintPreview />,
            title: 'Preview'
        },
    }

    useEffect(() => {
        if (themeIsLoged(shopTheme)) updateStates('step', 'position')
    }, [shopTheme])

    return (
        <mintToMerchContext.Provider value={{ states: States, methods: { updateStates, closed: onClose } }}>
            <AppModal isOpen={isOpen} onClose={onClose} title={steps[States.step]?.title} props={{ modal: { isCentered: true, size: 'xl' } }}>
                {steps[States.step]?.component}
            </AppModal>
        </mintToMerchContext.Provider>
    )
}

export default MintToMerch