import * as React from "react";
import { Icon } from "@chakra-ui/react";
import { useShopStore } from "lib/stores/shop/shop";
import useAppTheme from "hooks/_refactor/theme/useAppTheme";

const CustomIcon = ({
    w,
    maxW,
    color,
    onClick,
    children,
    borderRadius,
    h,
    bg,
    borderColor,
    p,
    as,
}: {
    w?: any;
    p?: any;
    color?: string;
    maxW?: any;
    onClick?: () => void;
    children?: React.ReactNode;
    borderRadius?: string;
    h?: string;
    bg?: string;
    borderColor?: string;
    as?: any;
}) => {
    const { shopData: { shopDesign: { iconHeaderColor } } } = useShopStore();
    const { isDefaultTheme } = useAppTheme()

    return (
        <Icon
            as={as}
            w={w || "50px"}
            h={h || "50px"}
            maxW={maxW}
            p={p}
            color={color ? color : iconHeaderColor ? iconHeaderColor : !isDefaultTheme ? '#333' : '#FFF'}
            bg={bg}
            borderRadius={borderRadius}
            fontSize="16px"
            fontWeight="400"
            borderColor={borderColor}
            onClick={onClick}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            cursor={"pointer"}
        >
            {children}
        </Icon>
    );
};

export default CustomIcon;
