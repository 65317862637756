import { Stack } from "@chakra-ui/react";
import AppLogo from "components/common/appLogo/AppLogo";
import { useShopStore } from "lib/stores/shop/shop";
import React, { useCallback, useMemo } from "react";
import unstoppable from "assets/icons/footer/footer-logo.png";
import { ShopThemeEnum } from "lib/models/shop";
import FooterItems from "../parts/Items/FooterItems";
import footerDefaultModel from "../model";
import { isCustomDomain } from "lib/utils/theme/theme-utils";

const defaultLinks = [
    { caption: "Any questions? Contact us at support@droplinked.com", link: "mailto:support@printful.com" },
    { caption: "Terms & Conditions", link: "privacy" },
    { caption: "Returns & FAQ", link: "return-policy" },
];
const FooterBrandLinks = () => {
    const { shopTheme, shopData } = useShopStore();
    const footerLinks = useMemo(() => footerDefaultModel.getLinks(shopTheme, shopData).brand.links, [shopTheme, shopData]);
    
    return (
        <Stack direction={{ base: "column", md: "row" }} align={{ base: "start", md: "center" }} justifyContent={"space-between"} w="full">
            <AppLogo {...shopTheme === ShopThemeEnum.THEME_UNSTOPPABLE && { src: unstoppable }} maxW={"128px"} objectFit="contain" {...shopData.template_options?.["--dlk-ftr"]?.["--dlk-ftr-logo"]} />;
            <Stack direction={{ base: "column", md: "row" }}>
                <FooterItems items={footerLinks} hyperlink={!isCustomDomain} />
            </Stack>
        </Stack>
    );
};

export default FooterBrandLinks;
