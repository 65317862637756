import { HStack, VStack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { ICartItemInterface } from "lib/models/cart";
import { ICartStoreItem } from "lib/stores/_refactor/cart/interfaces";
import CartDeleteItemButton from "../../../parts/delete/CartDeleteItemButton";
import CartPageProductImage from "../../../parts/productImage/CartPageProductImage";
import CartPageMobileItemDetails from "./parts/detail/CartPageMobileItemDetails";

const CartPageMobileItem = ({ cartItem }: { cartItem: ICartStoreItem }) => {
    return (
        <VStack w={'full'} fontSize={'12px'}>
            <HStack justify={"space-between"} w='full'>
                <CartPageProductImage cartItem={cartItem} />
                <VStack  fontSize={{ base: '12px', md: '16px' }} align={'left'}>
                <AppTypography>{cartItem?.product.title}</AppTypography>
                {cartItem?.options?.color?.value && <AppTypography>Size: {cartItem?.options?.size?.caption}</AppTypography>}
                {cartItem?.options?.size?.value && <AppTypography>Color: {cartItem?.options?.color?.caption}</AppTypography>}
                </VStack>
                <CartDeleteItemButton itemId={cartItem?._id} />
            </HStack>
            <CartPageMobileItemDetails cartItem={cartItem} />
        </VStack>
    );
};

export default CartPageMobileItem;
