import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Formik } from 'formik'
import { Box, Flex, VStack } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { addressByIdService, allCountriesService, citiesService } from 'apis/address/addressServices';
import { IaddressByIdService, IallCountriesService, IcitiesService, IcreateAddressServiceParams, IsatatesService } from 'apis/address/interfaces';
import checkoutFormAddressModel from './model';
import AppFormMaker from 'components/common/form/maker/AppFormMaker';
import AppButton from 'components/common/form/button/AppButton';
import { useToastify } from "context/toastify/ToastifyContext";
import useAppAddress from 'hooks/_refactor/address/useAppAddress';
import useAppStore from 'lib/stores/_refactor/cart/cartStore';

interface IProps {
  addressID?: string
  onSuccess?: Function
  onCancel?: Function
}
function CheckoutFormAddress({ addressID, onSuccess, onCancel }: IProps) {
  const fetchService = useMutation((params: IaddressByIdService) => addressByIdService(params))
  const countryService = useMutation((params: IallCountriesService) => allCountriesService(params))
  const cities = useMutation((params: IcitiesService) => citiesService(params))
  const [Loading, setLoading] = useState(false)
  const address = fetchService.data ? fetchService.data.data?.data : null
  const { initialValues, formSchema } = checkoutFormAddressModel
  const loading = !Boolean(addressID && fetchService.isLoading)
  const { create, update } = useAppAddress()
  const { errorToast, successToast } = useToastify()
  const { states: { cart } } = useAppStore()

  const citiesData = useMemo(() => cities.data?.data?.data && cities.data?.data?.data?.cities.length && cities.data?.data?.data?.cities, [cities])

  const onSubmit = async (body: IcreateAddressServiceParams) => {
    try {
      setLoading(true)
      const params = { ...body, city: getCity(body.city)?.name }
      if (addressID) await update({ addressID: addressID, params, anonymous: false })
      else await create(params)
      if (onSuccess) onSuccess()
      successToast(addressID ? 'Store address has been updated!' : 'Store address has been added successfully!')
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const err = error?.response?.data
      errorToast(err?.data?.message ? err?.data?.message : err && err?.statusCode && err?.statusCode === 422 ? "Cant verify address" : "Oops! Something went wrong", "error");
    }
  }

  // Fetch address by ID
  useEffect(() => {
    getCountry()
  }, [addressID])

  const getCountry = useCallback(async () => {
    if (!addressID) return false
    try {
      const { data } = await fetchService.mutateAsync({ addressID })
      const countryName = data?.data?.country
      const country = await countryService.mutateAsync({ name: countryName })
      const country_id = country?.data?.data?.countries.find((el: any) => el.name === countryName)?.id
      cities.mutate({ country_id, name: data?.data.city })
    } catch (error) {
    }
  }, [addressID])

  const getCity = (cityID: string) => citiesData ? citiesData?.find((el: any) => (el._id === cityID) || el.name === cityID) : []

  return (
    <Formik
      initialValues={initialValues({ address })}
      enableReinitialize
      validateOnChange={false}
      validationSchema={formSchema()}
      onSubmit={onSubmit}
    >
      {({ errors, values, setFieldValue }) => (
        <Form>
          <VStack align={"stretch"} spacing="20px">
            <Box>
              <AppFormMaker
                loading={loading}
                element={{
                  type: 'input',
                  props: { value: values.addressLine1, onChange: (e: any) => setFieldValue('addressLine1', e.target.value) }
                }}
                error={errors.addressLine1}
                label='Address line 1'
              />
            </Box>
            <Box>
              <AppFormMaker
                loading={loading}
                element={{
                  type: 'input',
                  props: { value: values.addressLine2, onChange: (e: any) => setFieldValue('addressLine2', e.target.value) }
                }}
                error={errors.addressLine2}
                label='Address line 2'
              />
            </Box>
            <Flex gap={7}>
              <Box width={"50%"}>
                <AppFormMaker
                  loading={loading}
                  element={{
                    type: 'dropdown',
                    props: {
                      value: values.country ? { label: values.country, value: values.country } : null,
                      onInputChange: (e: string) => {
                        if (e.length > 2) countryService.mutate({ name: e || '' })
                      },
                      isLoading: countryService.isLoading,
                      onChange: (e: any) => {
                        setFieldValue("country", e.value)
                        setFieldValue("state", null)
                        setFieldValue("city", null)
                      },
                      options: countryService.data?.data?.data?.countries ? countryService.data?.data?.data?.countries.map((el: any) => ({ value: el.name, label: el.name })) : []
                    }
                  }}
                  error={errors.country}
                  label='Country'
                />
              </Box>
              <Box width={"50%"}>
                <AppFormMaker
                  loading={loading}
                  element={{
                    type: 'dropdown',
                    props: {
                      value: values.city ? { label: getCity(values.city)?.name, value: values.city } : null,
                      isLoading: cities.isLoading,
                      isDisabled: Boolean(!values.country.length),
                      onInputChange: (e: string) => {
                        const country_id = countryService.data?.data?.data?.countries.find((el: any) => el.name === values.country).id
                        if (values.country && e && country_id) cities.mutate({ country_id, name: e || '' })
                      },
                      onChange: (e: any) => {
                        setFieldValue("city", e.value)
                        if (citiesData) setFieldValue("state", getCity(e.value)?.state_name)
                      },
                      options: citiesData && citiesData.length ? citiesData.map((el: any) => ({ value: el._id, label: el.name + ` (${el.state_name})` })) : []
                    }
                  }}
                  error={errors.city}
                  label='City'
                />
              </Box>
            </Flex>
            <Flex gap={7}>
              <Box width={"50%"}>
                <AppFormMaker
                  loading={loading}
                  element={{
                    type: 'dropdown',
                    props: {
                      value: values.state ? { label: values.state, value: values.state } : null,
                      isDisabled: true,
                      onChange: () => { },
                      options: []
                    }
                  }}
                  error={errors.state}
                  label='State'
                />
              </Box>
              <Box width={"50%"}>
                <AppFormMaker
                  loading={loading}
                  element={{
                    type: 'input',
                    props: { value: values.zip, onChange: (e: any) => setFieldValue('zip', e.target.value) }
                  }}
                  error={errors.zip}
                  label='Zip Code'
                />
              </Box>
            </Flex>
            <Flex gap={7}>
              <Box width={"50%"}>
                <AppFormMaker
                  loading={loading}
                  element={{
                    type: 'input',
                    props: { value: values.firstName, onChange: (e: any) => setFieldValue('firstName', e.target.value) }
                  }}
                  error={errors.zip}
                  label='First Name'
                />
              </Box>
              <Box width={"50%"}>
                <AppFormMaker
                  loading={loading}
                  element={{
                    type: 'input',
                    props: { value: values.lastName, onChange: (e: any) => setFieldValue('lastName', e.target.value) }
                  }}
                  error={errors.lastName}
                  label='Last Name'
                />
              </Box>
            </Flex>
            <Flex gap={7}>
              <Box width={"50%"}>
                <AppButton width="100%" onClick={() => onCancel ? onCancel() : {}} variant='outline'>Cancel</AppButton>
              </Box>
              <Box width={"50%"} textAlign="right">
                <AppButton width="100%" type='submit' isLoading={Loading}>Submit</AppButton>
              </Box>
            </Flex>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}

export default CheckoutFormAddress