import { Flex } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { useContext } from 'react'
import orderModalContext from '../../context'
import OrderItem from './parts/orderItem/OrderItem'

function PurchaseHistoryOrderItems() {
    const order = useContext(orderModalContext)

    return (
        <Flex direction={"column"} gap={"24px"}>
            <AppTypography width='full' textAlign={'left'} fontSize={{ base: "16px", md: "18px" }} fontWeight={'bold'}>Items</AppTypography>
            <Flex direction={"column"} gap={"18px"}>
                {order?.items?.map((item) => (
                    <OrderItem key={item?.productId} item={item} />
                ))}
            </Flex>
        </Flex>
    )
}

export default PurchaseHistoryOrderItems