import { createContext } from "react";

interface IProps {
    updateStep: Function
}

const paymentDefaultStepsContext = createContext<IProps>({
    updateStep: () => { }
})

export default paymentDefaultStepsContext