import React, { useContext } from 'react'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import mintToMerchContext from '../../../../../context'

function MintPositionRemove() {
    const { states: { removeBackground }, methods: { updateStates } } = useContext(mintToMerchContext)
    
    return (
        <>
            <Checkbox isChecked={removeBackground} onChange={(e) => updateStates('removeBackground', e.target.checked)}><AppTypography fontSize="14px">Remove Background</AppTypography></Checkbox>
        </>
    )
}

export default MintPositionRemove