import { useProfile } from 'hooks/useProfile/useProfile'
import { useUserStore } from 'lib/stores/user/userStore'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React, { useMemo } from 'react'
import { ICheckoutGated } from './interface'

function useAppCheckout() {
    const { states: { cart } } = useAppStore()
    const { profile } = useProfile()
    const { user } = useUserStore()

    const IsCartDigital = useMemo(() => cart && cart?.items ? cart.items.filter(el => el.product.type === "DIGITAL").length === cart.items.length : false, [profile, user, cart])

    const gated = useMemo((): ICheckoutGated => ({
        email: profile ? Boolean(user?.email) : Boolean(cart?.email),
        address: IsCartDigital ? true : Boolean(cart?.address),
        shipping: IsCartDigital ? true : Boolean(cart?.shippings?.length && cart?.shippings.find(el => el.data.find(item => item.selected))),
    }), [profile, user, cart, IsCartDigital])


    return { gated, IsCartDigital }
}

export default useAppCheckout