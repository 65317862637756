// external
import { useCallback, useState } from "react";
import { useOpenSignMessage, useAuth } from "@micro-stacks/react";
import UAuth from "@uauth/js";

// internal
import { useToastify } from "context/toastify/ToastifyContext";
import { useApi } from "../useApi/useApi";
import { useCustomParams } from "hooks/useCustomParams/useCustomParams";
import { getUser, putUser } from "apis/userApi";
import { postLogin } from "apis/authApi";
import { appDeveloment } from "lib/utils/app/variables";
import {
  casper_wallet_login,
  isCapseWalletExtentionInstalled,
} from "utils/casper/login-casper-wallet-utils";
import { useUserStore } from "lib/stores/user/userStore";
import { validateEmail } from "lib/utils/validation/email";
import { convertToUserModel, getChain } from "./utils";
import { IUserData, WALLET_TYPES } from "lib/models/user";
import { unisatLogin } from "lib/utils/unisat/login";
import { UNSTOPPABLE_CLIENT_ID } from "lib/utils/app/variables";
import { getNetworkProvider } from "lib/chains/chainProvider";
import { Chain, Network } from "lib/chains/Chains";
import { CasperProvider } from "lib/chains/providers/casper/casperProvider";
import { loginWithXumm } from "lib/blockchain/xumm/xummLogin";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";

const uauth = new UAuth({
  clientID: UNSTOPPABLE_CLIENT_ID as string,
  redirectUri: window.location.origin,
});

/**
 * Custom hook for managing profile-related functionality and data.
 *
 * @returns {Object} - An object containing various profile-related functions and data.
 * @property {boolean} loading - The loading status.
 * @property {IUser | null} profile - The user profile object contain user data in current shop .
 * @property {string} walletAddress - The wallet address associated with the user profile.
 * @property {Function} updateProfile - Updates the user profile data.
 * @property {Function} addEmailToAccount - Adds an email to the user account.
 * @property {Function} loginCasperWallet - Logs in using Casper wallet.
 * @property {Function} loginViaUnstoppable - Logs in via Unstoppable wallet.
 * @property {Function} logout - Logs out the user.
 */
//
export function useProfile() {
  // state for loading status
  const [loading, setLoading] = useState(false);
  // stores
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);
  const profile = useUserStore((state) => state.user);
  // hookgs
  const { errorToast } = useToastify();
  const { getApi, postApi, putApi } = useApi();
  const { openAuthRequest, signOut, isSignedIn } = useAuth();
  const { openSignMessage } = useOpenSignMessage();
  const { shopName } = useCustomParams();
  const { route } = useCustomNavigate();

  // update jwt after shop or userData changed
  const updateJwtToken = useCallback(() => {
    const token = userData?.hasOwnProperty(shopName)
      ? userData[shopName].token
      : null;
    if (token) localStorage.setItem("token", JSON.stringify(token));
    else localStorage.removeItem("token");
  }, [shopName]);

  // loginFunction for call after integrate with each wallets
  const loginFunction = useCallback(
    async (resultData: any, publicKey?: string, walletType?: WALLET_TYPES) => {
      let data: IUserData = { ...userData };
      data[shopName] = {
        token: resultData.access_token,
        user: convertToUserModel({
          ...resultData.user,
          publicKey: publicKey,
          walletType: walletType,
        }),
      };
      setUserData(data, shopName);
      localStorage.setItem("token", JSON.stringify(resultData.access_token));
    },
    [userData]
  );

  const clearUser = useCallback(() => {
    if (!userData) return;
    if (userData.hasOwnProperty(shopName)) {
      const result = Object.fromEntries(
        Object.entries(userData).filter(([key]) => !key.includes(shopName))
      );
      localStorage.removeItem("token");
      setUserData(result, shopName);
    } else setUserData(userData, shopName);
  }, [userData]);

  // update userData
  const updateProfile = useCallback(async () => {
    updateJwtToken();
    const token = localStorage.getItem("token");
    if (token) {
      const result = await getApi(getUser());
      let currentData: IUserData = { ...userData };
      currentData[shopName] = {
        token: currentData[shopName].token,
        user: convertToUserModel({
          ...result,
          publicKey: currentData[shopName].user?.publicKey,
          walletType: currentData[shopName].user?.walletType,
        }),
      };
      setUserData(currentData, shopName);
    } else {
      clearUser();
    }
  }, [userData]);

  // ----------------------------------------------------------------------
  // *** stacks wallet ***
  const connectStacksWallet = async (stxAddress: any, walletResponse: any) => {
    let result = await postApi(
      postLogin(
        stxAddress,
        walletResponse.publicKey,
        walletResponse.signature,
        WALLET_TYPES.STACK,
        shopName
      )
    );
    if (result)
      loginFunction(result, walletResponse.publicKey, WALLET_TYPES.STACK);
    setLoading(false);
  };

  const signMessage = async (address: string) => {
    let message = address || "";
    await openSignMessage({
      message,
      onFinish: (walletResponse) => {
        connectStacksWallet(address, walletResponse);
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const loginViaStacks = async () => {
    setLoading(true);
    if (isSignedIn) await signOut();
    await openAuthRequest({
      onFinish(session) {
        if (appDeveloment) {
          signMessage(session?.addresses?.testnet);
        } else {
          signMessage(session?.addresses?.mainnet);
        }
      },
      onCancel() {
        setLoading(false);
      },
    });
  };
  // *** stacks wallet ***
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  // function for pass to login casper wallet function
  const casperWalletCallbackFunction = async (account_info: any) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (!account_info.signature) throw Error('Somthing Wrong')
        const result = await postApi(
          postLogin(
            account_info.account_hash,
            account_info.publicKey,
            account_info.signature,
            WALLET_TYPES.CASPER,
            shopName
          )
        );
        if(!result) throw Error('Somthing Wrong')

        loginFunction(result, account_info.publicKey, WALLET_TYPES.CASPER);
        resolve({ result, publicKey: account_info.publicKey });
        setLoading(false);
      } catch (error) {
        reject(error)
      }
    })
  };
  // call this function for login via casper wallet
  const loginCasperWallet = async () => {
    // check wallet is installed
    if (isCapseWalletExtentionInstalled()) {
      setLoading(true);
      let chainProvider = new CasperProvider(
        0,
        appDeveloment ? Network.TESTNET : Network.MAINNET,
      );
      let result = await chainProvider.walletLogin();
      await casperWalletCallbackFunction(result);
      // casper_wallet_login(casperWalletCallbackFunction);
    } else {
      // if isn't install
      errorToast("Please install casper wallet");
      window.open("https://www.casperwallet.io/", "_blank");
    }
  };

  // ----------------------------------------------------------------------

  // ----------------------------------------------------------------------
  // *** unstoppable ***
  const unstoppableCallbackFuncion = async (account_info: any) => {
    let result = false;
    result = await postApi(
      postLogin(
        account_info?.idToken?.sub,
        account_info?.idToken?.wallet_address,
        account_info?.idToken?.__raw,
        WALLET_TYPES.UNSTOPPABLEDOMAIN,
        shopName,
        account_info?.idToken?.nonce
      )
    );
    if (result)
      loginFunction(
        result,
        account_info?.idToken?.wallet_address,
        WALLET_TYPES.UNSTOPPABLEDOMAIN
      );
    return result;
  };

  const loginViaUnstoppable = async () => {
    setLoading(true);
    let res = false;
    try {
      const authorization = await uauth.loginWithPopup();
      res = await unstoppableCallbackFuncion(authorization);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    return res;
  };
  // *** unstoppable ***
  // ----------------------------------------------------------------------

  const reload = () => window.location.reload()

  const logout = async () => {

    if (profile?.walletType === WALLET_TYPES.UNSTOPPABLEDOMAIN) {
      try {
        await uauth.logout();
      } catch (e) {
      }
    }
    clearUser();
    if (isSignedIn) await signOut()
    reload()
  };

  const addEmailToAccount = useCallback(
    async (email: string) => {
      //  let regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
      let result = false;
      if (validateEmail(email)) {
        result = await putApi(putUser({ email: email }));
        if (result) {
          updateProfile();
          result = true;
        }
      } else {
        errorToast("Invalid email");
      }
      return result;
    },
    [userData]
  );

  const loginUniSat = async () => {
    try {
      const login = await unisatLogin();
      const result = await postApi(
        postLogin(
          login.address,
          login.publicKey,
          login.signature,
          WALLET_TYPES.UNISAT,
          shopName
        )
      );
      if (result) loginFunction(result, login.publicKey, WALLET_TYPES.CASPER);
    } catch (error) {
      errorToast(error);
    }
  };

  const EVMLogin = async (walletType: any) => {
    setLoading(true);
    try {
      let chainProvider = getNetworkProvider(
        getChain(walletType),
        appDeveloment ? Network.TESTNET : Network.MAINNET,
        ''
      );
      let walletRes = await chainProvider?.walletLogin();
      if (walletRes) {
        let result = await postApi(
          postLogin(
            walletRes.address,
            walletRes.address,
            walletRes.signature,
            walletType,
            shopName
          )
        );

        if (result) loginFunction(result, walletRes.address, walletType);
      } else {
        console.error(walletRes);
      }
    } catch (error) {
      errorToast(error);
    }
    setLoading(false);
  };


  const loginViaXumm = async () => {
    setLoading(true);
    try {
      let xummRes = await loginWithXumm()

      if (xummRes) {
        let result = await postApi(
          postLogin(
            xummRes.address,
            xummRes.address,
            xummRes.payload,
            WALLET_TYPES.XUMM,
            shopName
          )
        );

        if (result) loginFunction(result, xummRes.address, WALLET_TYPES.XUMM);
      }
    } catch (error) {
      errorToast(error);
    }
    setLoading(false);
  };
  // const addData = (data:Object) => setUserData(data)

  return {
    loginViaUnstoppable,
    addEmailToAccount,
    loginCasperWallet,
    updateJwtToken,
    loginViaStacks,
    updateProfile,
    loginViaXumm,
    loginUniSat,
    EVMLogin,
    profile,
    loading,
    logout,
    reload
  };
}