import { Flex, VStack } from '@chakra-ui/react'
import { IaddEmailToCartService } from 'apis/cart/interface'
import { addEmailToCartService } from 'apis/cart/service'
import { updateUserService } from 'apis/user/addressServices'
import AppButton from 'components/common/form/button/AppButton'
import AppInput from 'components/common/form/maker/parts/input/AppInput'
import { useToastify } from 'context/toastify/ToastifyContext'
import { useProfile } from 'hooks/useProfile/useProfile'
import useAppCart from 'hooks/_refactor/cart/useAppCart'
import { useUserStore } from 'lib/stores/user/userStore'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'

interface IProps {
    onSuccess: Function
}
function CheckoutEmail({ onSuccess }: IProps) {
    const [States, setStates] = useState({
        email: '',
        loading: false
    })
    const { mutateAsync } = useMutation((params: any) => updateUserService(params))
    const { states: { cart } } = useAppStore()
    const { user } = useUserStore()
    const { profile, updateProfile } = useProfile()
    const { errorToast } = useToastify()
    const { addToCart } = useAppCart()

    useEffect(() => {
        const email = cart?.email || user?.email
        if (email) setStates(prev => ({ ...prev, email }))
    }, [cart?.email, user?.email])

    const setLoading = (loading: boolean) => setStates(prev => ({ ...prev, loading }))

    const click = useCallback(async () => {
        try {
            setLoading(true)
            const Email = States.email
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) throw Error('Please check your email address')
            const email = cart?.email || user?.email
            if (Email !== email) {
                if (profile) {
                    await mutateAsync({ email: Email })
                    await updateProfile()
                } else {
                    await addToCart({ field: 'email', value: Email })
                }
            }
            setLoading(false)
            onSuccess()
        } catch (error: any) {
            setLoading(false)
            errorToast(error.message)
        }
    }, [States.email, cart, user?.email, profile])

    return (
        <VStack align="stretch">
            <AppInput onChange={e => setStates(prev => ({ ...prev, email: e.target.value }))} placeholder="Enter email ..." value={States.email} />
            <Flex justifyContent="right"><AppButton onClick={click} isLoading={States.loading}>Submit</AppButton></Flex>
        </VStack>
    )
}

export default CheckoutEmail