import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import { useState, useEffect } from "react";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Text } from "@chakra-ui/react";

import { useCustomParams } from "hooks/useCustomParams/useCustomParams";

import Theme2Button from "./themes/theme2";
import CasperButton from "./themes/casper";
import UnstoppableButton from "./themes/unstoppable";
import { useShopStore } from "lib/stores/shop/shop";
import { getComponentByTheme } from "lib/utils/app/component-util";
import { ShopThemeEnum } from "lib/models/shop";
import { isCustomDomain } from "lib/utils/theme/theme-utils";
import useAppCart from "hooks/_refactor/cart/useAppCart";

const CheckoutForm = () => {
  const [isStripeLoading, setIsStripLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const shopTheme = useShopStore((state) => state.shopTheme);
  const stripe = useStripe();
  const {clear} = useAppCart();
  const elements = useElements();
  const { shopNavigate } = useCustomNavigate();
  const { shopName } = useCustomParams();

  const orderId = JSON.parse(localStorage.getItem("orderId") || "");
  const returnUrl = isCustomDomain
    ? `${window.location.origin}/paying/${orderId}`
    : `${window.location.origin}/${shopName}/paying/${orderId}`;

  const clickOnDiscart = async () => {
    await clear();
    shopNavigate("");
  };

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element?.on("ready", () => {
        setIsStripLoading(false);
      });
    }
  }, [elements]);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    setMessage(null)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (result.error) {
      setMessage(result?.error?.message as string);
      setLoading(false);
      // Show error to your customer (for example, payment details incomplete)
    } else {
      setLoading(false);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const componentsList = [
    {
      theme: ShopThemeEnum.THEME_2,
      component: (
        <Theme2Button
          loading={!stripe || loading}
          submit={handleSubmit}
          cancel={clickOnDiscart}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_1,
      component: (
        <Theme2Button
          loading={!stripe || loading}
          submit={handleSubmit}
          cancel={clickOnDiscart}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_CASPER,
      component: (
        <CasperButton
          loading={!stripe || loading}
          submit={handleSubmit}
          cancel={clickOnDiscart}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_SKALE,
      component: (
        <CasperButton
          loading={!stripe || loading}
          submit={handleSubmit}
          cancel={clickOnDiscart}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_UNSTOPPABLE,
      component: (
        <UnstoppableButton
          loading={!stripe || loading}
          submit={handleSubmit}
          cancel={clickOnDiscart}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_POLYGON,
      component: (
        <UnstoppableButton
          loading={!stripe || loading}
          submit={handleSubmit}
          cancel={clickOnDiscart}
        />
      ),
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {message && (
        <Text fontFamily="Manrope" fontSize="12px" fontWeight="500" color="red" py='8px'>
          {message}
        </Text>
      )}
      {!isStripeLoading && (
        <>{getComponentByTheme(shopTheme, componentsList)}</>
      )}
    </form>
  );
};

export default CheckoutForm;
