import appThemes from 'lib/models/themes/appThemes'
import { useShopStore } from 'lib/stores/shop/shop';
import React from 'react'

function PaymentPage() {
  const { shopTheme } = useShopStore();

  return appThemes.page.checkout.payment[shopTheme] || appThemes.page.checkout.payment['theme-1']
}

export default PaymentPage