import axiosInstance from "../axiosConfig"
import { IaddressAttachCartService, IaddressByIdService, IallCountriesService, IcitiesService, IcreateAddressService, IdeleteAddressService, IsatatesService, IupdateAddressService } from "./interfaces"

export const addressBookService = () => {
    return axiosInstance.get(`address-book`)
}

export const createAddressService = ({ anonymous, params }: IcreateAddressService) => {
    return axiosInstance.post(anonymous ? 'address-book/public/anonymous-customer' : 'address-book', params)
}

export const updateAddressService = ({ addressID, anonymous, params }: IupdateAddressService) => {
    return axiosInstance.put(`address-book/${addressID}`, params)
}

export const addressByIdService = ({ addressID }: IaddressByIdService) => {
    return axiosInstance.get(`address-book/${addressID}`)
}

export const deleteAddressService = ({ addressID }: IdeleteAddressService) => {
    return axiosInstance.delete(`address-book/${addressID}`)
}

export const allCountriesService = ({ name }: IallCountriesService) => {
    return axiosInstance.get(`locations/countries${name ? '?name=' + name : ''}`)
}

export const statesService = ({ country_name }: IsatatesService) => {
    return axiosInstance.get(`locations/states${country_name ? `?country_name=${country_name}` : ""}`)
}

export const citiesService = ({ name, country_id }: IcitiesService) => {
    return axiosInstance.get(`locations/cities?country_id=${country_id}&name=${name}`)
}

export const addressAttachCartService = ({ params, cartID, anonymous }: IaddressAttachCartService) => {
    return axiosInstance.post(anonymous ? `checkout/v2/public/anonymous-cart/${cartID}/address` : 'checkout/v2/add-address', params)
}