import { FlexProps, IconProps, InputProps } from "@chakra-ui/react";
import { IShopDesign, ShopThemeEnum } from "lib/models/shop";

interface IThemes {
    [propname: string]: {
        box: FlexProps
        icons: IconProps
        input: InputProps
    }
}
namespace appQuantityModel {
    export const themes = (shopDesign: IShopDesign): IThemes => {
        const defaults: IThemes = {
            dark: {
                box: { backgroundColor: shopDesign.foreground || "#000", color: shopDesign.textColorParagraphs || "#FFF", borderRadius: "100px" },
                icons: { fill: shopDesign.textColorParagraphs || "#FFF" },
                input: { color: shopDesign.textColorParagraphs || "#FFF" }
            },
            light: {
                box: { backgroundColor: "#FFF", color: "#333", border: "1px solid #333", borderRadius: "100px" },
                icons: { fill: "#333" },
                input: { color: "#333" }
            },
        }

        return {
            [ShopThemeEnum.THEME_1]: defaults.dark,
            [ShopThemeEnum.THEME_2]: defaults.dark,
            [ShopThemeEnum.THEME_SKALE]: defaults.light,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: defaults.light,
            [ShopThemeEnum.THEME_CASPER]: defaults.light,
            [ShopThemeEnum.THEME_POLYGON]: defaults.light,
        }
    }
}

export default appQuantityModel