import { Box, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import AppTypography from 'components/common/typography/AppTypography';
import { useShopStore } from 'lib/stores/shop/shop'
import checkoutPageContext from 'pages/_refactors/checkout/context';
import React, { useContext } from 'react'

function CheckoutPayment() {
    const { paymentMethods } = useShopStore()
    const { mehtods: { updateStates }, states } = useContext(checkoutPageContext)
    
    return (
        <VStack align="stretch">
            <RadioGroup onChange={e => updateStates('payment', e)} value={states.payment || ''}>
                <VStack align="stretch" spacing="14px">
                    {paymentMethods.map((el, key: number) => (
                        <Box padding="18px 14px 14px 14px" border="1px solid #777" borderRadius="4px">
                            <Radio key={key} value={el.type}>
                                <AppTypography fontSize="14px">{el.type === "STRIPE" ? 'Fiat payment (debit or credit card)' : el.type}</AppTypography>
                            </Radio>
                        </Box>
                    ))}
                </VStack>
            </RadioGroup>
        </VStack>
    )
}

export default CheckoutPayment