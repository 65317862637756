import AppTypography from 'components/common/typography/AppTypography'
import React, { useContext } from 'react'
import productPageContext from '../../context'

function ProductDescription() {
    const { states: { product } } = useContext(productPageContext)

    return (
        <AppTypography  dangerouslySetInnerHTML={{ __html: product?.description }}></AppTypography>
    )
}

export default ProductDescription