import { useEffect } from "react";
import { appDeveloment } from "lib/utils/app/variables";

const SuggestShops = () => {

  useEffect(() => {
    window.location.replace('https://dev.droplinked.com/');
    appDeveloment
      ? window.location.replace('https://dev.droplinked.com/')
      : window.location.replace('https://droplinked.io/shop');
  }, []);

  return (
    <>
      <h1>Redirect to droplinked.com</h1>
    </>
  );
};

export default SuggestShops;
