const casperTopbarModel = {
    links: [
        {
            title: "Redeem Mystery Box",
            href: "https://casperpunks.io/portfolio",
        },
        {
            title: "Marketplace",
            href: "https://casperpunks.io/gen0boxes",
        },
        {
            title: "About",
            href: "https://casperpunks.io/#about",
        },
        {
            title: "Roadmap",
            href: "https://casperpunks.io/#roadmap",
        },
        {
            title: "FAQ",
            href: "https://casperpunks.io/faq",
        },
        {
            title: "Docs",
            href: "https://docs.casperpunks.io/",
        },
        {
            title: "Merch",
            href: "https://shop.casperpunks.io/",
        },
        {
            title: "Create Wallet",
            href: "https://casperpunks.io/connectwallet",
        },
    ]
}

export default casperTopbarModel