import { HStack, useDisclosure } from "@chakra-ui/react";
import AppButton from "components/common/form/button/AppButton";
import AppModal from "components/common/modal/AppModal";
import AppTypography from "components/common/typography/AppTypography";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import useAppCart from "hooks/_refactor/cart/useAppCart";
import useAppCheckout from "hooks/_refactor/checkout/useAppCheckout";
import useAppTheme from "hooks/_refactor/theme/useAppTheme";
import CheckoutEmail from "pages/_refactors/checkout/parts/steps/email/CheckoutEmail";
import { Link } from "react-router-dom";
import cartPageButtonGroupsModel from "./model";

const CartPageButtonGroups = () => {
    const { gated } = useAppCheckout()
    const { shopNavigate, route } = useCustomNavigate();
    const { clear } = useAppCart()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { checkout } = cartPageButtonGroupsModel
    const { isDefaultTheme } = useAppTheme()

    const discard = async () => {
        try {
            await clear()
        } catch (error: any) { }
    }

    const successEmail = async () => {
        onClose()
        shopNavigate('checkout/address')
    }

    return (
        <>
            <HStack spacing='20px' justify={'end'} w="full">
                <AppTypography fontSize={{ base: 'xs', md: 'md' }} cursor="pointer" textDecoration="underline" onClick={discard}>Discard</AppTypography>
                <Link to={route('')}>
                    <AppButton variant="outline">Continue Shopping</AppButton>
                </Link>
                <AppButton fontSize={{ base: 'xs', md: 'md' }} onClick={() => checkout({
                    openEmailModal: onOpen,
                    shopNavigate,
                    gated,
                    isDefaultTheme
                })}>Checkout</AppButton>
            </HStack>
            <AppModal title='Enter email' isOpen={isOpen} onClose={onClose}><CheckoutEmail onSuccess={successEmail} /></AppModal>
        </>
    );
};

export default CartPageButtonGroups;
