import AppSkeleton from 'components/common/skeleton/AppSkeleton'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'
import { useShopStore } from 'lib/stores/shop/shop'
import React, { useMemo } from 'react'
import Select from 'react-select'
import appFormMakerModel from '../../model'

interface Ioptions {
    value: string | number
    label: string | number
}

export interface IAppDropDown {
    options: Array<Ioptions>
    onChange: any
    isLoading?: boolean
    onInputChange?: Function
    isDisabled?: boolean
    value: Ioptions | null
}

function AppDropDown({ options, onChange, value, isDisabled = false, onInputChange, isLoading }: IAppDropDown) {
    const { shopTheme, shopDesign } = useShopStore();
    const theme = useMemo(() => appFormMakerModel.themes(shopDesign)[shopTheme], [shopTheme, shopDesign])
    const { isDefaultTheme } = useAppTheme()

    return (
        <AppSkeleton isLoaded={true}>
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                onInputChange={e => onInputChange ? onInputChange(e) : {}}
                styles={{
                    control: (baseStyles: any) => ({
                        ...baseStyles,
                        ...theme,
                        padding: '4px'
                    }),
                    menu: (baseStyles: any) => ({
                        ...baseStyles,
                        backgroundColor: !isDefaultTheme ? theme.backgroundColor : shopDesign.foreground,
                    }),
                    singleValue: (baseStyles: any) => ({
                        ...baseStyles,
                        color: theme.color,
                    }),
                    option: (baseStyles: any) => ({
                        ...baseStyles,
                        background: 'none',
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: theme.backgroundColor
                        }
                    }),
                    indicatorsContainer: (baseStyles: any) => ({
                        ...baseStyles,
                        opacity: '.3',
                        'svg': { fill: theme.color }
                    }),
                    indicatorSeparator: (baseStyles: any) => ({
                        ...baseStyles,
                        display: 'none'
                    }),
                    input: (baseStyles: any) => ({
                        ...baseStyles,
                        color: theme.color
                    }),
                }}
                options={options}
                value={value}
                onChange={onChange}
            />
        </AppSkeleton>
    )
}

export default AppDropDown