import { createContext } from "react";

export interface IcheckoutPageStates {
  payment: string | null
  clientSecret: string | null
  loading: boolean
}

export const checkoutPageStates: IcheckoutPageStates = {
  payment: null,
  clientSecret: null,
  loading: false
}

interface IProps {
  states: IcheckoutPageStates
  mehtods: {
    updateStates: Function,
    payment: Function
  }
}

const checkoutPageContext = createContext<IProps>({
  states: checkoutPageStates,
  mehtods: {
    updateStates: () => { },
    payment: () => { }
  }
})

export default checkoutPageContext