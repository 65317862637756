import axiosInstance from "apis/axiosConfig"
import { IcheckRulesetService, InftImagesService } from "./interface"

export const nftImagesService = (props: InftImagesService) => {
    return axiosInstance.post(`rule-set/nft-images`, props)
}

export const checkRulesetService = (props: IcheckRulesetService) => {
    return axiosInstance.post(`rule-set/check-rules`, props)
}
