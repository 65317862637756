import axios from "axios";
import { useCustomParams } from "hooks/useCustomParams/useCustomParams";

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

// let storageUser = localStorage.getItem("userStore") ? localStorage.getItem("userStore") : "";
// let storageShop = localStorage.getItem("new-shop") ? localStorage.getItem("new-shop") : "";
// let userData = storageUser ? JSON.parse(storageUser) : {};
// let shopData = storageShop ? JSON.parse(storageShop) : {};
// const jwtToken = userData?.hasOwnProperty(shopData?.state?.shopData?.name) ? userData?.state?.userData[shopData?.state?.shopData?.name]?.token : null;

axiosInstance.interceptors.request.use(
    function (config) {
        const jwtToken = localStorage.getItem("token");
        const token = jwtToken ? JSON.parse(jwtToken) : null;
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (res) {
        return res;
    },
    function (error) {
        const statusCode = error?.response?.status;
        if (statusCode && statusCode === 401) {
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
