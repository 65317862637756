import { Box, VStack } from '@chakra-ui/react'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'
import React, { useMemo } from 'react'
import AppDropDown, { IAppDropDown } from './parts/dropdown/AppDropDown'
import AppErrorBadge from './parts/error/AppErrorBadge'
import AppInput, { IAppInput } from './parts/input/AppInput'
import AppInputLabel from './parts/label/AppInputLabel'

interface IProps {
    error?: any
    loading?: boolean
    label?: string
    element: {
        type: 'input'
        props: IAppInput
    } |
    {
        type: 'dropdown'
        props: IAppDropDown
    }
}
function AppFormMaker({ error, label, loading = true, element }: IProps) {

    const handleElement = useMemo(() => {
        const type = element.type
        if (type === "input") return <AppInput {...element.props} />
        else if (type === "dropdown") return <AppDropDown {...element.props} />
    }, [element])

    return (
        <VStack align="stretch" spacing="3px">
            {label && <AppSkeleton isLoaded={loading}><AppInputLabel loading={loading} label={label} /></AppSkeleton>}
            <AppSkeleton isLoaded={loading}><Box>{handleElement}</Box></AppSkeleton>
            {error && <AppErrorBadge message={error} />}
        </VStack>
    )
}

export default AppFormMaker