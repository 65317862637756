import { HStack, Stack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { ReactComponent as CasperWallet } from "assets/icons/footer/casper/casper-wallet-fill-logo.svg";
import { ReactComponent as Casper } from "assets/icons/footer/casper/casper-wallet.svg";

const FooterCasperSupported = () => {
    return (
        <Stack direction={{ base: "column", lg: "row" }} align="center" spacing={"16px"}>
            <AppTypography fontFamily="Montserrat" fontWeight="700" fontSize="15px" color="#FFFFFF">
                Supported Wallets
            </AppTypography>
            <HStack>
                <Casper style={{ width: "42px", height: "42px" }} />
                <CasperWallet style={{ width: "42px", height: "42px" }} />
            </HStack>
        </Stack>
    );
};

export default FooterCasperSupported;
