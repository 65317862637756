import { IShop, ShopThemeEnum } from "lib/models/shop";

namespace privacyModel {
    export const getColors = (shopTheme: ShopThemeEnum, { shopDesign }: IShop) => {
        const lightThemeColors = { links: "#295EAD", texts: "#000", divider: "#27262B", navBackground: "#1E1E1E", background: "#FFF" };
        const darkThemeColors = { links: "#426DAD", texts: "#FFF", divider: "#E9E9E2", navBackground: "#f9f9f6", background: "#000" };
        //to know the default theme is dark or not we're able to check background and foreground colors, if any of them was white then theme is light, i know this is not the best way to check it; but we need an another field in shopDesign data as 'isDark' for it
        const defaultThemeColor = shopDesign?.foreground === "#FFFFFF" || shopDesign?.backgroundBody === "#FFFFFF" ? lightThemeColors : darkThemeColors;
        const themes = {
            [ShopThemeEnum.THEME_UNSTOPPABLE]: lightThemeColors,
            [ShopThemeEnum.THEME_SKALE]: lightThemeColors,
            [ShopThemeEnum.THEME_CASPER]: lightThemeColors,
            [ShopThemeEnum.THEME_POLYGON]: lightThemeColors,
            [ShopThemeEnum.THEME_1]: defaultThemeColor,
            [ShopThemeEnum.THEME_2]: defaultThemeColor,
        };
        return themes[shopTheme];
    };
}
export default privacyModel;
