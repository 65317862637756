import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const StripeComponent = ({
  clientSecret,
  appea,
}: {
  clientSecret: string;
  appea: "stripe" | "night" | "flat" | "none";
}) => {

  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: appea,
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripeComponent;
