import { chakra, HStack, Stack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { ReactComponent as One } from "assets/icons/footer/casper/1.svg";
import { ReactComponent as Two } from "assets/icons/footer/casper/2.svg";
import { ReactComponent as Three } from "assets/icons/footer/casper/3.svg";
const CasperCopyright = () => {
    return (
        <Stack direction={{ base: "column", lg: "row" }} align="center" spacing={"12px"}>
            <AppTypography textAlign={{ base: "center", lg: "right" }} fontFamily="Montserrat" fontWeight="400" fontSize={{ base: "15px", lg: "12px", xl: "15px" }} color="#CACACA">
                Casper Punks{" "}
                <chakra.span color={"#AD203E"} fontWeight="700">
                    © 2023 by{" "}
                </chakra.span>
                Punk Games LTD.{" "}
                <chakra.span color={"#AD203E"} fontWeight="700">
                    is licensed under CC BY-SA 4.0{" "}
                </chakra.span>
            </AppTypography>
            <HStack>
                <One />
                <Two />
                <Three />
            </HStack>
        </Stack>
    );
};

export default CasperCopyright;
