import { Flex, VStack } from '@chakra-ui/react';
import { useToastify } from 'context/toastify/ToastifyContext';
import useAppTheme from 'hooks/_refactor/theme/useAppTheme';
import useWeb3Hook, { Iwallets } from 'hooks/_refactor/web3/useWeb3Hook';
import { ShopThemeEnum } from 'lib/models/shop';
import { useShopStore } from 'lib/stores/shop/shop';
import { useMemo, useState } from 'react';
import CustomIcon from '../customIcon/customIcon';
import AppLoading from '../loading/AppLoading';
import AppTypography from '../typography/AppTypography';
import appWalletsModel from './model';

interface IProps {
    onClick?: Function
}

interface Iitems {
    [propname: string]: Iwallets
}

function AppWallets({ onClick }: IProps) {
    const { shopTheme, shopData: { shopDesign } } = useShopStore()
    const { backgroundBody, foreground } = shopDesign
    const [Loading, setLoading] = useState(false)
    const { wallets } = useWeb3Hook();
    const { errorToast } = useToastify()
    const isDark: boolean = useMemo(() => backgroundBody === '#FFFFFF' || foreground === '#FFFFFF', [backgroundBody, foreground])
    const { isDefaultTheme } = useAppTheme()
    const { theme } = appWalletsModel

    const items: Iitems = {
        [ShopThemeEnum.THEME_1]: wallets,
        [ShopThemeEnum.THEME_2]: wallets,
        [ShopThemeEnum.THEME_SKALE]: wallets,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: { multiwallet: wallets.multiwallet },
        [ShopThemeEnum.THEME_CASPER]: { casper: wallets.casper },
        [ShopThemeEnum.THEME_POLYGON]: wallets,
    }

    const getTheme = theme({ shopDesign })[shopTheme]

    return (
        <VStack align="stretch" spacing="12px">
            {Loading ? <AppLoading /> : Object.keys(items[shopTheme]).map((el, key: number) => (
                <VStack key={key} align="stretch" spacing="10px">
                    {isDefaultTheme && <AppTypography fontSize="14px">{items[shopTheme][el].wallet}</AppTypography>}
                    {Object.keys(items[shopTheme][el].chains).map((chain, key: number) => {
                        const wallet = items[shopTheme][el].chains[chain]
                        return (
                            <Flex alignItems="center" key={key} gap="10px" padding="16px" borderRadius="8px" boxShadow="0 4px 6px -3px rgba(0,0,0,0.3)" {...getTheme.button} cursor="pointer"
                                onClick={async () => {
                                    try {
                                        setLoading(true)
                                        await wallet.method()
                                        if (onClick) onClick()
                                        setLoading(false)
                                    } catch (error: any) {
                                        setLoading(false)
                                        errorToast(error?.message)
                                    }
                                }}
                            >
                                <CustomIcon as={isDark ? wallet.Icon.dark : wallet.Icon.light} w="20px" h="20px" />
                                <AppTypography fontSize={{ base: "14px", sm: '16px' }} {...getTheme?.typography}>{wallet.caption}</AppTypography>
                            </Flex>
                        )
                    })}
                </VStack>
            ))}
        </VStack>
    )
}
export default AppWallets