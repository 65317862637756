import { Text, TextProps } from '@chakra-ui/react'
import { useShopStore } from 'lib/stores/shop/shop';
import React from 'react'
import appTypographyModel from './model';

interface IProps extends TextProps { }
function AppTypography(props: IProps) {
    const { shopData: { shopDesign: { fontfamily, textColorParagraphs } }, shopTheme } = useShopStore();
    const { themes } = appTypographyModel
    
    return (
        <Text
            fontFamily={fontfamily}
            {...themes(textColorParagraphs)[shopTheme]}
            {...props}
        >
            {props.children}
        </Text>
    )
}

export default AppTypography