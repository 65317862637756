namespace appDropDownModel {
    const hexToRgb = (hex: any) => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m: any, r: any, g: any, b: any) => {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            }
            : null;
    }

    // Convert RGB to hex
    const rgbToHex = (rgb: any) => {
        return (
            "#" +
            ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b)
                .toString(16)
                .slice(1)
                .toUpperCase()
        );
    }

    export const darkenColor = (hex: any, percentage: any) => {
        const rgb = hexToRgb(hex);

        if (rgb) {
            rgb.r = Math.round(rgb.r * (1 - percentage / 100));
            rgb.g = Math.round(rgb.g * (1 - percentage / 100));
            rgb.b = Math.round(rgb.b * (1 - percentage / 100));

            return rgbToHex(rgb);
        }

        return null;
    }
}

export default appDropDownModel