import { Button, Flex, Spinner } from "@chakra-ui/react";


import { StripeButtonProps } from "../../interface";
import BasicButton, { BUTTON_TYPE } from "components/common/basicButton/BasicButton";

const UnstoppableButton = ({ loading, submit, cancel }: StripeButtonProps) => {
  return (
    <Flex pt="16px" w="100%" justifyContent="flex-end" gap="16px">
      <BasicButton
        width="120px"
        maxWidth="120px"
        type={BUTTON_TYPE.LIGHT}
        isDisable={loading}
        click={() => {
          if (!loading) cancel();
        }}
      >
        Cancel
      </BasicButton>
      <Button
        w="120px"
        maxW="120px"
        p="8px 16px"
        color="#FFFFFF"
        bg="#27262B"
        border="1px solid"
        borderRadius="4px"
        fontFamily="Manrope"
        fontSize="16px"
        fontWeight="400"
        borderColor="#27262B"
        type="submit"
        onClick={submit}
        disabled={loading}
      >
        {loading ? <Spinner /> : "Submit"}
      </Button>
    </Flex>
  );
};

export default UnstoppableButton;
