import { Flex, useDisclosure, VStack } from '@chakra-ui/react';
import AppButton from 'components/common/form/button/AppButton'
import { useToastify } from 'context/toastify/ToastifyContext';
import { useProfile } from 'hooks/useProfile/useProfile';
import useAppCart from 'hooks/_refactor/cart/useAppCart';
import useWeb3Hook from 'hooks/_refactor/web3/useWeb3Hook';
import { ShopThemeEnum } from 'lib/models/shop';
import { useShopStore } from 'lib/stores/shop/shop';
import productPageContext from 'pages/_refactors/product/context'
import React, { useContext, useState } from 'react'
import MintToMerch from '../m2m/MintToMerch';
import productButtonModel from './model';

function ProductButton() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { states: { product, sku, option: { quantity } } } = useContext(productPageContext)
    const [Loading, setLoading] = useState({
        login: false,
        addCart: false
    })
    const { add } = useAppCart()
    const { themeIsLoged, isChain } = productButtonModel
    const { shopTheme } = useShopStore()
    const { profile } = useProfile()
    const { errorToast, successToast } = useToastify();
    const { wallets } = useWeb3Hook();

    const updateLoading = <T extends keyof typeof Loading>(key: T, value: typeof Loading[T]) => setLoading(prev => ({ ...prev, [key]: value }))

    const addCart = async () => {
        try {
            if (!sku) throw Error('Please choose options')
            updateLoading('addCart', true)
            await add({ quantity, skuID: sku?._id, product })
            successToast('Product added to cart')
            updateLoading('addCart', false)
        } catch (error: any) {
            errorToast(error?.response?.data?.data?.message || error?.message || "Somthing wrong");
            updateLoading('addCart', false)
        }
    }

    const checkChain = () => isChain(product, shopTheme)

    const loginUnstoppable = async () => {
        try {
            updateLoading('login', true)
            await wallets.multiwallet.chains.unstoppabledomains.method()
            updateLoading('login', false)
        } catch (error) {
            updateLoading('login', false)
        }
    }

    return (
        <VStack align="stretch" spacing="10px">
            {shopTheme === ShopThemeEnum.THEME_UNSTOPPABLE && !profile ? (
                <AppButton width="100%" borderRadius="100px" backgroundColor="#0d67fe" onClick={loginUnstoppable} isLoading={Loading.login}>
                    <Flex alignItems="center" gap="10px">
                        {<wallets.multiwallet.chains.unstoppabledomains.Icon.dark />}
                        Unstoppable Login
                    </Flex>
                </AppButton>
            ) : null}
            <AppButton width="100%" borderRadius="100px" onClick={addCart} isLoading={Loading.addCart}>Add To Cart</AppButton>
            {product?.m2m_services.length && (themeIsLoged(shopTheme) ? checkChain() : true) ? (
                <>
                    <AppButton width="100%" variant="outline" onClick={() => ShopThemeEnum.THEME_UNSTOPPABLE === shopTheme && !profile ? loginUnstoppable() : onOpen()} isDisabled={Loading.login} borderRadius="100px">Mint to Merch</AppButton>
                    {isOpen && <MintToMerch isOpen={isOpen} onClose={onClose} />}
                </>
            ) : null}
        </VStack>
    )
}

export default ProductButton