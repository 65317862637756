import { Hide } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import privacyNavbarModel from './model'
import DesktopPrivacyNavbar from './screens/desktop/DesktopPrivacyNavbar'
import MobilePrivacyNavbar from './screens/mobile/MobilePrivacyNavbar'

const PrivacyNavbar = () => {
  const { hash } = useLocation();
  const {findHashtagFromNavItems} = privacyNavbarModel
  const hashtag = findHashtagFromNavItems(hash);

  return (
    <>
      <Hide below='md'><DesktopPrivacyNavbar hashtag={hashtag} /></Hide>
      <Hide above='md'><MobilePrivacyNavbar hashtag={hashtag}/></Hide>
    </>
  )
}

export default PrivacyNavbar