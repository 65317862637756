import { Box, Flex, Image, SimpleGrid, VStack } from '@chakra-ui/react'
import { InftImagesService } from 'apis/ruleset/interface'
import { nftImagesService } from 'apis/ruleset/services'
import AppButton from 'components/common/form/button/AppButton'
import AppLoading from 'components/common/loading/AppLoading'
import AppTypography from 'components/common/typography/AppTypography'
import { useProfile } from 'hooks/useProfile/useProfile'
import { appDeveloment } from 'lib/utils/app/variables'
import React, { useCallback, useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import mintToMerchContext from '../../../context'

function MintArtwork() {
    const { mutate, data, isLoading } = useMutation((params: InftImagesService) => nftImagesService(params))
    const { states: { source, artwork }, methods: { updateStates } } = useContext(mintToMerchContext)
    const { profile } = useProfile()

    useEffect(() => {
        if (profile?.walletAddress) mutate({ address: profile?.walletAddress, chain: source.toUpperCase(), network: appDeveloment ? "TESTNET" : "MAINNET" })
    }, [profile])

    const createMock = useCallback(() => {
        updateStates('step', 'preview')
    }, [])

    return (
        <>
            {isLoading ? <AppLoading /> : (
                <VStack align="stretch">
                    {data?.data?.data.length ? (
                        <SimpleGrid columns={4} spacing="10px">
                            {data?.data?.data.map((el: any, key: number) => (
                                <Box height="90px" border={`3px solid ${el === artwork ? '#3AEEA2' : 'transparent'}`} key={key} onClick={() => updateStates('artwork', el)} borderRadius="8px" background={`url('${el}') center no-repeat`} cursor="pointer" backgroundSize="cover"></Box>
                            ))}
                        </SimpleGrid>
                    ) : <AppTypography width="100%" fontSize="18px" color="#777" textAlign="center">Not found any artwork</AppTypography>}
                    <Flex justifyContent="space-between" direction="row-reverse">
                        <AppButton paddingRight="20px" paddingLeft="20px" size="sm" isDisabled={!Boolean(artwork.length)} onClick={createMock}>Next</AppButton>
                        <AppButton paddingRight="20px" paddingLeft="20px" size="sm" onClick={() => updateStates('step', 'position')}>Back</AppButton>
                    </Flex>
                </VStack>
            )}
        </>
    )
}

export default MintArtwork