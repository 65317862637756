import { HStack } from "@chakra-ui/react"
import AppTypography from "components/common/typography/AppTypography"

interface ITitleDesc {
  title: string,
  desc: string | number
}
const PurchaseHistoryTextGroup = ({ title, desc }: ITitleDesc) => {
  return (
    <HStack>
      <AppTypography fontSize={{ base: "12px", sm: "16px" }}>{title}:</AppTypography>
      <AppTypography fontSize={{ base: "12px", sm: "16px" }}>{desc}</AppTypography>
    </HStack>
  )
}

export default PurchaseHistoryTextGroup