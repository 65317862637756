import React, { useState } from "react";
import { useClipboard, Icon, Tag, Tooltip } from "@chakra-ui/react";
import AppIcons from "assets/icons/appIcons";
import AppButton from "components/common/form/button/AppButton";

interface IProps {
    toCopy: string;
}
const AppCopy = ({ toCopy }: IProps) => {
    const [copied, setCopied] = useState(false);
    const { onCopy } = useClipboard(toCopy);

    const handleCopy = () => {
        onCopy();
        setCopied(true);
        setTimeout(() => { setCopied(false) }, 5000);
    };

    return (
        <Tooltip color={"black"} bg={"white"} fontSize={"12px"} label={copied ? "Copied" : "Copy To Clipboard"} closeOnClick={false} closeDelay={500} hasArrow arrowSize={15}>
            <Tag bg={"transparent"} _hover={{ bg: "transparent" }}>
                <AppButton border={"none"} bg={"transparent"} _hover={{ bg: "transparent" }} onClick={handleCopy}>
                    <Icon as={AppIcons.Copy} transition="0.3s" />
                </AppButton>
            </Tag>
        </Tooltip>
    );
};

export default AppCopy;
