import React from 'react'
import { Box } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import { Link } from 'react-router-dom'
import CartHeaderItems from './parts/products/CartHeaderItems'
import { useShopStore } from 'lib/stores/shop/shop'
import cartPageButtonGroupsModel from 'pages/_refactors/checkout/pages/cart/parts/buttons/model'
import AppButton from 'components/common/form/button/AppButton'
import useAppCheckout from 'hooks/_refactor/checkout/useAppCheckout'
import HeaderBoxHover from '../parts/modal/HeaderBoxHover'
import useAppCart from 'hooks/_refactor/cart/useAppCart'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'

function CartHeader() {
  const { gated } = useAppCheckout()
  const { route, shopNavigate } = useCustomNavigate()
  const { countItems } = useAppCart()
  const { shopDesign: { textColorParagraphs } } = useShopStore()
  const { checkout: checkoutTrriger } = cartPageButtonGroupsModel
  const { isDefaultTheme } = useAppTheme()

  return (
    <HeaderBoxHover>
      <AppTypography fontSize="14px" textAlign="center" borderBottom={`1px solid ${textColorParagraphs}`} paddingBottom="10px">{countItems ? "Item added to your cart" : "No items to show yet. Start now!"}</AppTypography>
      {countItems && <Box><CartHeaderItems /></Box>}
      <Link to={route('checkout/cart')}>
        <AppButton variant="outline" width="100%">
          <AppTypography fontSize={{ base: "14px", sm: "16px" }}>See my cart ({countItems})</AppTypography>
        </AppButton>
      </Link>
      <AppButton onClick={() => checkoutTrriger({
        gated,
        openEmailModal: () => shopNavigate('checkout/cart'),
        shopNavigate,
        isDefaultTheme
      })}>Checkout</AppButton>
      <Link to={route('')}><AppTypography fontSize={{ base: "14px", sm: "16px" }} textAlign="center">Continue Shopping</AppTypography></Link>
    </HeaderBoxHover>
  )
}

export default CartHeader