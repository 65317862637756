import { Box } from '@chakra-ui/react';
import appThemes from 'lib/models/themes/appThemes';
import { useShopStore } from 'lib/stores/shop/shop';
import React from 'react'

function Header() {
  const { shopTheme } = useShopStore();

  return appThemes.layout.header[shopTheme] || appThemes.layout.header['theme-1']
}

export default Header