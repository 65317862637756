export const appDeveloment = process.env.REACT_APP_PRODUCTION === "false"

export const BASE_URL = appDeveloment ? "https://apiv3dev.droplinked.com" : process.env.REACT_APP_BASE_API_URL;

export const UNSTOPPABLE_CLIENT_ID = [
  "https://shop.unstoppabledomains.com",
  "https://www.shop.unstoppabledomains.com",
].includes(window.location.origin)
  ? window.location.origin === "https://www.shop.unstoppabledomains.com"
    ? process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID_SHOP_WWW
    : process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID_SHOP
  : process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID;
