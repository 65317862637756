import { useDisclosure } from '@chakra-ui/react'
import CustomIcon from 'components/common/customIcon/customIcon'
import AppDialog from 'components/common/dialog/AppDialog'
import AppButton from 'components/common/form/button/AppButton'
import AppTypography from 'components/common/typography/AppTypography'
import { useToastify } from 'context/toastify/ToastifyContext'
import useAppAddress from 'hooks/_refactor/address/useAppAddress'
import { useShopStore } from 'lib/stores/shop/shop'
import React, { useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import checkoutListAddresModel from '../../model'

interface IProps {
    address: any
    onSuccess?: Function
}
function ListAddressDelete({ address, onSuccess }: IProps) {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { errorToast, successToast } = useToastify()
    const { deleted } = useAppAddress()
    const { themes } = checkoutListAddresModel
    const { shopTheme } = useShopStore()
    const [Loading, setLoading] = useState(false)

    return (
        <>
            <CustomIcon as={AiOutlineDelete} onClick={onOpen} color={themes[shopTheme]} w="20px" h="20px" />
            {isOpen && <AppDialog text={`Are you sure ${address?.addressLine1} deleted ?`} close={onClose} open={true} title='Confirm delete' buttons={[
                {
                    onClick: onClose,
                    children: 'Cancel',
                },
                {
                    onClick: async () => {
                        try {
                            setLoading(true)
                            await deleted({ addressID: address?._id })
                            if (onSuccess) onSuccess()
                            onClose()
                            successToast('Address is deleted')
                            setLoading(false)
                        } catch (error: any) {
                            setLoading(false)
                            errorToast(error?.message)
                        }
                    },
                    children: 'Delete',
                    buttonProps: {
                        isLoading: Loading
                    }
                }
            ]} />}
        </>
    )
}

export default ListAddressDelete