import { FlexProps } from "@chakra-ui/react";
import { createContext } from "react";

export interface IhomepageDefaultStateThemes {
  sidebarBoxes: FlexProps,
  textColor: string
}

export interface IhomepageDefaultState {
  search: string
  theme: IhomepageDefaultStateThemes
}

export const homepageDefaultState: IhomepageDefaultState = {
  search: '',
  theme: {
    sidebarBoxes: {},
    textColor: ''
  }
}

interface IProps {
  States: IhomepageDefaultState
  updateState(key: string, value: any): void
}
const homepageDefaultContext = createContext<IProps>({
  States: homepageDefaultState,
  updateState: () => { }
})

export default homepageDefaultContext