export const capitalizeFirstLetter = (value: string) => {
    if (!value) return ""
    return value.split(" ").map(el => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase()).join(" ")
}

export const isDarkColor = (hexColor: string) => {
    hexColor = hexColor.replace("#", "");
    const red = parseInt(hexColor.substr(0, 2), 16);
    const green = parseInt(hexColor.substr(2, 2), 16);
    const blue = parseInt(hexColor.substr(4, 2), 16);
    const luminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255;
    const threshold = 0.5;
    return luminance < threshold;
}