import axiosInstance from "apis/axiosConfig";
import { Im2mAddToCartService, IpodGenerateMockup, IpodGenerateTextService } from "./interface";

export const podGenerateTextService = (props: IpodGenerateTextService) => {
    return axiosInstance.post(`pod/generate/text/overlay`, props);
};
export const podGenerateOverlayService = ({ baseImageLink, overlayImageLink, bgremove }: IpodGenerateTextService) => {
    return axiosInstance.post(`pod/generate/overlay${bgremove ? "?bgremove=sd" : ""}`, { baseImageLink, overlayImageLink });
};

export const podGenerateMockup = ({ variant_ids, placement, image_url, podBlankId }: IpodGenerateMockup) => {
    return axiosInstance.post(`pod/generate-mockup/${podBlankId}?order=true`, { variant_ids, files: [{ placement, image_url, position: {} }] });
}
    ;
export const m2mAddToCartService = ({ cartId, itemId, params }: Im2mAddToCartService) => {
    return axiosInstance.post(`cart/add/preview/item/${cartId}/${itemId}`, params);
};
