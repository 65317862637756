import { IShop, IShopDesign, ShopThemeEnum } from "lib/models/shop";

namespace appSeperatorModel {
    export const getSeperatorColor = (shopTheme: ShopThemeEnum, shopDesign: IShopDesign) => {
        const defaultThemeColor = shopDesign?.foreground === "#FFFFFF" || shopDesign?.backgroundBody === "#FFFFFF" ? { borderColor: "#27262B" } : { borderColor: "#E9E9E2" };
        const themes = {
            [ShopThemeEnum.THEME_UNSTOPPABLE]: { borderColor: "#27262B" },
            [ShopThemeEnum.THEME_SKALE]: { borderColor: "#27262B" },
            [ShopThemeEnum.THEME_CASPER]: { borderColor: "#27262B" },
            [ShopThemeEnum.THEME_POLYGON]: { borderColor: "#27262B" },
            [ShopThemeEnum.THEME_1]: defaultThemeColor,
            [ShopThemeEnum.THEME_2]: defaultThemeColor,
        };
        return themes[shopTheme];
    };
}
export default appSeperatorModel;
