import React, { useEffect } from 'react'
import { Box, Flex, VStack } from '@chakra-ui/react'
import AppSeperator from 'components/common/seperator/AppSeperator'
import AppTypography from 'components/common/typography/AppTypography'
import GiftCardDefault from 'pages/_refactors/checkout/parts/gift/GiftCardDefault'
import CheckourCard from 'pages/_refactors/checkout/parts/layout/card/CheckoutCard'
import PurchaseDefault from 'pages/_refactors/checkout/parts/purchase/PurchaseDefault'
import CheckoutMainButtons from './parts/buttons/CheckoutMainButtons'
import CheckoutMainDetail from './parts/details/CheckoutMainDetail'
import CheckoutMainTotal from './parts/total/CheckoutMainTotal'
import AppContainer from 'components/common/container/AppContainer'
import useAppCheckout from 'hooks/_refactor/checkout/useAppCheckout'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import { Navigate } from 'react-router-dom'
import cartPageButtonGroupsModel from '../../../cart/parts/buttons/model'
import useAppCart from 'hooks/_refactor/cart/useAppCart'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'
import PaymentCustomOptions from './parts/options/PaymentCustomOptions'
import CheckoutLayoutPage from 'pages/_refactors/checkout/parts/layout/page/CheckoutLayoutPage'

function PaymentCustom() {
    const { route, shopNavigate } = useCustomNavigate()
    const { countItems } = useAppCart()
    const { checkout } = cartPageButtonGroupsModel
    const { gated } = useAppCheckout()
    const { isDefaultTheme } = useAppTheme()

    useEffect(() => {
        checkout({ gated, openEmailModal: () => shopNavigate('checkout/cart'), shopNavigate, isDefaultTheme })
    }, [gated])

    return countItems ? (
        <CheckoutLayoutPage title='Checkout Payment'>
            <VStack align="stretch" spacing="16px">
                <CheckourCard><PurchaseDefault /></CheckourCard>
                <AppSeperator borderColor="#DDD" />
                <Flex justifyContent="space-between" flexDirection={{ base: "column", sm: "row" }}>
                    <Box width={{ base: "100%", sm: "40%" }}>
                        <GiftCardDefault />
                    </Box>
                    <Box width={{ base: "100%", sm: "40%" }}><CheckoutMainDetail /></Box>
                </Flex>
                <AppSeperator borderColor="#DDD" />
                <CheckoutMainTotal />
                <AppSeperator borderColor="#DDD" />
                <PaymentCustomOptions />
                <AppSeperator borderColor="#DDD" />
                <CheckoutMainButtons />
            </VStack>
        </CheckoutLayoutPage>
    ) : <Navigate to={route('checkout/cart')} />
}

export default PaymentCustom