import { VStack } from "@chakra-ui/react";
import { useCallback } from "react";
import PrivacyLists from "../../parts/list/PrivacyLists";
import privacyNavbarModel from "../../model";
import { useShopStore } from "lib/stores/shop/shop";
import privacyModel from "pages/_refactors/privacy/model";

const DesktopPrivacyNavbar = ({hashtag}: {hashtag: string}) => {
    const shop = useShopStore();
    const { navItems } = privacyNavbarModel;
    const getDesign = useCallback(() => privacyModel.getColors(shop.shopTheme, shop.shopData), [shop.shopTheme, shop.shopData]);
    return (
        <VStack justifyContent="flex-start" flexDirection={"column"} minW="300px" color={getDesign()?.texts} position={"sticky"} top={26} height="50vh">
            <PrivacyLists hashtag={hashtag} navItems={navItems} />
        </VStack>
    );
};

export default DesktopPrivacyNavbar;
