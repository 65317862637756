import { Box, Flex, VStack } from '@chakra-ui/react';
import AppTypography from 'components/common/typography/AppTypography';
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import productModel from './model';

interface IProps {
    product: any
}
function Product({ product }: IProps) {
    const { route } = useCustomNavigate()

    const colors = useMemo(() => productModel.getOptions({ skuIDs: product?.skuIDs, type: "color" }), [product])

    return (
        <Link to={`${route("product")}/${product._id}`}>
            <VStack align="stretch" spacing="0">
                <Box height={{ base: "150px", lg: "200px", xl: "320px" }} position="relative" background={`url(${product.media.find((el: any) => el.isMain === "true").url}) center`} backgroundSize="cover"></Box>
                <VStack align="stretch" spacing="3px">
                    <AppTypography fontWeight="bold" paddingTop="10px" textAlign="center" fontSize={{ base: "14px", sm: "16px" }}>{product?.title}</AppTypography>
                    {product && product?.skuIDs && product?.skuIDs.length ? <AppTypography textAlign="center" fontSize={{ base: "14px", sm: "16px" }}>${parseFloat(product?.skuIDs[0].price).toFixed(2)} USD</AppTypography> : null}
                    <Flex justifyContent="center" gap="8px" flexWrap={"wrap"}>
                        {colors.length ? colors.map((el: any, key: number) => (
                            <Box key={key} height="16px" width="16px" borderRadius="100%" border="1px solid #777" backgroundColor={el.value}></Box>
                        )) : null}
                    </Flex>
                </VStack>
            </VStack>
        </Link>
    )
}

export default Product