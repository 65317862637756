import { Divider, DividerProps } from "@chakra-ui/react";
import { useShopStore } from "lib/stores/shop/shop";
import React from "react";
import appSeperatorModel from "./model";
interface IProps extends DividerProps {}

const AppSeperator = (props: IProps) => {
  const { shopTheme, shopData: { shopDesign } } = useShopStore();
  const themes = appSeperatorModel.getSeperatorColor(shopTheme, shopDesign);

    return <Divider {...themes} {...props} />;
};

export default AppSeperator;
