import { VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React, { useMemo } from 'react'

function CheckoutMainDetail() {
    const { states: { cart } } = useAppStore()

    const items = useMemo(() => [
        {
            caption: 'Subtotal',
            value: cart?.totalCart?.subtotal
        },
        {
            caption: 'Shipping',
            value: cart?.totalCart?.shipping
        },
        {
            caption: 'Estimated Taxes',
            value: cart?.totalCart?.estimatedTaxes
        }
    ], [cart])

    return (
        <VStack align={{ base: "center", sm: "end" }} paddingTop={{ base: "20px", sm: "0" }} spacing={{ base: "3px", sm: "8px" }}>
            {items.map((el, key) => (
                <AppTypography key={key} fontSize={{ base: "14px", sm: "16px" }}>{el.caption}: ${el.value} USD</AppTypography>
            ))}
        </VStack>
    )
}

export default CheckoutMainDetail