import AppMagnifier from "components/common/magnifier/AppMagnifier";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import { ICartStoreItem } from "lib/stores/_refactor/cart/interfaces";
import { Link } from "react-router-dom";

const CartPageProductImage = ({ cartItem }: { cartItem: ICartStoreItem }) => {
    const { route } = useCustomNavigate();
    return (
        <Link to={route(`product/${cartItem?.product?._id}`)}>
            <AppMagnifier
                src={cartItem.product.m2m_preview || cartItem.product.image}
                props={{ minW: { base: '96px', md: '144px' }, height: { base: '96px', md: '144px' } }}
                magnifierRadius={60}
                zoom={6}
            />
        </Link>
    );
};

export default CartPageProductImage;
