import { IconProps, ModalContentProps, TextProps } from "@chakra-ui/react"
import { IShopDesign, ShopThemeEnum } from "lib/models/shop"

interface Ithemes {
    [propname: string]: {
        title: TextProps
        modal: ModalContentProps
        close?: IconProps
    }
}

namespace appModalModel {
    export const themes = (shopDesign: IShopDesign): Ithemes => {
        const { foreground, textColorParagraphs } = shopDesign
        const themes: Ithemes = {
            default: {
                title: {
                    fontSize: "20px",
                    fontWeight: "bold"
                },
                modal: {
                    backgroundColor: foreground || "#27262B",
                    color: textColorParagraphs || "#DDD",
                    border: `4px solid #000`
                },
                close: {
                    width: "24px",
                    height: "24px"
                }
            },
            custom: {
                title: {
                    fontSize: "22px",
                    fontWeight: "bolder"
                },
                modal: {
                    backgroundColor: "#f9f6fe",
                    border: "4px solid #e2d8f3"
                },
                close: {
                    backgroundColor: "#FFF",
                    borderRadius: "100%",
                    width: "28px",
                    height: "28px",
                    padding: "2px"
                }
            }
        }

        return {
            [ShopThemeEnum.THEME_1]: themes.default,
            [ShopThemeEnum.THEME_2]: themes.default,
            [ShopThemeEnum.THEME_SKALE]: themes.custom,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: themes.custom,
            [ShopThemeEnum.THEME_CASPER]: themes.custom,
            [ShopThemeEnum.THEME_POLYGON]: themes.custom,
        }
    }
}

export default appModalModel