import { IShop } from "lib/models/shop";
import { ShopThemeEnum } from "lib/models/shop";
namespace footerDefaultModel {
    export const getLinks = (shopTheme: ShopThemeEnum, shopData: IShop) => {
        const brandLinks = [ShopThemeEnum.THEME_1, ShopThemeEnum.THEME_2].includes(shopTheme)
            ? (shopData?.shopDesign?.footerLinks || []).filter(el => el.caption && el.link).map(el => ({ ...el, link: el.link.startsWith('http') ? el.link : `http://${el.link}` }))
            : [
                { caption: "Any questions? Contact us at support@droplinked.com", link: "mailto:support@printful.com" },
                { caption: "Terms & Conditions", link: "privacy" },
                { caption: "Returns & FAQ", link: "return-policy" },
            ];
        const licenceTexts = ["Powered by droplinked", `© ${new Date().getFullYear()} ${shopTheme === ShopThemeEnum.THEME_UNSTOPPABLE ? "Unstoppable" : "droplinked"}, All Rights Reserved`];
        
        const licenceLinks = [
            { caption: "Terms & Conditions", link: "privacy" },
            { caption: "Returns & FAQ", link: "return-policy" },
        ];
        
        return {
            brand: { links: brandLinks },
            licence: {
                texts: licenceTexts,
                links: licenceLinks,
            },
        };
    };
    export const design = (shopTheme: ShopThemeEnum, { shopDesign }: IShop) => {
        const defaults = {
            background: shopDesign?.foreground,
            color: shopDesign?.textColorParagraphs,
            p: { base: "48px 21px", lg: "85px 21px", xl: "85px 120px", "2xl": "85px 160px" },
        };
        const themes = {
            [ShopThemeEnum.THEME_UNSTOPPABLE]: { background: "#0d65fe", color: "#FFF", p: { base: "48px 32px", lg: "85px 64px", xl: "85px 125px" } },
            [ShopThemeEnum.THEME_SKALE]: { background: "#000", color: "#FFF", p: { base: "48px 21px", lg: "85px 21px", xl: "85px 120px", "2xl": "85px 160px" } },
            [ShopThemeEnum.THEME_POLYGON]: { background: "#7d3de2", color: "#E9E9E2", p: { base: "48px 32px", lg: "85px 64px", xl: "85px 125px" } },
            [ShopThemeEnum.THEME_1]: defaults,
            [ShopThemeEnum.THEME_2]: defaults,
            [ShopThemeEnum.THEME_CASPER]: { color: '' },
        };
        return themes[shopTheme];
    };
}
export default footerDefaultModel;
