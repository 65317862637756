import { appDeveloment } from 'lib/utils/app/variables'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { ICartStore, IShop } from './interfaces'

interface IupdateState {
    state: string
    value: any
}

export interface IAppStore {
    states: {
        cart: ICartStore | null
        user: any
        shop: IShop | null
    }
    methods: {
        updateState: (props: IupdateState) => void
    }
}

const states = (set: any): IAppStore => ({
    states: {
        cart: null,
        user: null,
        shop: null,
    },
    methods: {
        updateState: ({ state, value }: IupdateState) => {
            set((prev: IAppStore) => ({
                ...prev,
                states: {
                    ...prev.states,
                    [state]: value
                }
            }))
        },
    }
})

export const appStorePersistName = "appStoreStorage"
const _persist = persist(states, {
    name: appStorePersistName, partialize: (state) => ({
        states: state.states
    })
})
const useAppStore = appDeveloment ? create<IAppStore>()(devtools(_persist, { name: "App" })) : create<IAppStore>()(_persist)

export default useAppStore