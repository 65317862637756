import { TextProps } from "@chakra-ui/react";
import { ShopThemeEnum } from "lib/models/shop";

interface IThemes {
    [propname: string]: TextProps
}
namespace appTypographyModel {
    export const themes = (textColorParagraphs: string): IThemes => ({
        [ShopThemeEnum.THEME_1]: { color: textColorParagraphs || "#FFF" },
        [ShopThemeEnum.THEME_2]: { color: textColorParagraphs || "#FFF" },
        [ShopThemeEnum.THEME_SKALE]: { color: "#333" },
        [ShopThemeEnum.THEME_UNSTOPPABLE]: { color: "#333" },
        [ShopThemeEnum.THEME_CASPER]: { color: "#333" },
        [ShopThemeEnum.THEME_POLYGON]: { color: "#333" },
    })
}

export default appTypographyModel