import { Image, ImageProps } from '@chakra-ui/react';
import { useShopStore } from 'lib/stores/shop/shop';
import { isCustomDomain } from 'lib/utils/theme/theme-utils';
import React from 'react'
import { Link, useParams } from 'react-router-dom';
interface IProps extends ImageProps {  }
const AppLogo = (props: IProps) => {
    const { shopData: { headerIcon } } = useShopStore()
    const param = useParams();
    return <Link to={isCustomDomain ? "/" : "/" + param.shopName}><Image src={props?.src || headerIcon} height="45px" {...props} maxWidth="100%" /></Link>

}

export default AppLogo