import { Flex } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import { useMemo } from 'react'

function PaymentTotalDefault() {
    const { states: { cart } } = useAppStore()

    const items = useMemo(() => [
        {
            title: 'Subtotal',
            value: cart?.totalCart.subtotal.toFixed(2)
        },
        {
            title: 'Shipping',
            value: cart?.totalCart?.shipping.toFixed(2)
        },
        {
            title: 'Estimated Taxes',
            value: cart?.totalCart?.estimatedTaxes.toFixed(2)
        },
        {
            title: 'Discount / Gift card',
            value: cart?.totalCart.giftCard?.amount.toFixed(2)
        },
        {
            title: "Total payment",
            value: cart?.totalCart.totalPayment.toFixed(2)
        }
    ], [cart])

    return (
        <Flex direction={"column"} gap={{ base: "8px", md: "16px" }} >
            {items.map((el, key) => (
                <Flex justifyContent={"space-between"} alignItems={"center"} key={key} fontSize={{ base: "14px", md: "16px" }}>
                    <AppTypography color={"#E9E9E2"}>{el.title}:</AppTypography>
                    <AppTypography color={"#E9E9E2"}>{`$ ${el.value} USD`}</AppTypography>
                </Flex>
            ))}
        </Flex>
    )
}

export default PaymentTotalDefault