import { Flex } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";

const PaymentDetailsTextComponent = ({ title, value }: { title: string; value: string }) => {
    return (
        <Flex justifyContent={"space-between"} fontSize={{ base: "14px", md: "16px" }}>
            <AppTypography>{title}:</AppTypography>
            <AppTypography>{value}</AppTypography>
        </Flex>
    );
};

export default PaymentDetailsTextComponent