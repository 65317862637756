import React, { useCallback } from 'react'
import { addressAttachCartService, createAddressService, deleteAddressService, updateAddressService } from 'apis/address/addressServices'
import { IaddressAttachCartService, IcreateAddressService, IcreateAddressServiceParams, IdeleteAddressService, IupdateAddressService } from 'apis/address/interfaces'
import { useProfile } from 'hooks/useProfile/useProfile'
import { useMutation } from 'react-query'
import useAppCart from '../cart/useAppCart'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'

function useAppAddress() {
    // const { methods: { updateState } } = useAppStore()
    const { profile } = useProfile()
    const { states: { cart } } = useAppStore();
    const createService = useMutation((params: IcreateAddressService) => createAddressService(params))
    const addressAttachCart = useMutation((params: IaddressAttachCartService) => addressAttachCartService(params))
    const updateService = useMutation((params: IupdateAddressService) => updateAddressService(params))
    const deleteService = useMutation((params: IdeleteAddressService) => deleteAddressService(params))
    const { addToCart } = useAppCart()
    const loading = addressAttachCart.isLoading || addressAttachCart.isLoading

    const create = useCallback((params: IcreateAddressServiceParams) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const anonymous = !Boolean(profile)
                const { data } = await createService.mutateAsync({ anonymous, params })
                const result = data?.data
                await addToCart({ field: 'address', value: result._id })
                resolve(result)
            } catch (error) {
                reject(error)
            }
        })
    }, [profile, cart])

    const update = async (params: IupdateAddressService) => {
        try {
            await updateService.mutateAsync(params)
        } catch (error) {
            return error
        }
    }
    const deleted = useCallback((params: IdeleteAddressService) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const cartAdressID = cart?.address?._id
                if (cartAdressID && cartAdressID === params.addressID) throw Error('This address used and cant deleted')
                const { data } = await deleteService.mutateAsync(params)
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }, [cart])


    return { create, update, deleted, loading }
}

export default useAppAddress