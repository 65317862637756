import { Xumm } from "xumm";

const apiKey = process.env.REACT_APP_XUMM_API_KEY;
async function loginWithXumm() {
    let xumm = new Xumm(apiKey || "");
    await xumm.authorize();
    let address = await xumm.user.account;
    address = await xumm.user.account;
    const jwt = await xumm.environment.jwt;
    const payloadResult = await xumm.payload?.get(jwt?.payload_uuidv4 as string);
    return { address, payload: (payloadResult as any).response.hex as string };
}

async function logoutXumm() {
    let xumm = new Xumm(apiKey || "");
    await xumm.logout();
}

export { loginWithXumm, logoutXumm };
