import { Box, HStack } from '@chakra-ui/react'
import AppIcons from 'assets/icons/appIcons'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'
import AppTypography from 'components/common/typography/AppTypography'
import productPageContext from 'pages/_refactors/product/context'
import React, { useContext } from 'react'
import styles from './style.module.scss'

function ProductGated() {
    const { states: { product, ruleset } } = useContext(productPageContext)
    const rule = product?.ruleSet    

    return rule ? ruleset.loading ? <AppSkeleton height="20px" /> : (
        <HStack alignItems='center' className={`${styles.icon} ${(rule?.gated && ruleset.data) || (!rule?.gated && ruleset.data?.discountPercentage) ? styles.active : ''}`}>
            {rule?.gated ? <AppIcons.Gated /> : <AppIcons.Discount />}
            <AppTypography opacity=".7" fontSize="14px">{rule?.rules[0]?.description}</AppTypography>
        </HStack>
    ) : null
}

export default ProductGated