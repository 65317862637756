import axiosInstance from "apis/axiosConfig"
import { IaddCartService, IaddEmailToCartService, IdeleteCartItemService, IdeleteCartService, IdiscountCartService, IgetCartService, IupdateCartService } from "./interface"

export const createCartService = () => {
    return axiosInstance.post(`cart/public/anonymous-cart`)
}
export const addCartService = ({ cartID, params, anonymous }: IaddCartService) => {
    return axiosInstance.post(anonymous ? `cart/v2/public/anonymous-cart/${cartID}` : `cart/v2`, params)
}

export const getCartService = ({ cartID, anonymous }: IgetCartService) => {
    return axiosInstance.get(anonymous ? `cart/v2/public/anonymous-cart/${cartID}` : `cart/v2`)
}

export const updateCartService = ({ cartID, params, anonymous, shopID }: IupdateCartService) => {
    return axiosInstance.patch(anonymous ? `cart/v2/public/anonymous-cart/${cartID}` : `cart/v2/${params.skuID}`, { shopID, ...params })
}
export const deleteCartItemService = ({ cartID, params, anonymous }: IdeleteCartItemService) => {
    return axiosInstance.delete(anonymous ? `cart/v2/public/anonymous-cart/${cartID}` : `cart/v2/${params.itemId}`, { data: params })
}

export const addEmailToCartService = ({ cartID, params }: IaddEmailToCartService) => {
    return axiosInstance.patch(`cart/public/anonymous-cart/email/${cartID}`, params)
}

export const discountCartService = ({ anonymous, cartId, code }: IdiscountCartService) => {
    return axiosInstance.patch(anonymous ? `giftcard/public/apply/${code}/${cartId}` : `giftcard/apply/${code}`)
}

export const deleteCartService = ({ anonymous, cartId }: IdeleteCartService) => {
    return axiosInstance.delete(anonymous ? `cart/public/${cartId}` : `cart`)
}