import { SimpleGrid, Skeleton } from '@chakra-ui/react';

function ProductsLoading({ marginTop = 0 }: { marginTop?: number }) {
    return (
        <SimpleGrid
            mt={marginTop}
            columns={{ base: 2, sm: 3 }}
            spacingY={8}
            spacing={6}
        >
            {Array.from({ length: 6 })?.map((_, i) => {
                return <Skeleton key={i} w="100%" h="200px" />;
            })}
        </SimpleGrid>
    )
}

export default ProductsLoading