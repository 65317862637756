import { HStack, VStack } from "@chakra-ui/react";
import AppSeperator from "components/common/seperator/AppSeperator";
import AppTypography from "components/common/typography/AppTypography";
import React from "react";

const PolicyHeader = () => {
    return (
        <VStack px={{ base: 6, lg: 20 }} w="full">
            <HStack py={26} justifyContent={"space-between"} w="full">
                <AppTypography fontFamily={"Montserrat"} fontWeight={700} fontSize={{ base: "20px", lg: "28px" }}>
                    Return Policy
                </AppTypography>
                <AppTypography fontFamily={"Montserrat"} fontWeight={400} fontSize="14px">
                    Last updated on June 3, 2022
                </AppTypography>
            </HStack>
            <AppSeperator />
        </VStack>
    );
};

export default PolicyHeader;
