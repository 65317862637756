import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Link, useDisclosure, VStack } from '@chakra-ui/react'
import CustomIcon from 'components/common/customIcon/customIcon'
import React from 'react'
import { LuMenu } from 'react-icons/lu'
import navigationCasperModel from '../../../../parts/navigation/model'

function HeaderCasperDrawer() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Flex backgroundColor="#BF3B57" onClick={onOpen} height="31px" width="31px" alignItems="center" justifyContent="center" borderRadius="3px"><CustomIcon as={LuMenu} color="#333" w="25px" h='25px' /></Flex>
            <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent backgroundColor="#FFF" maxWidth="240px">
                    <DrawerHeader>Menu</DrawerHeader>
                    <DrawerBody>
                        <VStack align="stretch">
                            {navigationCasperModel.links.map((el, key) => <Link href={el.link} target="_blank" key={key}>{el.text}</Link>)}
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default HeaderCasperDrawer