import { ORDER_STATUS_ENUM } from "pages/_refactors/history/interface";

namespace purchaseHistoryOrderModalModel {
    const defaults = { title: "Order Submitted!", color: "#FFFFFF", description: "Transaction confirmation may take 1-2 minutes. Kindly wait while finalizing your order." };
    const canceled = { title: "Payment Failed!", color: "#FF665C", description: "Please check your payment details and try again. If you continue to experience issues, contact our support team for assistance. support@droplinked.com" };
    const success = { title: "Payment Successful!", color: "#09E187", description: "Your transaction has been confirmed" };

    export const getOrderStatusDesign = (status: string) => {
        let result
        switch (status) {
            case ORDER_STATUS_ENUM.PAYMENT_CONFIRMED:
                result = success
                break;
            case ORDER_STATUS_ENUM.PROCESSING:
            case ORDER_STATUS_ENUM.SENT:
                result = defaults
                break;
            case ORDER_STATUS_ENUM.WAITING_FOR_PAYMENT:
            case ORDER_STATUS_ENUM.WAITING_FOR_CONFIRMATION:
            case ORDER_STATUS_ENUM.INITIALIZED_FOR_PAYMENT:
            case ORDER_STATUS_ENUM.CANCELED:
            case ORDER_STATUS_ENUM.CANCELED_PAYMENT_TIMEOUT:
            case ORDER_STATUS_ENUM.REFUNDED:
            case ORDER_STATUS_ENUM.IN_CART:
                result = canceled
                break
            default: result = defaults
        }
        return result
    };
}

export default purchaseHistoryOrderModalModel