import { Box, Flex, Select } from '@chakra-ui/react';
import React from 'react'
import { useShopStore } from "lib/stores/shop/shop";
import { appDeveloment } from 'lib/utils/app/variables';

function AppDevBar() {
    const { shopData, setShopData } = useShopStore();

    const changeTheme = (theme: any) => {
        setShopData({ ...shopData, theme })
        window.location.reload()
    }

    return (
        <>
            {appDeveloment && shopData ? (
                <Flex backgroundColor={"#000"} width="100%" justifyContent={"center"}>
                    <Box>
                        <Select placeholder='Select theme' backgroundColor={"#000"} boxShadow={"none !important"} border={"none"} value={shopData.theme} color="#FFF" onChange={(e) => changeTheme(e.target.value)}>
                            <option value='theme-1'>theme-1</option>
                            <option value='theme-2'>theme-2</option>
                            <option value='theme-skale'>theme-Skale</option>
                            <option value='theme-unstoppable'>theme-unstoppable</option>
                            <option value='theme-casper'>theme-casper</option>
                            <option value='theme-polygon'>theme-polygon</option>
                        </Select>
                    </Box>
                </Flex>
            ) : null}
        </>
    )
}

export default AppDevBar