import { Hide } from '@chakra-ui/react';
import React from 'react'
import HeaderDesktop from './screens/desktop/HeaderDesktop';
import HeaderMobile from './screens/mobile/HeaderMobile';

function HeaderCasper() {
  return (
    <>
      <Hide below='md'><HeaderDesktop /></Hide>
      <Hide above='md'><HeaderMobile /></Hide>
    </>
  )
}

export default HeaderCasper