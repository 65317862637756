import { VStack } from "@chakra-ui/react";
import PolicyHeader from "./parts/header/PolicyHeader";
import PolicyContent from "./parts/content/PolicyContent";
import AppSeperator from "components/common/seperator/AppSeperator";

const PolicyPage = () => {
    return (
        <VStack >
            <PolicyHeader/>
            <PolicyContent />
        </VStack>
    );
};

export default PolicyPage;
