import { Flex, VStack, Icon, useDisclosure, FlexProps } from '@chakra-ui/react'
import AppButton, { IAppButton } from 'components/common/form/button/AppButton'
import AppModal from 'components/common/modal/AppModal';
import AppTypography from 'components/common/typography/AppTypography'
import { useToastify } from 'context/toastify/ToastifyContext';
import { useProfile } from 'hooks/useProfile/useProfile';
import useAppAddress from 'hooks/_refactor/address/useAppAddress';
import useAppCart from 'hooks/_refactor/cart/useAppCart';
import { useShopStore } from 'lib/stores/shop/shop';
import useAppStore from 'lib/stores/_refactor/cart/cartStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IoIosAdd } from "react-icons/io";
import checkoutAddress from './model';
import CheckoutFormAddress from './parts/address/form/CheckoutFormAddress';
import CheckoutListAddres from './parts/address/list/CheckoutListAddres';

interface IProps {
    onSuccess: Function
    buttons?: {
        props?: IAppButton,
        children: JSX.Element
    }[]
}
function CheckoutAddress({ onSuccess, buttons }: IProps) {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { profile } = useProfile()
    const [States, setStates] = useState({
        loading: false,
        address: ''
    })
    const { addToCart } = useAppCart()
    const { states: { cart } } = useAppStore()
    const { errorToast } = useToastify()
    const { themes } = checkoutAddress
    const { shopTheme } = useShopStore();

    const updatestate = (key: string, value: any) => setStates((prev: any) => ({ ...prev, [key]: value }))

    useEffect(() => { if (cart?.address?._id) updatestate('address', cart?.address?._id) }, [cart?.address])

    const submit = useCallback(async () => {
        try {
            updatestate('loading', true)
            if (!States.address.length) throw Error('Please enter address')
            if (profile && States.address !== cart?.address?._id) await addToCart({ field: 'address', value: States.address })
            updatestate('loading', false)
            onSuccess()
        } catch (error: any) {
            updatestate('loading', false)
            errorToast(error.message)
        }
    }, [States.address, profile, cart])

    const confirmAddress = useCallback(() => {
        onClose()
        if (!profile) onSuccess()
    }, [profile])

    const isGuest = useMemo(() => !profile && cart?.address?.addressLine1, [profile, cart])

    return (
        <VStack align="stretch" width="100%">
            {profile && <CheckoutListAddres onChange={(addressBookID) => updatestate('address', addressBookID)} value={States.address} />}
            <Flex flexDirection="column" gap="10px" {...themes[shopTheme]?.flex}>
                <Flex border="1px solid #444" onClick={onOpen} alignItems="center" cursor="pointer" borderRadius="8px" padding="15px" {...themes[shopTheme]?.addressBox}>
                    <Icon as={IoIosAdd} fill="#eee" width="22px" height="22px" />
                    <AppTypography fontSize="14px">{isGuest ? `${cart?.address.addressLine1} ${cart?.address.country} ${cart?.address.state} ${cart?.address.city} ${cart?.address.zip}` : 'Add new address'}</AppTypography>
                </Flex>
                <Flex justifyContent="space-between" direction="row-reverse" gap="20px">
                    <AppButton {...themes[shopTheme]?.buttons} isLoading={States.loading} onClick={submit}>Submit</AppButton>
                    {buttons ? buttons.map((e, key) => <AppButton key={key} {...themes[shopTheme]?.buttons} {...e.props}>{e.children}</AppButton>) : null}
                </Flex>
            </Flex>
            <AppModal isOpen={isOpen} title="New Address" onClose={onClose} props={{ modal: { isCentered: true, size: "xl" } }}>
                <CheckoutFormAddress onCancel={onClose} onSuccess={confirmAddress} />
            </AppModal>
        </VStack>
    )
}

export default CheckoutAddress