import { createContext } from "react";
import { ORDER_STATUS_ENUM } from "../history/interface";

export enum CHAIN_TYPE {
    CASPER = "CASPER",
    POLYGON = "POLYGON",
    BINANCE = "BINANCE",
    STACKS = "STACKS",
    XRPLSIDECHAIN = "XRPLSIDECHAIN",
    NEAR = "NEAR",
    SKALE = "SKALE",
    BASE = "BASE",
    LINEA = "LINEA",
    XUMM = "XUMM",
    STRIPE = "STRIPE",
}

export enum PAYMENT_TYPE {
    CRYPTO = "CRYPTO",
    FIAT = "FIAT",
    STRIPE = "STRIPE",
}

interface Item {
    _id: string;
    productId: string;
    title: string;
    image: string;
    size: string;
    color: string;
    quantity: number;
    price: number;
    shippingType: string;
}

interface Details {
    orderId: string;
    shipping: number;
    items: number;
    tax: number;
    totalCost: number;
    status: ORDER_STATUS_ENUM;
    deployHash: string;
    paymentType: PAYMENT_TYPE;
    chain: CHAIN_TYPE;
    address: string;
    giftCard: any
}

interface IProps {
    order: { items: Item[]; details: Details };
}

const initialProps = {
    items: [],
    details: { orderId: "", shipping: 0, items: 0, tax: 0, totalCost: 0, status: ORDER_STATUS_ENUM.IN_CART, deployHash: "", paymentType: PAYMENT_TYPE.STRIPE, chain: CHAIN_TYPE.STRIPE, address: "", giftCard: null },
};

const paymentSuccessContext = createContext<IProps>({
    order: initialProps,
});

export default paymentSuccessContext;
