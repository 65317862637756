import { Flex, FlexProps } from '@chakra-ui/react'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'
import React from 'react'

interface IProps {
    children: any
    props?: FlexProps
    mini?: boolean
}
function AppContainer({ children, props, mini }: IProps) {
    const { isDefaultTheme } = useAppTheme()

    return (
        <Flex width="95%" maxWidth={mini ? "700px" : isDefaultTheme ? "1200px" : "1400px"} {...props}>{children}</Flex>
    )
}

export default AppContainer