import { WALLET_TYPES } from "lib/constant/wallet-types"; 
import { createApiReq } from "./api-utils";


export const postLogin = (
  wallet?: string,
  publicKey?: string,
  signature?: string,
  walletType?: string,
  customerShop?:string,
  nonce?: string
) => {
  const body = {
    wallet: wallet,
    publicKey: publicKey,
    signature: signature,
    type: walletType,
    customerShop: customerShop,
    ...(walletType === WALLET_TYPES.UNSTOPPABLEDOMAIN && { nonce: nonce }),
  };
  return createApiReq("auth/wallet", false, body);
};
