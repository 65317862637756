import { Button, Flex, VStack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import React from "react";
import { Link } from "react-router-dom";

const CartPageEmpty = () => {
    const { route } = useCustomNavigate()

    return (
        <VStack w="full" align={'center'} gap={"16px"} mt={'56px'}>
            <AppTypography fontSize="26px" fontWeight="700">
                Your Cart is Empty
            </AppTypography>
            <Link to={route('')}>
                <Button>Continue Shopping</Button>
            </Link>
        </VStack>
    );
};

export default CartPageEmpty;
