import { Box, Link, chakra, Flex } from "@chakra-ui/react";
import { useShopStore } from "lib/stores/shop/shop";
import React, { useMemo } from "react";
import privacyModel from "../../model";

const PrivacyContent = () => {
    const shop = useShopStore();
    const { links, texts } = useMemo(() => privacyModel.getColors(shop.shopTheme, shop.shopData), [shop.shopTheme, shop.shopData]);
    return (
        <Box height={"auto"} mt={26} mb="100px" overflowY="scroll">
            <Flex display={"flex"} justifyContent="flex-start" flexDirection={"column"} gap={16} color={texts}>
                <chakra.section id="summary">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Please read these terms very carefully as they form a binding Agreement between you and Printful regarding the use of our
                        services and website. At the beginning of each Section, you'll find a short summary to help you navigate the document. Note that these summaries do not replace or represent the
                        full text.
                        <br />
                        <br />
                        The following terms and conditions constitute a legally binding contract (this "Agreement") between you (“you” or “your”) and Printful, Inc., a Delaware corporation that
                        governs all use by you of the printful.com website (the "Site") and the services available on or at the Site (taken together with the use of the Site, the "Services"). We'll
                        refer to Printful and all of its subsidiaries and affiliated companies collectively as "Printful", "we", "our", "us", etc. Printful is a print-on-demand company for businesses
                        that want to outsource the printing and delivering component. Printful white-label prints and dropships products ("Products") directly to you and your customers ("Customers").
                        <br />
                        <br />
                        The Services are offered subject to your acceptance without modification of all of the terms and conditions contained herein. We also have other policies and procedures
                        including, without limitation,{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/shipping" target={"_blank"} color={links} textDecor={"underline"}>
                                Shipping
                            </Link>
                        </chakra.span>
                        ,{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/policies/returns" target={"_blank"} color={links} textDecor={"underline"}>
                                Return Policy
                            </Link>
                        </chakra.span>
                        ,{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/policies/privacy" target={"_blank"} color={links} textDecor={"underline"}>
                                Privacy Policy
                            </Link>
                        </chakra.span>{" "}
                        and others. Those policies contain additional terms and conditions, which apply to the Services and are part of this Agreement.
                        <chakra.span fontWeight={700}>
                            {" "}
                            YOUR USE OF THE SITE CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO BE BOUND BY THIS AGREEMENT. FURTHERMORE, BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THE SITE,
                            YOU ACCEPT AND ARE BOUND BY THIS AGREEMENT.
                        </chakra.span>
                        If you do not agree to this Agreement, do not use the Site or any other Services.
                        <br />
                        <br />
                        If you use our Services only for your personal use, you are considered a "User". If you use our Services to execute orders or deliver Products to third parties (including
                        Customers), you are considered a "Merchant".{" "}
                        <chakra.span fontWeight={700}>
                            REGARDLESS IF YOU ARE A USER OR MERCHANT, SECTION 18 OF THIS AGREEMENT REQUIRES THAT ALL DISPUTES (AS DEFINED BELOW) ARISING FROM OR RELATING TO THIS AGREEMENT BE RESOLVED
                            BY ARBITRATION ON AN INDIVIDUAL BASIS, RATHER THAN BY JURY TRIAL OR CLASS ACTIONS, EXCEPT AS OTHERWISE PROVIDED BY SECTION 18. IF YOUR COUNTRY OF RESIDENCE IS IN THE
                            EUROPEAN ECONOMIC AREA OR THE UNITED KINGDOM THIS APPLIES TO ANY ACTION YOU MAY WANT TO BRING AGAINST PRINTFUL IN THE UNITED STATES.
                        </chakra.span>
                    </chakra.article>
                </chakra.section>
                <chakra.section id="access">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>1. Access & Membership</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>You need to be of a certain legal age to use Printful, and you're fully responsible for your account and the use of our
                        services. If you violate our Terms of Service or otherwise act in bad faith, we can modify, cancel, or refuse service at any time.
                        <br />
                        <br />
                        In order to enjoy all of Printful's benefits, you may register your account and become a member ("Member"). Membership requires that you register on the Site (including by
                        truthfully filling out all required personal information). You may opt out of marketing and promotional activities, including emails. You may cancel your membership at any time
                        by canceling it online on the Site. To complete registration, you shall provide a name, surname, email address and password. You may never use another user's Printful account
                        without permission from that user. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure. You must notify
                        Printful immediately of any breach of security or unauthorized use of your account. Although Printful will not be liable for your and your Customer’s losses caused by any
                        unauthorized use of your account, you may be liable for the losses of Printful and others due to such unauthorized use.
                        <br />
                        <br />
                        Printful may change, suspend or discontinue the Services, Products, fees, charges, terms at any time, including the availability of any feature or content, but without
                        affecting orders for European Economic Area Users or the United Kingdom which Printful has already confirmed. Printful may also impose limits on certain features and Services
                        or restrict the User's access to parts or all of the Services without notice or liability. If you are an individual (rather than an organization or entity), then you certify to
                        Printful that you are at least 18 years of age, however, if you are an individual residing in Japan, you certify to Printful that you are at least 20 years of age. If you are
                        using the Services on behalf of an organization or entity then you certify that you are legally authorized and permitted to bind such organization or entity to this Agreement
                        and use the Services. Notwithstanding the aforementioned, you agree to take full responsibility for your selection and use of the Services. This Agreement is void where
                        prohibited by law, and the right to access or use the Services is revoked in such jurisdictions.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="modifications">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>2. Modifications</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>We reserve the right to modify our terms, services, and fees. Please follow changes to our terms by checking in with the
                        Policy updates section on our website. Without affecting orders for European Economic Area Users or orders for Users in the United Kingdom which Printful has already confirmed
                        Printful reserves the right, at its sole discretion, to modify this Agreement and its terms, as well as the fees and other charges for our Services, at any time. All such
                        modifications will become effective immediately after Printful posts them on the Site. You shall be responsible for reviewing and becoming familiar with all such modifications,
                        and you agree to review the terms of this Agreement each time you access or use the Site so that you are aware of any modifications made to this Agreement. Use of the Site and
                        other Services by you after Printful posts the modifications on the Site constitutes your acceptance of the terms and conditions of this Agreement, as modified. If you do not
                        agree to the modified terms, you are not authorized to access or use the Site or other Services, and you must send Printful a written notification, including via email (and
                        your Printful account will be deleted), to close your account within 30 days of notice.
                        <br />
                        <br />
                        In order to enjoy all of Printful's benefits, you may register your account and become a member ("Member"). Membership requires that you register on the Site (including by
                        truthfully filling out all required personal information). You may opt out of marketing and promotional activities, including emails. You may cancel your membership at any time
                        by canceling it online on the Site. To complete registration, you shall provide a name, surname, email address and password. You may never use another user's Printful account
                        without permission from that user. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure. You must notify
                        Printful immediately of any breach of security or unauthorized use of your account. Although Printful will not be liable for your and your Customer’s losses caused by any
                        unauthorized use of your account, you may be liable for the losses of Printful and others due to such unauthorized use.
                        <br />
                        <br />
                        Printful may change, suspend or discontinue the Services, Products, fees, charges, terms at any time, including the availability of any feature or content, but without
                        affecting orders for European Economic Area Users or the United Kingdom which Printful has already confirmed. Printful may also impose limits on certain features and Services
                        or restrict the User's access to parts or all of the Services without notice or liability. If you are an individual (rather than an organization or entity), then you certify to
                        Printful that you are at least 18 years of age, however, if you are an individual residing in Japan, you certify to Printful that you are at least 20 years of age. If you are
                        using the Services on behalf of an organization or entity then you certify that you are legally authorized and permitted to bind such organization or entity to this Agreement
                        and use the Services. Notwithstanding the aforementioned, you agree to take full responsibility for your selection and use of the Services. This Agreement is void where
                        prohibited by law, and the right to access or use the Services is revoked in such jurisdictions.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="content">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>3. Content</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Printful respects intellectual property rights and asks you to do the same. Anything you upload will remain yours, and
                        you're responsible for it. We'll only use the content you submit to provide you with our services. If we receive a Digital Millennium Copyright Act (DMCA) takedown notice for
                        content you've submitted, we may remove the allegedly infringing content or suspend access to the Printful website.
                        <br />
                        <br />
                        All content (including all information, images, pictures, data, text, photographs, graphics, messages, and other materials, hereinafter "Content") that you post, submit,
                        upload, display, sell or use, hereinafter "post", using our Services is your content. We don’t make any claim(s) to it. That includes anything you post using our Services (like
                        your Content, images, shop name, your customer reviews, comments, videos, usernames, etc.).
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Responsibility for the Content.</chakra.span> You understand that you are solely responsible for the Content that you post on or through the
                        Services. You represent that you have all necessary rights to the Content, including all necessary rights to post it or use it on your Products sold, manufactured, or
                        warehoused by Printful. You also represent that you’re not infringing or violating any third party’s rights by posting the Content or using the Content on your Products sold,
                        manufactured, or warehoused by Printful.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Permission to Use the Content.</chakra.span> Printful agrees that any Content that you post using our Services will remain yours. This means that
                        we will never use your Content except with your express permission or as otherwise provided in this Agreement.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Rights You Grant Printful.</chakra.span> By posting your Content, you grant Printful a non-exclusive, worldwide, royalty-free, irrevocable,
                        sub-licensable, perpetual license to use, display, edit, modify, reproduce, distribute, store, and prepare derivative works of your Content to provide the Services and to
                        promote Printful and/or your Printful store, or the Services in general, in any formats and through any channels, including across the Services, third-party website,
                        advertising medium and/or social media. You agree and represent that you have the rights to grant this license to us.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Reporting Unauthorized Content.</chakra.span> Printful respects intellectual property rights and follows intellectual property laws. We are
                        committed to following appropriate legal procedures to remove infringing content from the Service(s).
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Intellectual Property.</chakra.span> Printful respects your work and empowers you to express your voice and ideas. We ask that you respect the
                        work and creative rights of others. You need to either own the Content you post to Printful, or have the express authority to post it. Content must comply with right of
                        publicity, trademark and copyright laws, and all other applicable national, state, and federal laws.
                        <br />
                        <br />
                        Trademarks and Infringement of Intellectual Property. We are committed to following appropriate legal procedures to remove infringing content from the Services. Printful will
                        try to accommodate and not interfere with standard technical measures used by copyright owners to identify and protect their works. Printful reserves the right to do any or all
                        of the following at any time at our sole discretion: (i) immediately suspend your use of the Services; and/or (ii) remove, block, and disable access to any of your Content that
                        is alleged to infringe the intellectual property rights of others. When we receive notice of intellectual property infringement (via the{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/policies/dmca" target={"_blank"} color={links} textDecor={"underline"}>
                                form available here
                            </Link>
                        </chakra.span>
                        ), Printful strives to respond quickly by removing, blocking, or disabling access to the allegedly infringing material. When Printful removes, blocks or disables access in
                        response to such a notice, Printful makes a reasonable attempt to contact the allegedly infringing party, provide information about the notice and removal, and, in cases of
                        alleged copyright infringement, provide information about counter notification.
                        <br />
                        <br />
                        a.{" "}
                        <chakra.span fontWeight={700}>
                            DMCA Cooperation: Printful's copyright infringement notice and Content removal policies and procedures are intended to comply with the requirements and obligations of
                            service providers under the Digital Millennium Copyright Act (DMCA). To the extent any requirements and obligations of service providers under the DMCA change or are
                            different than set forth herein, Printful's policies and procedure may likewise change in accordance with the DMCA requirements and obligations of service providers. More
                            information about DMCA procedure is available
                        </chakra.span>{" "}
                        <chakra.span fontWeight={700}>
                            <Link href="https://www.printful.com/policies/dmca" color={links} textDecor={"underline"} target={"_blank"}>
                                here.
                            </Link>
                        </chakra.span>
                        <br />
                        <br />
                        b. <chakra.span fontWeight={700}>Counter DMCA Notifications:</chakra.span> If Printful receives a DMCA counter notification, Printful will send a copy of the counter notice to
                        the original complaining party. Unless the copyright owner files an action seeking a court order against the allegedly infringing party and informs Printful of this action, the
                        removed, blocked, or disabled material may be replaced or access to it may be restored 10-14 business days after delivery of the counter notice to the original complaining
                        party. If an action is filed, any Content removed, blocked, or disabled shall remain removed, blocked or disabled at the sole discretion of Printful.
                        <br />
                        <br />
                        c. <chakra.span fontWeight={700}>Repeat Infringement:</chakra.span> Printful may terminate account privileges of Members that are subject to repeat notices of intellectual
                        property infringement as determined by Printful at its sole discretion.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Copyright and Trademark Responsibility.</chakra.span> You agree and represent by accepting this Agreement and using our Services that you own all
                        rights (including copyrights) for the Content you post, or if you are not the owner, that you have permission to use and reproduce the Content in connection with the Services,
                        and that you have all of the rights required to post your Content. Likewise, by accepting this Agreement and using our Services, you agree and represent that you own or have
                        permissions to use all copyrights, trademarks, service marks, trade dress, and trade names incorporated into the Content you post or use in connection with any Content and the
                        Services provided under this Agreement.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Inappropriate, False, or Misleading Content.</chakra.span> This should be common sense, but there are certain types of Content we don’t want
                        submitted to our Services (for legal reasons or otherwise). You agree that you will not post any content that is abusive, threatening, defamatory, obscene, vulgar, illegal or
                        otherwise offensive or in violation of any part of this Agreement. You also agree not to post any Content that is false or misleading or uses the Services in a manner that is
                        fraudulent or deceptive.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="use-services">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>4. Use of Services</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>By using Printful, you agree to use our services according to our terms and to respect the rights of our brand name,
                        trademark, and digital items. In case we need to contact you, we'll reach out to you via email. We grant you a limited, non-exclusive, non-transferable, and revocable license
                        to use our Services, platforms, and integrations subject to this Agreement and the following restrictions in particular:
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Don’t Use Our Services to Break the Law.</chakra.span> You agree that you will not violate any laws in connection with your use of the Services.
                        This includes any local, state, federal, and international laws that may apply to you or Printful. For example, it’s your responsibility to obtain any permits or licenses that
                        your store may require; you must not engage in fraud, theft, anti-competitive conduct, threatening conduct, or any other unlawful acts or crimes against Printful, another
                        Printful user, or a third party. You also agree that you are not the target of trade, financial, and economic sanctions, and that you do not appear on a sanctions-related list,
                        including lists maintained by the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), the U.S. Department of State, the U.S. Department of Commerce, the
                        European Union, or Her Majesty’s Treasury of the United Kingdom. You also agree that you will not export, reexport, or otherwise transfer Printful’s Products to countries or
                        territories that are the target of comprehensive embargoes or sanctions or parties on the sanctions-related lists referenced above. You agree not to use the Services: (i) to
                        impersonate or attempt to impersonate Printful, any other Printful Party (as defined below), or any other person or entity; or (ii) for the purpose of exploiting, harming, or
                        attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise. The following are collectively referred to as the “Printful Parties”:
                        (a) Printful, (b) Printful’s subsidiaries, affiliated companies, and joint ventures, and (c) the officers, directors, members, managers, equity holders, agents, and employees
                        of Printful and its subsidiaries, affiliated companies, and joint ventures.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Don’t Try to Harm Our Systems.</chakra.span> You agree not to interfere with or try to disrupt our Services, for example by distributing a virus
                        or other harmful computer code into our platforms, third party services, or other programs or systems our clients may use to promote their Products. You agree not to use the
                        Services: (i) to send, knowingly receive, upload, download, use, or re-use any material which does not comply with this Agreement; or (ii) to engage in any other conduct that
                        restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm any Printful Party or expose any of them to liability. You agree that
                        Printful reserves the right to audit our platforms (systems) to ensure integrity and compliance with this Agreement, at the sole discretion of Printful.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Follow Brand Guidelines.</chakra.span> The name “Printful”, our iconography, phrases, logos, and designs that we use in connection with the
                        Products or Services we provide are trademarks, service marks, or trade dress of Printful in the US, European Union, and all other countries, that are used for proprietary
                        purposes at our sole discretion. Except as expressly provided in this Agreement, Printful does not grant you any rights to use its trademarks, service marks, or trade dress.
                        You may state in connection with the Services or Products provided by Printful that Printful provided such Services or Products. If you use any of our trademarks in reference
                        to our Products or Services, you must include a statement attributing that trademark to us. You must not use any of our trademarks: (i) in or as the whole or part of your own
                        trademarks; (ii) in connection with activities, Products or Services which are not ours; (iii) in a manner which may be confusing, misleading or deceptive; or (iv) in a manner
                        that disparages us or our information, Products or Services (including the Site). Printful reserves the right to request you to immediately remove any such misused iconography,
                        phrases, logos, and designs at our sole discretion.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Share Your Ideas.</chakra.span> We love your suggestions and ideas! They can help us improve your experience and our Services. Any unsolicited
                        ideas or other materials you submit to Printful (not including your Content or Products you sell or warehouse through our Services) are considered non-confidential and
                        nonproprietary to you. By submitting those ideas and materials to us, you grant us a non-exclusive, worldwide, royalty-free, non-revocable, sub-licensable, perpetual license to
                        use and publish those ideas and materials for any purpose, without compensation to you at any time.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Communication Methods.</chakra.span> Printful will provide you with certain legal information in writing. By using our Services, you’re agreeing
                        to our communication methods which describe how we provide that information to you. This simply means that we reserve the right to send you information electronically (by
                        email, etc.) instead of mailing you paper copies (it’s better for the environment).Under California Civil Code Section 1789.3, Users from the State of California are entitled
                        to the following specific consumer rights notice:The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be
                        contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210. You may contact us at:Printful,
                        Inc.11025 Westlake Dr., Charlotte, NC 28273support@printful.com
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Digital Items.</chakra.span> Digital items (like mockups, templates, images and other design assets) and texts created in connection with the
                        Products and/or Services we offer and their intellectual property rights belong exclusively to Printful. Digital items and any results may only be used in connection with the
                        advertising, promoting, offering and sale of Printful’s Products and may not be used for other purposes or in conjunction with products from other manufacturers. If Printful
                        provides the possibility for Users to modify or customize any Digital Items, you will ensure that the Content used to modify such Digital Items will comply with the
                        intellectual property laws and our Acceptable Content guidelines.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="content-services">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>5. Content and Services</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>You are responsible for communicating with your customers and handling any claims they might have. We provide the best
                        service we can on an "as is" basis, and please be aware that it may have errors and interruptions.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Items Your Clients Purchase Through Our Sites.</chakra.span> You understand that Printful cannot and does not make any warranties about your
                        Content, stores, or what you sell to your Customer that we may warehouse. Any legal claim related to a Product your Customers purchase must be brought directly against you as
                        the seller of the Product You release Printful from any claims related to Products sold by you through our Services, including claims for misrepresentations by you to your
                        Customers or claims for physical injury or property damage by any of your Products that are warehoused for you by Printful.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Content You Access.</chakra.span> Your Customers may come across materials that they find offensive or inappropriate while using our Services. We
                        make no representations concerning any content posted by others through the Services. Printful is not responsible for the accuracy, copyright compliance, legality, or decency
                        of content posted by others that you accessed through the Services. You release us from all liability relating to that content.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Third-Party Services.</chakra.span> Our stores on our Site(s) may contain links to third-party websites or services that we don’t own or control
                        (for example, links to Facebook, Twitter, and Pinterest). You may also need to use a product or service of one of our subcontractors or other third parties in order to use some
                        of our Services (like a compatible mobile device to use our mobile apps). When you access these third-party services, you do so at your own risk. The third parties may require
                        you to accept their own terms of use. Printful is not a party to those agreements; they are solely between you and the third party. You agree that Printful will not be liable
                        to you in any way for your use of these third party services.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Services.</chakra.span> Printful is dedicated to making our Services the best they can be, but we’re not perfect and sometimes things can go
                        wrong. You understand that our Services are provided “as is,” with all faults and without any kind of warranty (express or implied), except for the warranties provided with
                        respect to Products in Section 6 below.{" "}
                        <chakra.span fontWeight={700}>
                            TO THE FULLEST EXTENT PERMITTED BY LAW, WE ARE EXPRESSLY DISCLAIMING ANY AND ALL WARRANTIES OR CONDITIONS OF NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
                            PURPOSE, AS WELL AS ANY WARRANTIES OR CONDITIONS IMPLIED BY A COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE, WITH RESPECT TO OUR SERVICES, EXCEPT FOR THE
                            WARRANTIES OR CONDITIONS PROVIDED WITH RESPECT TO PRODUCTS IN SECTION 6 BELOW. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR CONDITIONS, SO THE ABOVE
                            LIMITATIONS MAY NOT APPLY TO YOU.
                        </chakra.span>{" "}
                        We do not guarantee that:
                        <br />
                        <br />
                        a. the Services will be secure or available at any particular time or location – Printful shall not be liable for any delays, interruptions or loss of data in connection with
                        the use of our Site and Services. Printful shall strive to ensure that the Services are carried out in other locations in case it is not possible to carry them out at the
                        location the Services were initially intended to be performed at. 
                        <br />
                        <br />
                        b. any errors for which Printful is responsible will be corrected;
                        <br />
                        <br />
                        c. the Services will always be free of viruses or other harmful materials; or
                        <br />
                        <br />
                        d. the results of using the Services will meet your expectations. You use the Services solely at your own risk.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>
                            LIABILITY LIMITS. TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER PRINTFUL NOR ANY OTHER PRINTFUL PARTY SHALL BE LIABLE TO YOU OR YOUR CUSTOMERS FOR ANY LOST PROFITS OR
                            REVENUES, DIMINUTION IN VALUE, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY, ENHANCED, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THE SERVICES
                            OR THIS AGREEMENT, REGARDLESS OF (I) WHETHER ANY OF THE FOREGOING DAMAGES WERE FORESEEABLE, (II) WHETHER OR NOT YOU WERE ADVISED OF THE POSSIBILITY OF INCURRING ANY OF THE
                            FOREGOING DAMAGES, (III) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, OR OTHERWISE) UPON WHICH THE CLAIM IS BASED, AND (IV) ANY SPECIFIC CIRCUMSTANCES OF YOU AND/OR YOUR
                            CUSTOMER. THE LIABILITY OF THE PRINTFUL PARTIES WILL UNDER NO CIRCUMSTANCES EXCEED THE ACTUAL AMOUNT PAID BY YOU FOR THE SERVICE THAT YOU HAVE PURCHASED OR USED THROUGH THE
                            SITE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL, CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT
                            APPLY TO YOU.
                        </chakra.span>
                    </chakra.article>
                </chakra.section>
                <chakra.section id="limited">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>6. Limited Warranty on Products</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Please read this section very carefully—it covers our obligations and responsibilities for Printful services. If you have
                        an issue with your order, you may be eligible for a replacement product or refund, so reach out to us as soon as possible.
                        <br />
                        <br />
                        Does not apply to Users residing in the European Economic Area or the United Kingdom (see Section 7).
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Limited Warranty.</chakra.span> We warrant that, at the time of delivery of a Product to a User or the Merchant’s Customer, the Product will not
                        be materially defective or damaged (the “Limited Warranty”). Customers residing in Brazil have the right to cancel and return their order within 7 days, for any reason.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>Who May Use This Warranty?</chakra.span> Printful extends the Limited Warranty only to Users and Merchants. All Limited Warranty coverage
                        terminates if the User or the Merchant’s Customer sells or otherwise transfers a Product.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>What Does This Warranty Not Cover?</chakra.span> This Limited Warranty does not cover any damage to or defect in a Product caused by any of the
                        following: (a) you or third parties; (b) any improper handling (including during shipping), use or storage of the Product; (c) any failure to follow any Product instructions;
                        (d) any modifications to the Product; (e) any unauthorized repair to the Product; or (f) any external causes such as accidents, fire, flood, “acts of God” or other actions or
                        events beyond our reasonable control; or (g) any costs or expenses related to the loss of use of the Product or any other costs or expenses not covered by this Limited
                        Warranty. This Limited Warranty does not cover any items supplied by third parties, size exchanges or buyer’s remorse.
                        <br />
                        <br />
                        Finally, as noted elsewhere in this Agreement, we unfortunately cannot guarantee that the colors and details in our website images are 100% accurate representations of a
                        Product, and sizes might in some cases be approximate. Accordingly, this Limited Warranty does not cover such matters.
                        <br />
                        <br />
                        What Is The Period Of Coverage? This limited warranty starts on the date of the delivery of the Product to the User or the Merchant’s Customer and lasts for thirty (30) days
                        (the “Warranty Period”). For the avoidance of doubt, Printful acknowledges that according to some jurisdictions the User may be entitled to a longer Warranty Period.
                        <br />
                        <br />
                        What Are Your Remedies Under This Warranty? With respect to any materially defective or damaged Product, we will, in our sole discretion, either: (a) replace such Product (or
                        the defective or damaged part of the Product) free of charge, or (b) refund the purchase price paid to us by the User or the Merchant along with the shipping fees corresponding
                        to the defective or damaged Products.
                        <br />
                        <br />
                        How Do You Obtain Warranty Service? Promptly following delivery of a Product, you or your Customer (if you are a Merchant) will inspect the Product. If a Product is materially
                        defective (including any error by Printful in printing) or damaged upon receipt, or if you received the wrong Product, then in order to be eligible for service under this
                        Limited Warranty, you must submit a claim within the Warranty Period in compliance with our Return Policy. If you are a Merchant, your Customer should contact you regarding any
                        defective or damaged Product and with respect to any delivery of the wrong Product.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>
                            LIMITATION OF LIABILITY. THE REMEDIES DESCRIBED IN THIS SECTION 6 ARE YOUR SOLE AND EXCLUSIVE REMEDIES (AND OUR ENTIRE LIABILITY) FOR ANY BREACH OF THIS LIMITED WARRANTY.
                            OUR LIABILITY FOR A DEFECTIVE OR DAMAGED PRODUCT SHALL UNDER NO CIRCUMSTANCES EXCEED THE ACTUAL AMOUNT RECEIVED BY US FOR THE PRODUCT, NOR SHALL WE UNDER ANY CIRCUMSTANCES,
                            TO THE FULLEST EXTENT PERMITTED BY LAW, BE LIABLE FOR ANY LOST PROFITS OR REVENUES, DIMINUTION IN VALUE, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY,
                            ENHANCED OR PUNITIVE DAMAGES OR LOSSES ARISING OUT OF OUR PRODUCTS, REGARDLESS OF (I) WHETHER ANY OF THE FOREGOING DAMAGES WERE FORESEEABLE, (II) WHETHER OR NOT YOU WERE
                            ADVISED OF THE POSSIBILITY OF INCURRING ANY OF THE FOREGOING DAMAGES, (III) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, OR OTHERWISE) UPON WHICH THE CLAIM IS BASED, AND
                            (IV) ANY SPECIFIC CIRCUMSTANCES OF YOU AND/OR YOUR CUSTOMER. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
                            ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. THIS LIMITED WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS AND YOU MAY ALSO HAVE OTHER RIGHTS, WHICH VARY FROM JURISDICTION
                            TO JURISDICTION. WE LIMIT THE DURATION AND REMEDIES OF ALL IMPLIED WARRANTIES OR CONDITIONS WITH RESPECT TO OUR PRODUCTS, INCLUDING WITHOUT LIMITATION THE WARRANTIES OR
                            CONDITIONS OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TO THE DURATION OF THE WARRANTY PERIOD. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN
                            IMPLIED WARRANTY OR CONDITION LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                        </chakra.span>
                    </chakra.article>
                </chakra.section>
                <chakra.section id="rights">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>7. Your rights in the case of lack of conformity</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>If you reside in the European Economic Area or the United Kingdom, this is where you'll find information on what you can do
                        if there's an issue with your order.
                        <br />
                        <br />
                        If you are a User residing in the European Economic Area or the United Kingdom, the legal guarantees established by Directive 1999/44/EC on consumer sales and guarantees or
                        similar consumer contracts regulations apply to the sale of our products. Consequently, you have the right, free of charge, to get the products repaired (or replaced) within
                        the limits provided by the law, or, in case of failure of one of the remedies above to have an appropriate reduction in the price of the products, or the contract cancelled.
                        Delivery costs for returning the product to be repaired or replaced under this clause shall be borne by Printful, as well as any costs related to the delivery to you of the
                        repaired or replaced product.
                        <br />
                        <br />
                        If the Products we supply to you are: (1) made to your specification and are clearly personalized with your designs; or (2) sealed for health protection or hygiene reasons, or
                        both, you do not, have the right to cancel your order or change your mind as set out in our{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/policies/returns " target={"_blank"} color={links} textDecor={"underline"}>
                                Return Policy.
                            </Link>
                        </chakra.span>
                        <br />
                        <br />
                        For other Products, you have the right to cancel and return the order for any reason within 14 days following the date on which the Product was delivered. You must inform us of
                        the decision to cancel the order by sending us written notice before the cancelation period expires. You may contact us at{" "}
                        <chakra.span>
                            <Link href="mailto:support@printful.com" target={"_blank"} color={links} textDecor={"underline"}>
                                support@printful.com
                            </Link>
                        </chakra.span>{" "}
                        . Within 14 days of notifying us of your decision to cancel the order, you must also return the product to us using the return address indicated on the original package in
                        which the order was delivered. You will have to bear the direct cost of returning the goods. Within 14 days of receiving written notice of your decision to cancel the order we
                        will, without undue delay, issue a refund for the Product and the delivery costs (with the exception of the supplementary costs resulting from your choice of a type of delivery
                        other than the least expensive type of standard delivery offered by us). We may delay the refund until the Product has been returned or we have received proof that the Product
                        has been returned, whichever is earlier. The refund will be made to your Printful Wallet or to the payment method used by you when placing the order. You may be liable for any
                        diminished value of the Product resulting from the handling other than what is necessary to establish the nature, characteristics and functioning of the Product.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="responsibility">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>8. Responsibility of Site members and visitors</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>When using Printful services, it's your responsibility to follow our policies. If you violate our policies, we might
                        restrict or even end up terminating your access to our services. Violation of this Agreement or any other rules will result in the termination of your Printful account.
                        Printful and its designees shall have the right (but not the obligation) in their sole discretion to pre-screen, refuse, or remove any Content that is available via the
                        Services. Without limiting the foregoing, Printful and its designees shall have the right to remove, block, and disable any Content. You agree that you must evaluate and to the
                        extent permitted by law bear all risks associated with the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content. In this
                        regard, you acknowledge that you may not rely on any Content created by Printful or submitted to Printful, including without limitation information in Printful collaborations,
                        posts and in all other parts of the Printful Services.
                        <br />
                        <br />
                        Without limiting other remedies, we may limit, suspend or terminate our Services and your account, prohibit access to our Site, delay, remove, block, or disable hosted Content,
                        and take technical and legal steps to keep you off the Site if we think that you are creating problems, causing possible legal liabilities, breaching this Agreement, or acting
                        inconsistently with the letter or spirit of our policies. We also reserve the right to cancel unconfirmed accounts or accounts that have been inactive for extended periods of
                        time. You are responsible for providing Printful with accurate and truthful information (including but not limited to your name and surname if you are a User or retail price if
                        you are a Merchant). If you have provided Printful with inaccurate or false information, (a) you shall be liable to Printful for damages and losses (including but not limited
                        to taxes) arising out of such inaccurate or false information, (b) you shall reimburse such damages and losses (including but not limited to taxes) to Printful, and (c)
                        Printful shall have the right to charge you for such damages and losses (including but not limited to taxes) and otherwise limit or suspend your access to the Services.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="payments">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>9. Payments and fees</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>To pay for Printful services, you need a valid payment method (e.g. a credit card, PayPal) that you're authorized to use.
                        All fees will be charged to your payment method, which may but is not guaranteed to be automatically updated. Note that you might need to reimburse us for any chargeback fees
                        for returns or claims that aren't in line with our policies.
                        <br />
                        <br />
                        You may choose to save your billing information to use it for all future orders and charges associated with Printful Products and/or Services. In such case, you also
                        acknowledge and agree that this information will be stored and processed by third party PCI DSS compliant service providers. Printful may participate in Account Updater
                        programs. Such programs may allow for your payment card information to be automatically updated if and when there is a change, such as to the payment card number or expiration
                        date. To the extent Printful participates in such programs and your financial institution or payment card provider is a participant, you agree to automatic updates to your
                        payment card information. However, Printful cannot guarantee that such automatic updates will be made, so you also acknowledge that you are ultimately responsible for ensuring
                        that your payment card information is current.
                        <br />
                        <br />
                        When you order a Product, or use a Service that has a fee, you will be charged, and you agree to pay, the fees in effect at the time the order is placed. We may change our fees
                        from time to time (for example, when we have holiday sales, offer you a discount of base product prices, etc.). The fees for the Products and the Services (if and as
                        applicable), as well as any associated delivery costs will be indicated on the Site when you place an order or pay for the Service. We may choose to temporarily change the fees
                        for our Services for promotional events or new Services, and such changes are effective when we post the temporary promotional event or new Service on the Site or inform you
                        individually. The sale will be submitted for processing and you will be charged as soon as you confirm it. You may then receive an email from us.
                        <br />
                        <br />
                        By placing an order or paying for Services , you are confirming that you are legally entitled to use the means of payment tendered and, in the case of card payments, that you
                        are either the cardholder or have the cardholder's express permission to utilize the card to effect payment. In case of an unauthorized use of a payment method, you will be
                        personally liable for, and shall reimburse Printful for damages resulting from such unauthorized use.
                        <br />
                        With regard to payment methods, you represent to Printful that (i) the billing information you supply to us is true, correct, and complete and (ii) to the best of your
                        knowledge, charges incurred by you will be honored by your financial institution (including but not limited to credit card company) or payment service provider.
                        <br />
                        If you or your Customer makes any return or claim which does not comply with our policies (including policies which are described{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/policies/returns " target={"_blank"} color={links} textDecor={"underline"}>
                                here
                            </Link>
                        </chakra.span>
                        ), you will reimburse Printful for its losses, which consist of fulfillment costs and chargeback handling fees (up to $15 USD per chargeback), if applicable.
                        <br />
                        <br />
                        We may refuse to process a transaction for any reason or refuse to provide Services to anyone at any time at our sole discretion. We will not be liable to you or any third
                        party by reason of refusing or suspending any transaction after processing has begun.
                        <br />
                        <br />
                        Unless stated otherwise, you may choose currency from the options available at the Site in which all fees and payments will be quoted. You are responsible for paying all fees,
                        payments and applicable taxes associated with our Site and Services. After receiving your order you may receive an email from us with the details and description of the
                        Products ordered. Payment of the total price plus taxes and delivery must be made in full before the dispatch of your Products.
                        <br />
                        <br />
                        Printful at its sole discretion may offer you various discounts, as well as change, suspend or discontinue them at any time. You may find more information about the available
                        discounts at the Site, in the marketing and promotional emails or via other channels or events Printful may use or participate in.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="taxes">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>10. Taxes</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>You are responsible for paying any applicable taxes to your local taxing authority, unless we have informed you otherwise.
                        <br />
                        <br />
                        Aside from the limited circumstances set out below, you are responsible for (and shall charge) all applicable taxes, such as but not limited to sales taxes, VAT, GST and
                        others, and duties associated with the Products (if and as applicable).
                        <br />
                        <br />
                        In some states in the US and countries, Printful may collect the applicable taxes from you as the seller and pay this to the relevant tax authority (if and as applicable).
                        <br />
                        In certain cases you are required to provide a valid exemption certificate such as, without limitation, Resale certificate, VAT ID or ABN.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="shipping">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>11. Shipping</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Once you've placed an order, you might no longer be able to edit the order details or cancel it. If you have an issue with
                        the shipment of your order, contact us within 30 days of the delivery or estimated delivery date. In some cases, you may need to reach out to the shipping carrier directly.
                        <br />
                        <br />
                        Once you have confirmed your order, it might not be possible to edit or cancel it. If you want to change some parameters, Customer addresses, etc., please check whether such an
                        option is available in your account. We are not bound to make such modifications to your order, but we will do our best on a case-by-case basis.
                        <br />
                        The risk of loss of, damage to and title for Products pass to you upon our delivery to the carrier. It shall be your (if you are a User) or your Customer's (if you are a
                        Merchant) responsibility to file any claim with a carrier for a lost shipment if carrier tracking indicates that the Product was delivered. In such case Printful will not make
                        any refunds and will not resend the Product.
                        <br />
                        <br />
                        For Users in the European Economic Area or the United Kingdom, the risk of loss of, damage to and title for Products will pass to you when you or a third party indicated by you
                        has acquired the physical possession of the Products.
                        <br />
                        If ​​you are a Merchant sending orders to your Customers in Germany, the risk of loss and damage to Products pass to you in the moment after the shipment with the Products has
                        crossed the German border (“Moment of Risk Transfer”). In such case, Printful will bear the full legal responsibility for the loss of or damage to Products sent to your
                        Customers in Germany in case such loss or damage to Products has verifiably occurred before the Moment of Risk Transfer.
                        <br />
                        <br />
                        If carrier tracking indicates that a Product was lost in transit, you or your Customer may make a written claim for replacement of (or credit to the Member’s account for) the
                        lost Product in compliance with Printful’s Return Policy. For Products lost in transit, all claims must be submitted no later than 30 days after the estimated delivery date.
                        All such claims are subject to Printful investigation and sole discretion. Printful cannot guarantee delivery to P.O. boxes.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="description">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>12. Description of products</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>We strive towards making our product development process the best we can, yet we can't guarantee that the product
                        representations on our website will be 100% accurate or that the manufacturing stage won't result in damages. To speed up fulfillment, we also offer you product alternatives in
                        case any of your products are out of stock.
                        <br />
                        <br />
                        While many component parts of our Products are standard, all Products available for purchase are described on their specific page on our Site. We always try to represent each
                        design as accurately as possible via photography and copy points provided by designers, artists or photographers.
                        <br />
                        <br />
                        We have a policy of continuous Product development so we can provide you with what we consider the best design combined with best performance, and thus reserve the right to
                        amend the specifications of Products, their price, packaging, and any Service associated at any time, without prior notice. Before ordering, we invite you to have a close look
                        at the Product description and design.
                        <br />
                        <br />
                        We use our best efforts to provide you with the best images and descriptions, but unfortunately cannot guarantee that colors and details in website images are 100% accurate
                        representations of the product, and sizes might in some cases be approximate.
                        <br />
                        <br />
                        Sometimes during the manufacturing process Products can be damaged. Obviously we won’t knowingly ship damaged items to you (your Customers), but these damaged items can still
                        be used for charitable purposes. Printful reserves the right to donate all damaged items with full or partial designs to charity and you hereby waive your right to collect
                        royalties or other fees regarding damaged Products that are donated.
                        <br />
                        <br />
                        If a Product the Merchant has ordered goes out of stock and the Merchant has not opted-out of Product alternatives in store settings, the Product might be substituted at
                        Printful's own discretion and without any express notification with the most appropriate Product alternative in the order to avoid fulfillment delays, unless we can't find a
                        comparable replacement, in which case the Merchant still need to wait for the original Product to be restocked. If the Product alternative costs more than the original Product
                        being substituted, Printful will charge the price Merchant has paid for the order of the Product. For Product alternatives that cost less than the original Product, we'll
                        refund you the difference. If you wish to opt-out of alternative Products, you can change it in the store settings. Unless the Merchant has opted-out of Product alternatives in
                        the store settings,{" "}
                        <chakra.span fontWeight={700}>Merchant is solely responsible for informing Merchant’s Customers about the Product being substituted with a Product alternative.</chakra.span>
                    </chakra.article>
                </chakra.section>
                <chakra.section id="purchase">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>13. Purchase of products</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>To make sure your orders arrive as quickly as possible, please make sure you submit the correct order details at checkout.
                        Printful doesn't take responsibility for missed deliveries due to typos in the delivery information.
                        <br />
                        Your order is purchase of a Product for which you have paid the applicable fee and/or other charges that we have accepted and received. Any Products in the same order which we
                        have not accepted do not form part of that contract. We may choose not to accept any orders in our sole discretion.
                        <br />
                        Orders are placed and received exclusively via the Site. Before ordering from us, it is your responsibility to check and determine full ability to receive the Products. Correct
                        name of the recipient, delivery address and postal code/zip code, up-to-date telephone number, and email address are absolutely necessary to ensure successful delivery of
                        Products.
                        <br />
                        <br />
                        All information asked on the checkout page must be filled in precisely and accurately. Printful will not be responsible for missed delivery because of a wrong or misspelled
                        recipient name or surname, delivery address or an inappropriate phone number. Should you like to ask for a change in the delivery address, phone number, or any other special
                        requirements, please contact Printful.
                        <br />
                        <br />
                        We reserve the right to place your order on hold due to print file issues, security concerns, if we need to confirm your address, or in case of other issues. In case your order
                        will be placed on hold, we will give you 30 days to resolve the issue. If you will not resolve the issue or cancel your order within 30 days, we will cancel the order. In case
                        your order is cancelled, we will only make a refund if work on your order has not started yet.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="delivery">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>14. Delivery</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>While we may provide delivery estimates, we can't provide guaranteed delivery dates. Once Printful receives payment for
                        your order (including delivery fees), we fulfill the order and pass it onto the carrier. This is also the moment where you or your customer legally become the owner of the
                        products.
                        <br />
                        We deliver to most places in the world. You shall cover delivery costs. Delivery prices are additional to the Product’s price and may vary depending on delivery location and/or
                        sort of Products, and additional charges may be added to the order for remote or difficult to access locations that require special attention. Flat rate delivery charges are
                        shown on our checkout page; however, we reserve the right to advise you of any additional delivery charges that apply to your specific delivery address.
                        <br />
                        <br />
                        Some Products are packaged and shipped separately. We cannot guarantee delivery dates and to the extent permitted by law accept no responsibility, apart from advising you of
                        any known delay, for Products that are delivered after the estimated delivery date. Average time for delivery may be shown on the Site. It is only an average estimation, and
                        some delivery can take longer, or alternatively be delivered much faster. All delivery estimates given at the time of placing and confirming order can be subject to change. In
                        any case, we will do our best to contact you and advise you of all changes. We try our best to make Product delivery as simple as possible.
                        <br />
                        <br />
                        Ownership of the Products will only pass to you/Customer after we receive full payment of all sums due in respect of the Products, including delivery charges and taxes, and
                        deliver the Products to the carrier.
                        <br />
                        We make no guarantees with respect to any collaboration we undertake with you, including any collaboration with respect to Services, Products (including new Products) or any
                        integration with a vendor platform.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="release">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>15. Release</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>You can’t sue us for anything that we have disclaimed or obtained your release from under this Agreement.
                        <br />
                        <br />
                        To the fullest extent permitted by law you release us and all other Printful Parties from any and all claims and demands, as well as any and all damages, losses, liabilities,
                        judgments, costs, reasonable attorneys' fees, and other expenses incurred or suffered by any Printful Party, of every kind and nature, known and unknown, relating to or arising
                        out of any right, claim, or matter (a) which is disclaimed by Printful (or for which Printful provides no guarantees) under this Agreement, or (b) for which Printful is
                        otherwise indemnified or released by you under this Agreement.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="indemnity">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>16. Indemnity</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Printful is not responsible for any damages and liabilities you incur if you break the law, violate these terms or any
                        rights of third parties and it results in legal action (this also includes misrepresentations made by you and any product liability claims).
                        <br />
                        <br />
                        To the fullest extent permitted by law you will defend, indemnify, and hold Printful and the other Printful Parties harmless from any claim or demand made by any third party
                        (including, but not limited to, your Customer), as well as any and all damages, losses, liabilities, judgments, costs, reasonable attorneys' fees, and other expenses of every
                        kind and nature, known and unknown, incurred or suffered by the Printful Parties, relating to or arising out of (a) your breach of this Agreement, (b) your use (or misuse) of
                        our Services, (c) your Content, (d) the infringement by your Content or your account of someone else’s intellectual property or other rights, (d) your violation of any law or
                        the rights of a third party, or (e) if you are a Merchant, any claims related to products or items sold by you through our Services, including claims for misrepresentations by
                        you to your Customers and claims for physical injury or property damage by any of your products or items that are warehoused for you by Printful. We reserve the right to handle
                        our legal defense however we see fit, even if you are indemnifying us, in which case you agree to cooperate with us so we can execute our strategy.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="governing">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>17. Governing Law</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>If any dispute arises between us we'll apply the laws of the State of North Carolina to resolve it—unless you as a consumer
                        reside in the European Economic Area, Switzerland, or the United Kingdom in which case we'll follow the laws of the Republic of Latvia.
                        <br />
                        This Agreement, and all disputes and claims arising out of or in connection with this Agreement or its subject matter or formation including non-contractual disputes and
                        claims, are governed by the laws of the State of North Carolina, without regard to its conflict of laws rules. These laws will apply no matter where in the world you live or
                        are located but if you are a User living in the European Economic Area, Switzerland, or the United Kingdom the laws of the Republic of Latvia will apply to any dispute arising
                        out of or relating to this Agreement. Notwithstanding the aforementioned, nothing in this Agreement, including the aforementioned choice of law provision, affects your rights
                        as an User living in the European Economic Area or Switzerland to rely on any mandatory provisions of the law of the country in which you are resident.
                        <br />
                        For Users in the European Union and the United Kingdom: Alternatively,{" "}
                        <chakra.span>
                            <Link
                                href="https://commission.europa.eu/live-work-travel-eu/consumer-rights-and-complaints/resolve-your-consumer-complaint/european-consumer-centres-network-ecc-net_en"
                                target={"_blank"}
                                color={links}
                                textDecor={"underline"}
                            >
                                you can contact the consumer center in your country
                            </Link>
                        </chakra.span>{" "}
                        or you can decide to access the platform for alternative extra-judicial resolution of disputes{" "}
                        <chakra.span>
                            <Link href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target={"_blank"} color={links} textDecor={"underline"}>
                                provided by the European Commission.
                            </Link>
                        </chakra.span>
                    </chakra.article>
                </chakra.section>
                <chakra.section id="arbitration">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>18. ARBITRATION AND JURY TRIAL WAIVER</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Any legal dispute involving our services (except for situations defined below) will be resolved by arbitration according to
                        the rules in this Section. By using our services, you waive any rights to a jury trial or class actions.
                        <br />
                        <br />
                        “Disputes” means all disputes and claims arising out of or in connection with this Agreement or its subject matter or formation including non-contractual disputes and claims,
                        excluding only claims under the Limited Warranty and claims brought by Users living in the European Economic Area, Switzerland, or Users in the United Kingdom against Printful
                        in European Economic Area or in the courts of England and Wales. All Disputes shall be finally settled by final and binding arbitration, using the English language,
                        administered by the American Arbitration Association (the “AAA”) under its Commercial Arbitration Rules (“Commercial Rules”) in effect at that time. The Rules are deemed to be
                        incorporated by reference into this Agreement. As of the date of this Agreement you can find them{" "}
                        <chakra.span>
                            <Link href="https://www.adr.org/sites/default/files/Commercial%20Rules.pdf" target={"_blank"} color={links} textDecor={"underline"}>
                                here
                            </Link>
                        </chakra.span>{" "}
                        or by calling the AAA at 1–800–778–7879. The Federal Arbitration Act applies to this Agreement. The arbitration shall be administered by the AAA and shall be conducted by a
                        sole arbitrator selected in accordance with the Commercial Rules unless otherwise mutually agreed by the parties. Unless Printful and you agree otherwise, any arbitration
                        hearings will take place in Charlotte, North Carolina, except that, for Users, any arbitration hearings will take place in the county of your residence. Judgment on any
                        arbitration award may be entered and enforced by any court that has jurisdiction to do so. Any arbitration will take place on an individual basis and neither the arbitrator nor
                        AAA are empowered to conduct a class arbitration, class action or to resolve claims of more than a single claimant in anything other than a single proceeding, unless both
                        parties consent. You and Printful acknowledge that AAA may conclude that the Consumer Arbitration Rules or the Rules for the International Centre for Dispute Resolution may be
                        more appropriate in certain cases. You and Printful agree to abide by the decision of the AAA as to the applicable rules in cases where a party may assert that the Commercial
                        Rules are not appropriate.
                        <br />
                        <br />
                        <chakra.span fontWeight={700}>
                            TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AND PRINTFUL KNOWINGLY, VOLUNTARILY, IRREVOCABLY AND ABSOLUTELY WAIVE ALL RIGHTS TO A TRIAL BY JURY OF ANY AND ALL DISPUTES (AS
                            DEFINED ABOVE). THIS JURY TRIAL WAIVER IS INTENTIONALLY MADE AS YOU AND PRINTFUL WOULD PREFER TO RESOLVE ALL DISPUTES (AS DEFINED ABOVE) AS PROVIDED BY THIS SECTION 18.
                            THIS JURY TRIAL WAIVER IS A MATERIAL INDUCEMENT TO ENTERING INTO THIS AGREEMENT. FURTHERMORE, YOU AND PRINTFUL KNOWINGLY, VOLUNTARILY, IRREVOCABLY AND ABSOLUTELY WAIVE ALL
                            RIGHTS TO PARTICIPATE IN A CLASS ACTION, CLASS ARBITRATION OR OTHER FORM OF JOINT DISPUTE RESOLUTION WITH OTHER PARTIES.
                        </chakra.span>
                        <br />
                        <br />
                        Notwithstanding the foregoing, you may instead assert your claim in any Dispute in “small claims” court if you provide us with written notice of your intention to do so before
                        any claim is submitted to arbitration and provided that (a) your claim qualifies, (b) your claim remains in such court, and (c) your claim remains on an individual,
                        non-representative, and non-class basis.
                        <br />
                        <br />
                        Also, notwithstanding the foregoing, each party shall have the right to bring an action in a court of proper jurisdiction for injunctive or other equitable or conservatory
                        relief, pending a final decision by the arbitrator.
                        <br />
                        You and Printful shall each bear 50% of all fees and expenses of the AAA and the arbitrator except as otherwise provided by the Commercial Rules (or, if applicable, the
                        Consumer Arbitration Rules or the Rules for the International Centre for Dispute Resolution). The arbitrator may award such fees and expenses of the AAA and the arbitrator as
                        well as other expenses and reasonable attorneys’ fees to a prevailing party consistent with the provisions of the Commercial Rules (or, if applicable, the Consumer Arbitration
                        Rules or the Rules for the International Centre for Dispute Resolution).
                    </chakra.article>
                </chakra.section>
                <chakra.section id="privacy">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>19. Privacy and Personal Data Processing</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Printful's Privacy Policy is an important part of our Terms of Service that explains how your information is used when you
                        use our services. Please read it very carefully.
                        <br />
                        Printful collects your personal data to provide our Services. We are committed to protecting your personal data and privacy and our{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/policies/privacy" target={"_blank"} color={links} textDecor={"underline"}>
                                Privacy Policy
                            </Link>
                        </chakra.span>{" "}
                        details and explains how we are processing Site visitor and User personal information. By accepting this Agreement, you are also accepting and acknowledging our Privacy Policy.
                        <br />
                        If you are a Merchant and provide us with your customers' personal data, our{" "}
                        <chakra.span>
                            <Link href="https://www.printful.com/policies/data-processing-terms" target={"_blank"} color={links} textDecor={"underline"}>
                                Data Processing Terms
                            </Link>
                        </chakra.span>{" "}
                        , which are incorporated in this Agreement by reference, apply.
                    </chakra.article>
                </chakra.section>
                <chakra.section id="general">
                    <chakra.article fontFamily={"Montserrat"}>
                        <chakra.h1 fontWeight={700}>20. General</chakra.h1>
                        <br />
                        <chakra.span fontWeight={700}>Summary: </chakra.span>Using our services doesn't make you an agent, partner or employee of Printful. We're not responsible for any violations of
                        these terms if it's out of our control. If you have any questions about our Terms of Service, feel free to contact us at{" "}
                        <chakra.span>
                            <Link href="mailto:support@printful.com." target={"_blank"} color={links} textDecor={"underline"}>
                                support@printful.com.
                            </Link>
                        </chakra.span>{" "}
                        <br />
                        <br />
                        No agency, partnership, joint venture, employee-employer, franchisor-franchisee relationship, etc. is intended or created by this Agreement.
                        <br />
                        <br />
                        Printful will not be liable or responsible to you, nor be deemed to have defaulted or breached this Agreement, for any failure or delay in Printful’s performance under this
                        Agreement when such failure or delay is caused by or results from acts or circumstances beyond our reasonable control, such as any fire; flood; earthquake; governmental action;
                        war, invasion or hostilities; national emergency; explosion; terrorist threat or act; riot or other civil unrest; insurrection; epidemic; lockout, strike or other labor dispute
                        (whether or not relating to our workforce); inability or delay in obtaining supplies; telecommunication breakdown; or power outage.
                        <br />
                        <br />
                        You represent that you have all necessary permits to grant us with Customer’s personal data to fulfill this Agreement. To ensure full customer support and a higher quality of
                        service, Printful can sign in to customers/users accounts as a customer according to Printful's Privacy Policy.
                        <br />
                        <br />
                        If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced. In our sole
                        discretion, we may assign this Agreement upon notice to you. Headings are for reference purposes only and do not limit the scope or extent of such section. Our failure to act
                        with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all
                        breaches of this Agreement.
                        <br />
                        <br />
                        Printful at its own discretion reserves the right to transfer or assign this Agreement or any right or obligation under this Agreement at any time, including the right to
                        engage third party manufacturing services to perform any Services. If you are a User in the European Economic Area or the United Kingdom and such transfer or assignment may
                        reduce your guarantees under this Agreement, then Printful will ask for your permission beforehand.
                        <br />
                        If you have any questions about this Agreement, please email us at{" "}
                        <chakra.span>
                            <Link href="mailto:support@printful.com." target={"_blank"} color={links} textDecor={"underline"}>
                                support@printful.com.
                            </Link>
                        </chakra.span>{" "}
                    </chakra.article>
                </chakra.section>
            </Flex>
        </Box>
    );
};
export default PrivacyContent;
