import React from 'react'
import { Box, HStack } from '@chakra-ui/react'
import HeaderIcons from '../../../../icons/HeaderIcons';
import { LuShoppingCart, LuUser2 } from "react-icons/lu";
import HeaderCasperDrawer from './parts/drawer/HeaderCasperDrawer';
import AppLogo from 'components/common/appLogo/AppLogo';

function HeaderMobile() {
    return (
        <HStack padding="4px 10px" alignItems="center" backgroundColor="#ad203e" justifyContent="space-between">
            <Box><AppLogo width="auto" maxWidth="200px" height="40px" /></Box>
            <HStack alignItems="center">
                <Box>
                    <HeaderIcons
                        icons={{
                            cart: LuShoppingCart,
                            profile: LuUser2
                        }}
                        color="#333"
                    />
                </Box>
                <Box><HeaderCasperDrawer /></Box>
            </HStack>
        </HStack>
    )
}

export default HeaderMobile