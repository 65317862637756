import { Box, Flex, Radio, RadioGroup, Stack, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { addressBookService } from 'apis/address/addressServices'
import AppTypography from 'components/common/typography/AppTypography'
import CheckoutListAddressLoading from './parts/loading/CheckoutListAddressLoading'
import AppButton from 'components/common/form/button/AppButton'
import AppModal from 'components/common/modal/AppModal'
import CheckoutFormAddress from '../form/CheckoutFormAddress'
import ListAddressDelete from './parts/delete/ListAddressDelete'
import CustomIcon from 'components/common/customIcon/customIcon'
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import checkoutListAddresModel from './model'
import { useShopStore } from 'lib/stores/shop/shop'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'

interface Iprops {
    onChange(addressBookID: string): void
    value: string
}
function CheckoutListAddres({ onChange, value }: Iprops) {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [UpdateAddressID, setUpdateAddressID] = useState('')
    const { themes } = checkoutListAddresModel
    const { states: { cart } } = useAppStore()
    const { shopTheme } = useShopStore()
    const { data, isLoading, refetch } = useQuery({
        queryFn: addressBookService,
        queryKey: "addressBookService",
        cacheTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        refetch()
    }, [cart?.address])

    useEffect(() => {
        if (cart?.address?._id) onChange(cart?.address?._id)
    }, [data])

    const update = (addressID: string) => {
        setUpdateAddressID(addressID)
        onOpen()
    }

    const onSuccess = () => {
        refetch()
        onClose()
    }

    return (
        <>
            {isLoading ? <CheckoutListAddressLoading /> : (
                <RadioGroup onChange={(addressBookID) => onChange(addressBookID)} value={value}>
                    <VStack align="stretch">
                        {data?.data?.data.map((el: any, key: number) => (
                            <Flex justifyContent="space-between" padding="15px" border="1px solid #444" borderRadius="8px">
                                <Radio value={el._id} key={key}><AppTypography fontSize="14px">
                                    {el.addressLine1} {el.country} {el.state} {el.city} {el.zip}
                                </AppTypography></Radio>
                                <Flex gap="15px" alignItems="center">
                                    {el._id !== cart?.address?._id ? <ListAddressDelete address={el} onSuccess={refetch} /> : null}
                                    <CustomIcon onClick={() => update(el._id)} as={AiOutlineEdit} color={themes[shopTheme]} w="20px" h="20px" />
                                </Flex>
                            </Flex>
                        ))}
                    </VStack>
                </RadioGroup>
            )}
            {isOpen && UpdateAddressID.length ? (
                <AppModal isOpen={isOpen} title="New Address" onClose={onClose} props={{ modal: { isCentered: true, size: "xl" } }}>
                    <CheckoutFormAddress onCancel={onClose} addressID={UpdateAddressID} onSuccess={onSuccess} />
                </AppModal>
            ) : null}
        </>
    )
}

export default CheckoutListAddres