import AppLayout from "components/layout/app/AppLayout";
import { isCustomDomain } from "lib/utils/theme/theme-utils";
import ErrorPage from "pages/error";
import CheckoutPage from "pages/_refactors/checkout/CheckoutPage";
import AddressPage from "pages/_refactors/checkout/pages/address/AddressPage";
import CartPage from "pages/_refactors/checkout/pages/cart/CartPage";
import PaymentPage from "pages/_refactors/checkout/pages/payment/PaymentPage";
import ShippingPage from "pages/_refactors/checkout/pages/shipping/ShippingPage";
import PurchaseHistoryPage from "pages/_refactors/history/PurchaseHistoryPage";
import Homepage from "pages/_refactors/homepage/Homepage";
import PaymentSuccess from "pages/_refactors/paymentSuccess/PaymentSuccess";
import PolicyPage from "pages/_refactors/policy/PolicyPage";
import PrivacyPage from "pages/_refactors/privacy/PrivacyPage";
import ProductPage from "pages/_refactors/product/ProductPage";
import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import SuggestShops from "./pages/suggestShops/SuggestShops";
const PayingtPage = lazy(() => import("pages/paying-page/PayingPage"));
const NotFoundPage = lazy(() => import("pages/404/NotFoundPage"));

const pages = [
  {
    index: true,
    element: <Homepage />,
  },
  {
    path: "paying/:orderId",
    element: <PayingtPage />,
  },
  {
    path: "product/:productId",
    element: <ProductPage />,
  },
  {
    path: "checkout",
    element: <CheckoutPage />,
    children: [
      {
        index: true,
        element: <NotFoundPage />,
      },
      {
        path: "cart",
        element: <CartPage />
      },
      {
        path: "address",
        element: <AddressPage />
      },
      {
        path: "shipping",
        element: <ShippingPage />
      },
      {
        path: "payment",
        element: <PaymentPage />
      }
    ]
  },
  {
    path: "purchase-history",
    element: <PurchaseHistoryPage />,
  },
  {
    path: "success-payment/:orderId",
    element: <PaymentSuccess />,
  },
  {
    path: "privacy",
    element: <PrivacyPage />,
  },
  {
    path: "return-policy",
    element: <PolicyPage />,
  },
  {
    path: "*",
    element: <Navigate to="/not-found" replace={true} />,
  }
]

const appRouting = {
  path: "/",
  errorElement: <ErrorPage />,
  element: <AppLayout />,
  children: [
    { path: "not-found", element: <NotFoundPage /> },
    ...isCustomDomain ? pages : [
      {
        index: true,
        element: <SuggestShops />,
      },
      {
        path: ":shopName/",
        children: pages
      },
    ]
  ]
}

export const router = createBrowserRouter([appRouting]);