import { StyleProps } from "@chakra-ui/react"
import { IShopDesign, ShopThemeEnum } from "lib/models/shop"
import { CSSProperties } from "react"
import appDropDownModel from "./parts/dropdown/model"

interface Ithemes {
    [propname: string]: CSSProperties
}
namespace appFormMakerModel {
    const { darkenColor } = appDropDownModel

    export const themes = (shopDesign: IShopDesign): Ithemes => {
        const styles: Ithemes = {
            default: {
                backgroundColor: 'rgba(255, 255, 255, .1)',
                border: `1px solid ${darkenColor(shopDesign.foreground, 30)} !important`,
                outline: 'none',
                boxShadow: 'none',
                padding:'22px 15px',
                color: shopDesign.textColorParagraphs || "#FFF",
            },
            custom: {
                backgroundColor: '#EEE',
                border: '1px solid #cfcfcf !important',
                outline: 'none',
                padding:'22px 15px',
                boxShadow: 'none',
                color: "#333",
            }
        }
        return {
            [ShopThemeEnum.THEME_UNSTOPPABLE]: styles.custom,
            [ShopThemeEnum.THEME_SKALE]: styles.custom,
            [ShopThemeEnum.THEME_CASPER]: styles.custom,
            [ShopThemeEnum.THEME_POLYGON]: styles.custom,
            [ShopThemeEnum.THEME_1]: styles.default,
            [ShopThemeEnum.THEME_2]: styles.default,
        }
    }
}

export default appFormMakerModel