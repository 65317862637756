import { VStack } from "@chakra-ui/react";
import AppLogo from "components/common/appLogo/AppLogo";
import Powered from "./parts/powered/Powered";
import casper from 'assets/icons/footer/casper/casper-logo-dark.png'

const FooterCasperBrand = () => {
    return (
        <VStack spacing={"12"}>
            <AppLogo src={casper} width="auto" height="70px" />
            <Powered />
        </VStack>
    );
};

export default FooterCasperBrand;
