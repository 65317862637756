import { Flex, VStack } from '@chakra-ui/react'
import AppContainer from 'components/common/container/AppContainer'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'

interface IProps {
    title: string
    children: any
}
function CheckoutLayoutPage({ children, title }: IProps) {
    return (
        <Flex justifyContent="center">
            <AppContainer mini props={{ flexDirection: "column", maxWidth: "750px" }}>
                <VStack align="stretch" width="100%" spacing="20px">
                    <AppTypography fontSize="22px" fontWeight="bold">{title}</AppTypography>
                    {children}
                </VStack>
            </AppContainer>
        </Flex>
    )
}

export default CheckoutLayoutPage