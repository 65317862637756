import { HStack, Link } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { CHAIN_TYPE } from "pages/_refactors/paymentSuccess/context";
import deployHashCodeModel from "./model";
import { useMemo } from "react";
import AppCopy from "components/common/copy/AppCopy";

interface IDeployHash {
    hash: string;
    hashType: CHAIN_TYPE;
}
const DeployHash = ({ hash, hashType }: IDeployHash) => {
    const { getDeployHashLink } = deployHashCodeModel;
    const deployHash = useMemo(() => getDeployHashLink(hash, hashType), []);
    return (
        <HStack align={"center"} justifyContent={"flex-start"}>
            <Link href={deployHash} w={"90%"} target="_blank">
                <AppTypography fontSize="14px" fontWeight="400" _hover={{ color: "#B6B6AF" }} textDecoration="underline" textAlign="center" whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                    {`${hash}`}
                </AppTypography>
            </Link>
            <AppCopy toCopy={hash} />
        </HStack>
    );
};

export default DeployHash;
