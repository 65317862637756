import { ShopThemeEnum } from 'lib/models/shop'
import { useShopStore } from 'lib/stores/shop/shop';
import React from 'react'

function useAppTheme() {
    const { shopTheme } = useShopStore();

    const isDefaultTheme = [ShopThemeEnum.THEME_1, ShopThemeEnum.THEME_2].includes(shopTheme)

    return { isDefaultTheme }
}

export default useAppTheme