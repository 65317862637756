import { Flex } from "@chakra-ui/react";
import FooterCasperBrand from "./parts/brand/FooerCasperBrand";
import FooterCasperLicence from "./parts/licence/FooterCasperLicence";

const FooterCasper = () => {
    return (
        <Flex flexDir={{ base: "column", lg: "row" }} p={{ base: "51px 25px 40px 25px", lg: "40px 65px 65px 65px" }} gap='32px' justifyContent={"space-between"} bg={"black"}>
            <FooterCasperBrand />
            <FooterCasperLicence />
        </Flex>
    );
};

export default FooterCasper;
