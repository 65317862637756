import { Flex } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { useContext } from "react";
import orderModalContext from "../../context";
import DisplayRow from "./parts/row/SummaryRow";

const PurchaseHistoryOrderSummary = () => {
    const order = useContext(orderModalContext)

    return (
        <Flex direction={"column"} gap={"24px"}>
            <AppTypography w={'full'} textAlign={'left'} fontSize={{ base: "16px", md: "18px" }} fontWeight='bold'>Order Summary</AppTypography>
            <Flex direction={"column"} gap={"18px"}>
                <DisplayRow label="Order ID" content={order?.orderId!} />
                {order?.shippingMethod && <DisplayRow label="Shipping Method" content={order.shippingMethod} />}
                {order?.address && <DisplayRow label="Address" content={order.address} />}
            </Flex>
        </Flex>
    );
};

export default PurchaseHistoryOrderSummary;
