import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import useAppStore from "lib/stores/_refactor/cart/cartStore";
import CartPageTableRow from "./parts/tableRow/CartPageTableRow";

const CartPageDesktop = () => {
    const { states: { cart } } = useAppStore();
    return (
        <Table>
            <Thead>
                <Tr>
                    <Th textTransform={"capitalize"}><AppTypography fontSize={'14px'} whiteSpace="nowrap">Products({cart?.items?.length})</AppTypography></Th>
                    <Th textTransform={"capitalize"}><AppTypography fontSize={'14px'} whiteSpace="nowrap">Quantity</AppTypography></Th>
                    <Th textTransform={"capitalize"}><AppTypography fontSize={'14px'} whiteSpace="nowrap">Cost Per Unit</AppTypography></Th>
                    <Th textTransform={"capitalize"}><AppTypography fontSize={'14px'} whiteSpace="nowrap">Total</AppTypography></Th>
                    <Th> {/* Empty header for delete button */} </Th>
                </Tr>
            </Thead>
            <Tbody>
                {cart?.items?.map((item, key: number) => (
                    <CartPageTableRow key={key} cartItem={item} />
                ))}
            </Tbody>
        </Table>
    );
};

export default CartPageDesktop;
