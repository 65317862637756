import axiosInstance from "apis/axiosConfig"
import { IgetOrderByIdService, IgetOrdersService, IorderPaymentService } from "./interface"

export const getOrdersService = ({ page, limit }: IgetOrdersService) => {
    return axiosInstance.get(`order/retrieve/customer/orders?page=${page}&limit=${limit}`)
}

export const getSingleCustomerOrderByIdService = (orderId: string) => {
    return axiosInstance.get(`order/single/customer/${orderId}`)
}

export const getOrderByIdService = ({ id }: IgetOrderByIdService) => {
    return axiosInstance.get(`order/public/${id}`)
}

export const orderPaymentService = ({ chain, params, anonymous }: IorderPaymentService) => {
    return axiosInstance.post(anonymous ? `order/public/payment/${chain}` : `order/payment/${chain}`, params)
}