import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

import { IUserData, IUser } from "lib/models/user";
import { IAddressInterface } from "lib/models/address";

interface IUserStore {
  userData: IUserData | null;
  address: Array<IAddressInterface>;
  isLoggedIn: boolean;
  walletAddress: string;
  user: IUser | null;
  setUserData: (data: IUserData, shopName: string) => void;
}

let store: any = (set: any): IUserStore => {
  return {
    userData: null,
    address: [],
    isLoggedIn: false,
    walletAddress: "",
    user: null,
    setUserData: (data: IUserData, shopName: string) => {
      const user = data?.hasOwnProperty(shopName) ? data[shopName].user : null;
      set({
        user: user,
        isLoggedIn: user !== null,
        walletAddress: user?.walletAddress || "",
      });
      set({ userData: data });
    },
  };
};

store = persist(store, { name: "userStore" });
store = devtools(store);
export const useUserStore = create<IUserStore>(store);
