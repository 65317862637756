import axios from "axios";

import * as casper_consts from "./constants";

import { CLPublicKey, CasperWalletEventTypes, CLU512 } from "casper-js-sdk";
import { appDeveloment } from "lib/utils/app/variables";
let casperWalletInstance;
export let account_information;
export const getCasperWalletInstance = () => {
  try {
    if (!casperWalletInstance) {
      casperWalletInstance = window.CasperWalletProvider();
    }
    return casperWalletInstance;
  } catch (err) {}
  throw Error("Please install the Casper Wallet Extension.");
};

export const isCapseWalletExtentionInstalled = () => {
  try {
    let walletInstance = window.CasperWalletProvider();
    return walletInstance != null;
  } catch (error) {
    return false;
  }
};

let get_account_information = async function (publicKey) {
  let sign = await getCasperWalletInstance().signMessage(
    "Please sign this message to let droplinked access to your PublicKey and validate your identity.",
    await getCasperWalletInstance().getActivePublicKey()
  );
  return {
    publicKey: publicKey,
    account_hash: CLPublicKey.fromHex(publicKey).toAccountRawHashStr(),
    signature: sign.signatureHex,
  };
};
export async function casper_wallet_login(on_connected) {
  let called = false;
  await getCasperWalletInstance().requestConnection();
  if (await getCasperWalletInstance().isConnected()) {
    if (!called) {
      called = true;
      on_connected(
        await get_account_information(
          await getCasperWalletInstance().getActivePublicKey()
        )
      );
    }
    return;
  }
  await getCasperWalletInstance().requestConnection();
  const handleConnected = async (event) => {
    try {
      const action = JSON.parse(event.detail);
      if (action.activeKey) {
        if (!called) {
          called = true;
          on_connected(await get_account_information(action.activeKey));
        }
      }
    } catch (err) {
    }
  };
  window.addEventListener(CasperWalletEventTypes.Connected, handleConnected);
  if (!called)
    on_connected(
      await get_account_information(
        await getCasperWalletInstance().getActivePublicKey()
      )
    );
}

/**
 *
 * @param {string} accountHash
 * @returns {Promise<number>} balance
 */
export async function get_casper_balance(accountHash) {
  // let pk = CLPublicKey.fromHex(publicKey);
  try {
    let stateRootHash = await casper_consts.casperService.getStateRootHash();
    let balance_uref =
      await casper_consts.casperService.getAccountBalanceUrefByPublicKeyHash(
        stateRootHash,
        accountHash
      );
    let balance = await casper_consts.casperService.getAccountBalance(
      stateRootHash,
      balance_uref
    );
    return balance.toNumber();
  } catch (err) {
    return 0;
  }
}

async function getCasperRatio(usd_amount) {
  let js = (
    await axios.post(
      `https:/${
        appDeveloment ? "apiv3dev.droplinked.com" : "apiv3.droplinked.com"
      }/payment/casper/price`,
      {
        usd_amount: usd_amount,
      }
    )
  ).data.data;
  return new CLU512(js);
}

/**
 *
 * @param {number} price_in_usd
 * @param {string} accountHash
 * @returns {Promise<boolean>}
 */
export async function check_balance_on_product(price_in_usd, accountHash) {
  let expected_amount = (await getCasperRatio(price_in_usd)).data.toNumber();
  let current_amount = await get_casper_balance(accountHash);
  return current_amount > expected_amount;
}
