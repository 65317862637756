import { Hide, HStack } from "@chakra-ui/react";
import AppSeperator from "components/common/seperator/AppSeperator";
import AppTypography from "components/common/typography/AppTypography";
import { useShopStore } from "lib/stores/shop/shop";
import { useMemo } from "react";
import footerDefaultModel from "../../model";
import FooterLinks from "../footerLinks/FooterLinks";

interface IFooterItems {
    items: string[] | { link: string; caption: string }[]
    hyperlink?: boolean
}
const FooterItems = ({ items, hyperlink }: IFooterItems) => {
    const { shopData, shopTheme } = useShopStore()
    const color = useMemo(() => footerDefaultModel.design(shopTheme, shopData).color, [shopTheme, shopData])
    return (
        <>
            {items.map((item: any, index: number) => (
                <HStack key={index} alignItems="center" align="center">
                    {index !== 0 && <Hide below="md"><AppSeperator orientation="vertical" height="12px" /></Hide>}
                    {item.caption ? <FooterLinks hyperlink={hyperlink} link={item.link} caption={item.caption} /> : <AppTypography color={color}>{typeof item === 'string' ? item : null}</AppTypography>}
                </HStack>
            ))}
        </>
    );
};

export default FooterItems;
