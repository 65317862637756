import { IApiServiceType } from "./api-model";

export function createApiReq(
  url: string,
  auth: boolean,
  body?: any
): IApiServiceType {
  const token = (auth && localStorage.getItem("token")) ? JSON.parse(localStorage.getItem("token") || "") : "";
  return { url: url, token: token, body: body };
}
