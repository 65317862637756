import { Box, Flex, VStack } from "@chakra-ui/react";
import { IgetOrderByIdService } from "apis/orders/interface";
import { getOrderByIdService } from "apis/orders/services";
import AppButton from "components/common/form/button/AppButton";
import AppLoading from "components/common/loading/AppLoading";
import AppSeperator from "components/common/seperator/AppSeperator";
import AppTypography from "components/common/typography/AppTypography";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import useAppCart from "hooks/_refactor/cart/useAppCart";
import { useShopStore } from "lib/stores/shop/shop";
import { useUserStore } from "lib/stores/user/userStore";
import useAppStore from "lib/stores/_refactor/cart/cartStore";
import React, { useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import paymentSuccessContext from "./context";
import PaymentPurchase from "./parts/purchase/PaymentPurchase";
import PaymentTotals from "./parts/totals/PaymentTotals";
import PaymentSuccessSummary from "./parts/summary/PaymentSuccessSummary";
import successPaymentHeaderModel from "./model";
import useAppTheme from "hooks/_refactor/theme/useAppTheme";

function PaymentSuccess() {
    const { mutate, data, isLoading } = useMutation((params: IgetOrderByIdService) => getOrderByIdService(params));
    const { shopDesign: { foreground } } = useShopStore();
    const { orderId } = useParams();
    const { route } = useCustomNavigate();
    const { isLoggedIn } = useUserStore();
    const { fetch, clear } = useAppCart();
    const { states: { cart } } = useAppStore();
    const { isDefaultTheme } = useAppTheme()

    const { title, color, description } = useMemo(() => successPaymentHeaderModel.getOrderStatusDesign(data?.data?.data?.details?.status), [data?.data?.data?.details?.status])

    const cleared = async () => {
        try {
            if (orderId) mutate({ id: orderId });
            if (isLoggedIn) await fetch();
            await clear(Boolean(cart?._id));
        } catch (error) { }
    };

    useEffect(() => {
        cleared();
    }, [orderId, isLoggedIn]);

    const order = useMemo(() => data?.data?.data, [data]);

    return isLoading ? (
        <Box paddingTop="150px">
            <AppLoading />
        </Box>
    ) : order ? (
        <paymentSuccessContext.Provider value={{ order }}>
            <Flex justifyContent="center" padding="50px 0">
                <VStack width="800px" maxWidth="95%" align="stretch" padding="40px" spacing="30px" backgroundColor={isDefaultTheme ? foreground : "#f7f7f7"} borderRadius="8px">
                    <VStack justifyContent="center" textAlign="center" >
                        <AppTypography fontSize={{ base: "16px", sm: "24px" }} color={color} fontWeight="bold">{title}</AppTypography>
                        <AppTypography fontSize={{ base: "14px", sm: "16px" }} color={color}>{description}</AppTypography>
                    </VStack>
                    <AppSeperator />
                    <PaymentSuccessSummary />
                    <AppSeperator />
                    <PaymentPurchase />
                    <AppSeperator />
                    <PaymentTotals />
                    <Flex justifyContent="center">
                        <Link to={route("")}><AppButton size="sm" paddingRight="20px" paddingLeft="20px">Back to Store</AppButton></Link>
                    </Flex>
                </VStack>
            </Flex>
        </paymentSuccessContext.Provider>
    ) : null;
}

export default PaymentSuccess;
