import { VStack } from "@chakra-ui/react";
import AppSeperator from "components/common/seperator/AppSeperator";
import useAppStore from "lib/stores/_refactor/cart/cartStore";
import { ICartStoreItem } from "lib/stores/_refactor/cart/interfaces";
import CartPageMobileItem from "./parts/mobileItem/CartPageMobileItem";

const CartPageMobile = () => {
    const { states: { cart } } = useAppStore();
    return (
        <VStack spacing={'32px'} w='full'>
            {cart?.items?.map((item: ICartStoreItem, key: number) => (
                <CartPageMobileItem key={key} cartItem={item} />
            ))}
            <AppSeperator />
        </VStack>
    );
};

export default CartPageMobile;
