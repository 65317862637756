import { HStack, Link } from "@chakra-ui/react";
import { ReactComponent as Twitter } from "assets/icons/footer/casper/twitter-fill-logo.svg";
import { ReactComponent as Telegram } from "assets/icons/footer/casper/telegram-fill-icon.svg";
import { ReactComponent as Discord } from "assets/icons/footer/casper/discord-fill-icon.svg";
import { ReactComponent as MLogo } from "assets/icons/footer/casper/m-fill-logo.svg";

const FooterCasperSocial = () => {
    return (
        <HStack spacing={'4'}>
            <Link href="https://twitter.com/casperpunks_" target={'_blank'}><Twitter/></Link>
            <Link href="https://t.me/casperpunks" target={'_blank'}><Telegram/></Link>
            <Link href="https://discord.gg/casperpunks" target={'_blank'}><Discord/></Link>
            <Link href="https://medium.com/@casperpunks" target={'_blank'}><MLogo/></Link>
        </HStack>
    );
};

export default FooterCasperSocial;
