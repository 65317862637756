import { Image } from "@chakra-ui/react"

const hiroSectionModel = {
    themeImages: {
        skale: <Image width="100%" src="/assets/images/banner-skale.png" />,
        unstoppable: <Image width="100%" src="/assets/images/banner-unstoppable.png" />,

    }
}

export default hiroSectionModel