import { Box, Flex, Image, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import { Link } from 'react-router-dom'

function CartHeaderItems() {
  const { states: { cart } } = useAppStore()
  const { route } = useCustomNavigate()

  return (
    <VStack align="stretch" spacing="20px">
      {cart?.items.map((el: any, key: number) => (
        <Flex key={key} alignItems="center" justifyContent="left" gap="10px">
          <Box width={{ base: "30%", sm: "30%" }}>
            <Link to={route('product/' + el?.product?._id)}><Image src={el?.product?.m2m_preview || el?.product?.image} width="100%" /></Link>
          </Box>
          <VStack width={{ base: "60%", sm: "60%" }} align="stretch" spacing={{ base: "0", sm: "4px" }}>
            <Link to={route('product/' + el?.product?._id)}><AppTypography fontSize={{ base: "14px", sm: "16px" }} fontWeight="600">{el.title}</AppTypography></Link>
            <AppTypography fontSize={{ base: "14px", sm: "16px" }} fontWeight={600}>{el?.product?.title}</AppTypography>
            <AppTypography fontSize={{ base: "12px", sm: "14px" }}>color: {el?.options?.color?.caption}</AppTypography>
            <AppTypography fontSize={{ base: "12px", sm: "14px" }}>size: {el?.options?.size?.value}</AppTypography>
          </VStack>
        </Flex>
      ))}
    </VStack>
  )
}

export default CartHeaderItems