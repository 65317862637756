import { Box, Flex, Image, Link, SimpleGrid } from '@chakra-ui/react'
import { faker } from '@faker-js/faker'
import AppModal from 'components/common/modal/AppModal'
import AppTypography from 'components/common/typography/AppTypography'
import { useShopStore } from 'lib/stores/shop/shop'
import styles from "./NFTModal.module.scss"

interface Props {
    isOpen: boolean
    close: Function
}

function NFTModal({ isOpen, close }: Props) {
    const { shopData: { shopDesign: { backgroundBody } } } = useShopStore();
    const nfts = Array.from({ length: 8 }).map(() => ({
        image: faker.image.avatar(),
        title: faker.lorem.sentence(5)
    }))

    return (
        <AppModal
            isOpen={isOpen}
            onClose={close}
            props={{ content: { maxWidth: "1210px" } }}
        >
            <Flex direction={"column"} gap={"36px"}>
                <AppTypography fontSize={"24px"} fontWeight={"bold"}>My NFTs</AppTypography>
                <SimpleGrid
                    columns={{
                        sm: 2,
                        md: 3,
                        lg: 4,
                        xl: 5,
                    }}
                    gap={"16px"}
                >
                    {nfts.map((nft, index) => (
                        <Box
                            key={index}
                            backgroundColor={backgroundBody}
                            className={styles["nft-card"]}
                        >
                            <Image src={nft.image} objectFit={"cover"} width={"100%"} height={"196px"} />
                            <Box paddingBlock={"12px"} paddingInline={"16px"}>
                                <AppTypography fontSize={"14px"} fontWeight={"600"}>{nft.title}</AppTypography>
                            </Box>
                            <Box
                                backgroundColor={backgroundBody}
                                className={styles["nft-details"]}
                            >
                                <Flex direction={"column"} gap={"16px"}>
                                    <Flex direction={"column"} gap={"5px"}>
                                        <AppTypography fontSize={"14px"}>NFT Name:</AppTypography>
                                        <AppTypography fontSize={"14px"} fontWeight={"700"}>Poker Face Monkey</AppTypography>
                                    </Flex>
                                    <Flex direction={"column"} gap={"5px"}>
                                        <AppTypography fontSize={"14px"}>Creator:</AppTypography>
                                        <AppTypography fontSize={"14px"} fontWeight={"700"}>
                                            {"0x3D2861029B27EbBE96E4FDC4BDef03fBf1d9F9D5".slice(0, 15) + "..."}
                                        </AppTypography>
                                    </Flex>
                                </Flex>
                                <Flex direction={"column"} gap={"5px"}>
                                    <AppTypography fontSize={"14px"}>Product Page:</AppTypography>
                                    <AppTypography fontSize={"14px"}>
                                        <Link
                                            href={"https://www.google.com/"}
                                            target={"_blank"}
                                            textDecoration={"underline"}
                                            textDecorationColor={"#33A9EC"}
                                            color={"#33A9EC"}
                                            fontSize={"14px"}
                                            fontWeight={"700"}
                                        >
                                            {"https://www.google.com/".slice(0, 15) + "..."}
                                        </Link>
                                    </AppTypography>
                                </Flex>
                            </Box>
                        </Box>
                    ))}
                </SimpleGrid>
            </Flex>
        </ AppModal >
    )
}

export default NFTModal