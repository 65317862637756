import { VStack } from '@chakra-ui/react'
import AppQuantity from 'components/common/quantity/AppQuantity'
import productPageContext from 'pages/_refactors/product/context'
import React, { useContext } from 'react'
import ProductDetailTitles from '../titles/ProductDetailTitles'

function ProductQuantity() {
    const { states: { option: { quantity } }, methods: { updateOption } } = useContext(productPageContext)

    return (
        <VStack align="stretch">
            <ProductDetailTitles text='Quantity' />
            <AppQuantity
                onChange={(value: string) => updateOption('quantity', value)}
                value={quantity}
            />
        </VStack>
    )
}

export default ProductQuantity