import React from 'react'
import { Flex, Modal, ModalBody, ModalContent, ModalContentProps, ModalHeader, ModalOverlay, Icon, ModalHeaderProps, TextProps, VStack } from '@chakra-ui/react'
import appModalModel from './model'
import { useShopStore } from 'lib/stores/shop/shop'
import AppTypography from '../typography/AppTypography'
import { IoMdClose } from "react-icons/io";
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'

export interface IAppModal {
    isOpen: boolean
    onClose: any
    title?: string
    props?: {
        modal?: any
        content?: ModalContentProps
        header?: ModalHeaderProps
        text?: TextProps
    }
    children?: any
}
function AppModal({ props, isOpen, onClose, title, children }: IAppModal) {
    const { themes } = appModalModel
    const { shopTheme, shopDesign } = useShopStore();
    const { isDefaultTheme } = useAppTheme()

    const getTheme = themes(shopDesign)[shopTheme]

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={!isDefaultTheme} {...props?.modal}>
            <ModalOverlay backdropFilter="blur(8px)" backgroundColor="rgba(0,0,0,.4)" />
            <ModalContent padding="30px 0" boxShadow="unset" background="none" {...props?.content}>
                <Flex justifyContent="center">
                    <VStack align="stretch" borderRadius="32px" width="100%" maxWidth="95%" padding={{ base: "10px 0", md: "20px" }} {...getTheme.modal}>
                        {title && (
                            <ModalHeader textAlign="center">
                                <Flex alignItems="center" position="relative" justifyContent="center" {...props?.header}>
                                    <AppTypography {...getTheme.title} {...props?.text}>{title}</AppTypography>
                                    <Icon position="absolute" right="0" as={IoMdClose} onClick={onClose} cursor="pointer" {...getTheme.close} />
                                </Flex>
                            </ModalHeader>
                        )}
                        <ModalBody>{children}</ModalBody>
                    </VStack>
                </Flex>
            </ModalContent>
        </Modal>
    )
}

export default AppModal