import { Box } from '@chakra-ui/react';
import CustomIcon from 'components/common/customIcon/customIcon';
import AppLoading from 'components/common/loading/AppLoading';
import { useToastify } from 'context/toastify/ToastifyContext';
import useAppCart from 'hooks/_refactor/cart/useAppCart';
import useAppTheme from 'hooks/_refactor/theme/useAppTheme';
import { useShopStore } from 'lib/stores/shop/shop';
import react, { useState } from 'react';
import { MdDeleteOutline } from "react-icons/md";

const CartDeleteItemButton = ({ itemId }: { itemId: string }) => {
  const { deleted } = useAppCart()
  const { errorToast } = useToastify()
  const { shopDesign: { foreground, textColorParagraphs } } = useShopStore()
  const [Loading, setLoading] = useState(false)
  const { isDefaultTheme } = useAppTheme()

  const deleteItem = async () => {
    try {
      setLoading(true)
      await deleted({ itemId })
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      errorToast(error?.message || "Oops Somthing wrong")
    }
  }

  return (
    <Box w={{ base: '28px', md: "39px" }} h={{ base: '28px', md: "39px" }} borderRadius="50%" bg={isDefaultTheme ? foreground : "#FFF"} display="flex" justifyContent="center" alignItems="center" cursor="pointer" onClick={() => !Loading ? deleteItem() : {}}>{Loading ? <AppLoading /> : <CustomIcon as={MdDeleteOutline} color={isDefaultTheme ? textColorParagraphs : ''} w="20px" h="20px" />}</Box>

  )
}

export default CartDeleteItemButton