import { Box, Flex, Radio, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { useShopStore } from 'lib/stores/shop/shop'
import React from 'react'
import checkoutShippingRadioModel from './model'

interface IProps {
    option: any
    active: boolean
}
function CheckoutShippingRadio({ option, active }: IProps) {
    const { themes } = checkoutShippingRadioModel
    const { shopTheme } = useShopStore()

    return (
        <Box {...themes[shopTheme].box} {...active && themes[shopTheme]?.active}>
            <Radio value={option.id} alignItems="self-start">
                <VStack align="stretch" width="100%">
                    <Flex gap={{ base: "2px", sm: "20px" }} flexDirection={{ base: "column", sm: "row" }} alignItems="baseline" width="100%" justifyContent="space-between">
                        <AppTypography {...themes[shopTheme].title} width="100%">{option.title}</AppTypography>
                        <AppTypography {...themes[shopTheme].title} whiteSpace="nowrap">${option.price ? option.price.toFixed(2) : 0} USD</AppTypography>
                    </Flex>
                    <AppTypography {...themes[shopTheme].caption}>Fulfillment Date : {option.delivery_estimation}</AppTypography>
                </VStack>
            </Radio>
        </Box>
    )
}

export default CheckoutShippingRadio