import { HStack, VStack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import { ICartItemInterface } from "lib/models/cart";
import { ICartStoreItem } from "lib/stores/_refactor/cart/interfaces";
import cartPageModel from "../../../model";
import CartPageProductImage from "../../../parts/productImage/CartPageProductImage";

const CartPageProduct = ({ cartItem }: { cartItem: ICartStoreItem }) => {
    return (
        <HStack>
            <CartPageProductImage cartItem={cartItem} />
            <VStack spacing={"8px"} align="start">
                <AppTypography fontWeight="600" fontSize="16px">{cartItem?.product.title}</AppTypography>
                {cartItem?.options?.color?.value && <AppTypography>Size: {cartItem?.options?.size?.caption}</AppTypography>}
                {cartItem?.options?.size?.value && <AppTypography>Color: {cartItem?.options?.color?.caption}</AppTypography>}
            </VStack>
        </HStack>
    );
};

export default CartPageProduct;
