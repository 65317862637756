import { Box, Flex, SimpleGrid, VStack } from '@chakra-ui/layout'
import AppContainer from 'components/common/container/AppContainer'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'

function ProductPageLoading() {
    return (
        <AppContainer props={{ gap: "80px", flexDirection: "column" }}>
            <VStack align="stretch" spacing="60px">
                <Flex align="stretch" gap={{ base: "30px", md: "80px" }} flexDirection={{ base: "column", md: "row" }}>
                    <Box width={{ base: "100%", md: "40%" }}>
                        <VStack align="stretch" spacing="20px">
                            <AppSkeleton height="500px" />
                            <SimpleGrid columns={5} spacing="10px">
                                <AppSkeleton height="60px" />
                                <AppSkeleton height="60px" />
                                <AppSkeleton height="60px" />
                                <AppSkeleton height="60px" />
                                <AppSkeleton height="60px" />
                            </SimpleGrid>
                        </VStack>
                    </Box>
                    <Box width={{ base: "100%", md: "60%" }}>
                        <VStack align="stretch" spacing="20px">
                            <AppSkeleton height="50px" />
                            <AppSkeleton height="20px" width="40%" />
                            <Flex gap="10px">
                                <AppSkeleton height="50px" width="50px" borderRadius="100%" />
                                <AppSkeleton height="50px" width="50px" borderRadius="100%" />
                                <AppSkeleton height="50px" width="50px" borderRadius="100%" />
                                <AppSkeleton height="50px" width="50px" borderRadius="100%" />
                                <AppSkeleton height="50px" width="50px" borderRadius="100%" />
                            </Flex>
                        </VStack>
                    </Box>
                </Flex>
                <AppSkeleton height="20px" />
            </VStack>
        </AppContainer >
    )
}

export default ProductPageLoading