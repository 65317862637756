import { Link, List, OrderedList, chakra } from "@chakra-ui/react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const PrivacyLists = ({ navItems, onClickLink, hashtag }: { navItems: { name: string; key: string }[]; onClickLink?: () => void; hashtag: string }) => {
    return (
        <chakra.nav>
            <OrderedList>
                {navItems?.map((item) => (
                    <List key={item?.key}>
                        <Link onClick={onClickLink} fontWeight={hashtag === item.key ? 700 : 400} href={`#${item?.key}`} mr={4} mb={{ base: "0px", md: "8px" }}>
                            {item?.name}
                        </Link>
                    </List>
                ))}
            </OrderedList>
        </chakra.nav>
    );
};

export default PrivacyLists;
