import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import AppContainer from 'components/common/container/AppContainer';
import HeaderIcons from '../../icons/HeaderIcons';
import { LuShoppingCart, LuUser2 } from "react-icons/lu";
import headerDefaultModel from './model';
import { useShopStore } from 'lib/stores/shop/shop';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate';
import AppLogo from 'components/common/appLogo/AppLogo';
import useAppTheme from 'hooks/_refactor/theme/useAppTheme';

function HeaderDefault() {
    const { isHomepage } = headerDefaultModel
    const { shopDesign: { textColorParagraphs, iconHeaderColor }, shopData: { template_options } } = useShopStore();
    const { route } = useCustomNavigate();
    const location = useLocation()
    const { isDefaultTheme } = useAppTheme()

    return (
        <Flex justifyContent="center" position={isDefaultTheme && isHomepage({ location, route }) ? "absolute" : "static"} right="0" left="0" zIndex="4" {...template_options?.['--dlk-hdr']?.['--dlk-hdr-styles']}>
            <AppContainer props={{ justifyContent: "space-between", alignItems: "center", padding: { base: "5px 0", md: "20px 0" }, ...template_options?.['--dlk-hdr']?.['--dlk-hdr-container'] }}>
                <Box maxWidth="150px"><AppLogo objectFit={'contain'} height={{ base: "35px", sm: "45px" }} {...template_options?.['--dlk-hdr']?.['--dlk-hdr-logo']} /></Box>
                <HeaderIcons
                    icons={{
                        cart: LuShoppingCart,
                        profile: LuUser2,
                    }}
                    color={iconHeaderColor || textColorParagraphs}
                />
            </AppContainer>
        </Flex>
    )
}

export default HeaderDefault