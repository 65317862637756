import React, { useState } from "react";
import AppQuantity from "components/common/quantity/AppQuantity";
import { useToastify } from "context/toastify/ToastifyContext";
import { ICartStoreItem } from "lib/stores/_refactor/cart/interfaces";
import useAppCart from "hooks/_refactor/cart/useAppCart";

const CartPageQuantity = ({ cartItem }: { cartItem: ICartStoreItem }) => {
    const { update } = useAppCart();
    const { errorToast } = useToastify()
    const [Loading, setLoading] = useState(false)
    
    const change = async (value: any) => {
        try {
            setLoading(true)
            await update({ quantity: value, skuID: cartItem.skuID, itemId: cartItem._id });
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            errorToast(error?.message)
        }
    }

    return <AppQuantity value={cartItem?.options.quantity} onChange={change} loading={Loading} />
};

export default CartPageQuantity;
