import { ButtonProps } from "@chakra-ui/react"
import { IShopDesign, ShopThemeEnum } from "lib/models/shop"

interface Ithemes {
    [propname: string]: ButtonProps
}

interface IVariantsItems {
    outline: ButtonProps
}

interface IVariants {
    default: IVariantsItems
    custom: IVariantsItems
}

namespace appButtonModel {
    export const themes = (shopDesign: IShopDesign): Ithemes => {
        const defaults: ButtonProps = {
            backgroundColor: shopDesign.textColorParagraphs,
            color: shopDesign.backgroundBody,
            border: "1px solid #555",
            fontFamily: shopDesign.fontfamily,
            boxShadow: "unset !important",
            _hover: {
                backgroundColor: "none"
            },
        }

        const custom: ButtonProps = {
            backgroundColor: "#222",
            color: "#FFF",
            _hover: {
                backgroundColor: "#000"
            }
        }

        return {
            [ShopThemeEnum.THEME_1]: defaults,
            [ShopThemeEnum.THEME_2]: defaults,
            [ShopThemeEnum.THEME_SKALE]: custom,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: custom,
            [ShopThemeEnum.THEME_CASPER]: custom,
            [ShopThemeEnum.THEME_POLYGON]: {},
        }
    }

    export const variants = (shopDesign: IShopDesign): any => {
        const styles: IVariants = {
            default: {
                outline: {
                    background: 'none',
                    border: `1px solid ${shopDesign.textColorParagraphs}`,
                    color: shopDesign.textColorParagraphs,
                    _hover: {
                        background: 'none'
                    }
                }
            },
            custom: {
                outline: {
                    background: 'none',
                    border: '1px solid #333',
                    color: '#333',
                    _hover: {
                        background: 'none'
                    }
                }
            },
        }
        return {
            [ShopThemeEnum.THEME_1]: styles.default,
            [ShopThemeEnum.THEME_2]: styles.default,
            [ShopThemeEnum.THEME_SKALE]: styles.custom,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: styles.custom,
            [ShopThemeEnum.THEME_CASPER]: styles.custom,
            [ShopThemeEnum.THEME_POLYGON]: styles.custom,
        }
    }
}

export default appButtonModel