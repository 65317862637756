import { Box, Flex } from '@chakra-ui/react'
import { IgetProductPublicService } from 'apis/product/interface'
import { getProductPublicService } from 'apis/product/services'
import { IcheckRulesetService } from 'apis/ruleset/interface'
import { checkRulesetService } from 'apis/ruleset/services'
import AppContainer from 'components/common/container/AppContainer'
import { useProfile } from 'hooks/useProfile/useProfile'
import { useShopStore } from 'lib/stores/shop/shop'
import { appDeveloment } from 'lib/utils/app/variables'
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import productPageContext, { IproductPageState, productPageState } from './context'
import productPageModel from './model'
import ProductDescription from './parts/description/ProductDescription'
import ProductDetails from './parts/details/ProductDetails'
import ProductPageLoading from './parts/loading/ProductPageLoading'
import ProductSlider from './parts/slider/ProductSlider'

function ProductPage() {
    const { mutateAsync, isLoading } = useMutation((params: IgetProductPublicService) => getProductPublicService(params))
    const checkeRuleService = useMutation((params: IcheckRulesetService) => checkRulesetService(params))
    const { shopData: { name } } = useShopStore();
    const params = useParams()
    const [States, setStates] = useState<IproductPageState>(productPageState)
    const { getFirstOption, findSkuAsOption } = productPageModel
    const { profile } = useProfile()

    const updateState = (key: string, value: any) => setStates((prev: IproductPageState) => ({ ...prev, [key]: value }))
    const updateOption = (key: string, value: any) => setStates((prev: IproductPageState) => ({ ...prev, option: { ...prev.option, [key]: value } }))
    const updateRuleset = (key: string, value: any) => setStates((prev: IproductPageState) => ({ ...prev, ruleset: { ...prev.ruleset, [key]: value } }))

    // Set default option color and size
    const initialOptions = (data: any) => {
        const option = getFirstOption(data?.skuIDs[0])
        updateOption("color", option.color)
        updateOption("size", option.size)
    }

    // Get product as productId in route
    const fetch = useCallback(async () => {
        try {
            if (!params.productId) throw Error('Please provide product')
            const data = await mutateAsync({ productID: params.productId, shopname: name })
            setStates(prev => ({ ...prev, product: data?.data?.data }))
            initialOptions(data?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }, [params.productId, name])

    useEffect(() => { if (name && params.productId) fetch() }, [params.productId, name])
    useEffect(() => updateRuleset('loading', checkeRuleService.isLoading), [checkeRuleService.isLoading])

    // Get sku as options
    useEffect(() => {
        const sku = States?.product?.product_type === "DIGITAL" ? States?.product?.skuIDs[0] : findSkuAsOption({ color: States.option.color, size: States.option.size, skuIDs: States.product?.skuIDs })
        updateState('sku', sku)
    }, [States.option, States.product])

    // Handle rule set
    useEffect(() => {
        const ruleset = States?.product?.ruleSet
        if (ruleset && profile && profile?.walletType === ruleset.type) {
            checkeRuleService.mutate({ address: profile?.walletAddress || "", chain: profile?.walletType || "", network: appDeveloment ? "TESTNET" : "MAINNET", rule: { gated: ruleset?.gated, redeemedNFTs: ruleset?.redeemedNFTs, rules: ruleset?.rules } }, {
                onSuccess: res => updateRuleset('data', res?.data?.data)
            })
        }
    }, [States.product, profile])

    return (
        <>
            <productPageContext.Provider value={{ states: States, methods: { updateOption, updateState } }}>
                <Flex justifyContent="center" paddingTop="50px">
                    {isLoading ? <ProductPageLoading /> : (
                        <AppContainer props={{ gap: "80px", flexDirection: "column" }}>
                            <Flex flexDirection={{ base: "column", md: "row" }} gap={{ base: "30px", md: "80px" }}>
                                <Box width={{ base: "100%", md: "40%" }}><ProductSlider /></Box>
                                <Box width={{ base: "100%", md: "60%" }}><ProductDetails /></Box>
                            </Flex>
                            <Box><ProductDescription /></Box>
                        </AppContainer>
                    )}
                </Flex>
            </productPageContext.Provider>
        </>
    )
}

export default ProductPage