import { ISelectedShipping } from "lib/models/shipping";
import { createApiReq } from "./api-utils";
import { PaymentsTypeEnum } from "lib/models/shop";
export const postCreateCheckout = (orderId: string) =>
  createApiReq(`checkout`, true, {
    orderID: orderId,
  });

export const postCreateOrder = () => createApiReq(`checkout/create-order`, true, null);

export const postAnonymousCreateOrder = (cartId: string, email: string) =>
  createApiReq(`checkout/public/anonymous-cart/${cartId}/create-order`, false, {
    email: email,
  });

export const confirmPayment = (cartId: string, orderId: string) =>
  createApiReq(`payment/confirm`, false, {
    cartId: cartId,
    orderId: orderId,
  });
export const postAddCheckoutAddress = (addressBookId: string) => {
  return createApiReq(`checkout/add-address`, true, {
    addressBookID: addressBookId,
  });
};
//
export const getCartShippingRates = () => {
  return createApiReq(`checkout/shippingrate`, true, null);
};

export const getAnonymousShippingRates = (cartId: string) => createApiReq(`checkout/public/anonymous-cart/${cartId}/shipping-rate`, false, null);

export const postAddShippingRate = (data: ISelectedShipping[]) => {
  return createApiReq(`checkout/shippingrate`, true, {
    rates: data,
  });
};

export const postCreateAnonymousCheckout = (cartId: string, email: string, orderId: string) =>
  createApiReq(`checkout/public/anonymous-cart/${cartId}`, false, {
    email: email,
    orderID: orderId,
  });

export const postAddShippingRateToAnonymous = (cartId: string, data: ISelectedShipping[]) =>
  createApiReq(`checkout/public/anonymous-cart/${cartId}/shipping-rate`, false, {
    rates: data,
  });

export const postCreateClientSecret = () => createApiReq(`checkout/client-secret`, true, null);

export const postCreateAnonymousClientSecret = (cartId: string, email: string) =>
  createApiReq(`checkout/public/anonymous-cart/${cartId}/client-secret`, false, {
    email: email,
  });

export const postCreateCryptoCheckout = (walletAddress: string, paymentType: PaymentsTypeEnum, token: string, cartID: string) => createApiReq(`checkout/v2/payment/${paymentType}/${token}/${cartID}`, true, {
  walletAddress: walletAddress
});

export const postCreateAnonymousCryptoCheckout = (cartId: string, walletAddress: string, email: string, paymentType: PaymentsTypeEnum, token: string) => {
  return createApiReq(`checkout/v2/public/anonymous-cart/${cartId}/payment/${paymentType}/${token}`, false, {
    email: email,
    walletAddress: walletAddress
  });
};
