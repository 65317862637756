import { Flex } from '@chakra-ui/react';
import AppButton from 'components/common/form/button/AppButton';
import AppInput from 'components/common/form/maker/parts/input/AppInput';
import { useToastify } from 'context/toastify/ToastifyContext';
import useAppCart from 'hooks/_refactor/cart/useAppCart';
import { useShopStore } from 'lib/stores/shop/shop';
import useAppStore from 'lib/stores/_refactor/cart/cartStore';
import { useState } from 'react';
import giftCardDefaultModel from './model';

function GiftCardDefault() {
  const { errorToast, successToast } = useToastify()
  const [States, setStates] = useState({ code: '', loading: false })
  const { discount } = useAppCart()
  const { states: { cart } } = useAppStore()
  const { themes } = giftCardDefaultModel
  const { shopTheme } = useShopStore()

  const updateState = (key: string, value: any) => setStates(prev => ({ ...prev, [key]: value }))

  const apply = async () => {
    try {
      if (!cart?.canApplyGiftCard) throw new Error('Gift card cannot be applied to the cart.');
      updateState('loading', true)
      await discount({ code: States.code })
      successToast('Code applied')
      updateState('loading', false)
      updateState('code', '')
    } catch (error: any) {
      updateState('loading', false)
      errorToast(error?.response?.data?.data?.message || error.message || 'Oops somthing wrong')
    }
  }

  return (
    <Flex w={"full"} alignItems={"stretch"} {...themes[shopTheme].flex}>
      <AppInput
        {...themes[shopTheme].text}
        height={themes[shopTheme].button?.height}
        textAlign="center"
        placeholder='Gift Card or discount code'
        onChange={e => updateState('code', e.target.value)}
      />
      <AppButton p={"8px 26px"} {...themes[shopTheme].button} isDisabled={!States.code.length || !cart?.canApplyGiftCard} isLoading={States.loading} onClick={apply}>Apply</AppButton>
    </Flex >
  )
}

export default GiftCardDefault