import React, { useCallback } from 'react'
import { Box, HStack, Icon, IconProps, StackProps } from '@chakra-ui/react'
import HeaderPopover from './parts/popover/HeaderPopover';
import CartHeader from './parts/modals/cart/CartHeader';
import HeaderIconCart from './parts/icons/cart/HeaderIconCart';
import HeaderIconProfile from './parts/icons/profile/HeaderIconProfile';
import ProfileHeader from './parts/modals/profile/ProfileHeader';
import { useProfile } from 'hooks/useProfile/useProfile';
import { useShopStore } from 'lib/stores/shop/shop';

interface IProps {
    icons: {
        cart?: any
        notification?: any
        profile?: any
    }
    color: string
    props?: {
        icon?: IconProps
        flex?: StackProps
    }
}
function HeaderIcons({ icons: { cart, notification, profile }, props, color }: IProps) {
    const profileHook = useProfile()
    const { shopData: { template_options } } = useShopStore();
    const optionIcon = template_options?.['--dlk-hdr']?.['--dlk-hdr-icons']

    const icon = useCallback((icon: any, props: any) => <Icon as={icon} cursor="pointer" width={{ base: "30px", md: "35px" }} height={{ base: "30px", md: "35px" }} stroke={color} {...props?.icon} {...props} />, [color, props])

    return (
        <HStack alignItems="center" spacing={{ base: "15px", md: "23px", lg: "32px" }} position="relative" top="4px" {...props?.flex} {...optionIcon?.['--dlk-hdr-icons-styles']} >
            {notification && <Box>{icon(notification, optionIcon?.['--dlk-hdr-icons-notification'])}</Box>}
            {cart && <Box><HeaderPopover icon={<HeaderIconCart icon={icon(cart, optionIcon?.['--dlk-hdr-icons-cart'])} />} content={<CartHeader />} /></Box>}
            {profile && <Box>{profileHook.profile ? <HeaderPopover icon={icon(profile, optionIcon?.['--dlk-hdr-icons-profile'])} content={<ProfileHeader />} /> : <HeaderIconProfile icon={icon(profile, optionIcon?.['--dlk-hdr-icons-profile'])} />}</Box>}
        </HStack>
    )
}

export default HeaderIcons