import { useToastify } from "../../context/toastify/ToastifyContext";
import axiosInstance from "apis/axiosConfig";

type HookProps = {
  url: string;
  token?: string;
  body?: any;
};
// recommit

export const getApi = async ({ url, token, body }: HookProps) => {
  return axiosInstance
    .get(`${url}`, {
      ...(body && { ...body }),
    })
    .then((res) => res.data.data)
    .catch((error) => {
      return null;
    });
};

// this hook provide function for call apis
export function useApi() {
  const { errorToast } = useToastify();

  const postApi = async ({ url, token, body }: HookProps) => {
    try {
      const res = await axiosInstance.post(
        `${url}`,
        { ...(body && { ...body }) },
      );
      return res.data.data;
    } catch (err: any) {
      if (err?.response?.data) {
        errorToast(err?.response?.data?.data?.message);
      }
      else {
        errorToast(err?.message);
      }
      return false;
    }
  };

  const patchApi = async ({ url, token, body }: HookProps) => {
    try {
      const res = await axiosInstance.patch(
        `${url}`,
        { ...(body && { ...body }) },
      );
      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        errorToast(err?.response?.data?.data?.message);
      } else {
        errorToast(err.message);
      }
      return false;
    }
  };

  const getApi = async ({ url, token, body }: HookProps) => {
    try {
      const res = await axiosInstance.get(`${url}`, {
        ...(body && { ...body }),
      });

      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        errorToast(err?.response?.data?.data?.message);
      } else {
        errorToast(err.message);
      }
      return false;
    }
  };

  const deleteApi = async ({ url, token, body }: HookProps) => {
    try {
      const res = await axiosInstance.delete(`${url}`, {
        ...(body && { data: body }),
      });
      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        errorToast(err?.response?.data?.data?.message);
      } else {
        errorToast(err.message);
      }
      return false;
    }
  };

  const putApi = async ({ url, token, body }: HookProps) => {
    try {
      const res = await axiosInstance.put(
        `${url}`,
        { ...(body && { ...body }) },
      );
      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        errorToast(err?.response?.data?.data?.message);
      }
      if (err.message) {
        errorToast(err.message);
      }
      return false;
    }
  };

  return {
    postApi,
    patchApi,
    getApi,
    deleteApi,
    putApi,
  };
}
