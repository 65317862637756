import { Box, Flex, Image, VStack } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography';
import React, { useContext } from 'react'
import paymentSuccessContext from '../../context';

function PaymentPurchase() {
    const { order } = useContext(paymentSuccessContext)

    return (
        <VStack align="stretch" spacing="20px">
            <AppTypography fontSize="16px" fontWeight="600">Items Purchased</AppTypography>
            {order?.items?.map((el: any, key: number) => (
                <Flex key={key} justifyContent="space-between" flexDirection={{ base: 'column', sm: 'row' }} gap="10px" alignItems="start">
                    <Flex alignItems="start" gap="5px" width={{ base: "100%", sm: "40%" }}>
                        <Image src={el?.image} width="48px" />
                        <VStack align="stretch" spacing="5px">
                            <AppTypography fontSize="14px">{el?.title}</AppTypography>
                            <AppTypography fontSize="14px" opacity=".5">{el?.shippingType}</AppTypography>
                        </VStack>
                    </Flex>
                    <Flex justifyContent="space-between" width={{ base: "100%", sm: "50%" }}>
                        <Flex alignItems="center" gap="10px">
                            <Box backgroundColor={el?.color} width="25px" height="25px" borderRadius="100%"></Box>
                            <AppTypography fontSize="14px">{el?.size}</AppTypography>
                        </Flex>
                        <AppTypography fontSize="14px">X{el?.quantity}</AppTypography>
                        <AppTypography fontSize="14px">${parseInt(el?.price).toFixed(2)}</AppTypography>
                    </Flex>
                </Flex>
            ))
            }
        </VStack >
    )
}

export default PaymentPurchase