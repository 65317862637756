import { Stack, VStack } from '@chakra-ui/react'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'

function PurchaseHistoryLoading() {
    const generateSkeletons = () => Array.from({ length: 2 }).map((_, index) =>
        <VStack key={index} w={"full"} maxW="820px" borderRadius="16px" border="2px solid" p={{ base: "14px", md: "64px 48px" }}>
            <Stack direction={{ base: "column", md: "row" }} justify={"space-between"} w="full">
                <AppSkeleton isLoaded={false} width={{ md: "50%", base: "100%" }} height={{ md: "24px", base: "18px" }} />
                <AppSkeleton isLoaded={false} width={{ md: "50%", base: "100%" }} height={{ md: "24px", base: "18px" }} />
            </Stack>
            <Stack direction={{ base: "column", md: "row" }} justify={"space-between"} w="full">
                <AppSkeleton isLoaded={false} width={{ md: "50%", base: "100%" }} height={{ md: "24px", base: "18px" }} />
                <AppSkeleton isLoaded={false} width={{ md: "50%", base: "100%" }} height={{ md: "24px", base: "18px" }} />
            </Stack>
        </VStack>
    )

    return <VStack w={"full"} maxW="820px" spacing={{ base: "16px", md: "32px" }}>{generateSkeletons()}</VStack>
}

export default PurchaseHistoryLoading