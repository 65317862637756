import { Box, Flex, Hide, Image, VStack } from '@chakra-ui/react';
import AppSeperator from 'components/common/seperator/AppSeperator';
import AppTypography from 'components/common/typography/AppTypography';
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate';
import { useShopStore } from 'lib/stores/shop/shop';
import useAppStore from 'lib/stores/_refactor/cart/cartStore';
import { Link } from 'react-router-dom';
import purchaseDefaultModel from './model';

function PurchaseDefault() {
    const { states: { cart } } = useAppStore()
    const { route } = useCustomNavigate()
    const { themes } = purchaseDefaultModel
    const { shopTheme } = useShopStore()

    return (
        <VStack {...themes[shopTheme].box} align="stretch" spacing="15px">
            <Flex justifyContent="center">
                <AppTypography fontSize={{ base: "1.2rem", md: "1.6rem" }} fontWeight="bold">Your Purchase</AppTypography>
            </Flex>
            <AppSeperator {...themes[shopTheme].seprator} />
            {cart?.items.map((el, index) => <Flex alignItems={"center"} gap={"12px"} key={index}>
                <Hide below="md">
                    <Box flexShrink={0}>
                        <Link to={route('product/' + el?.product?._id)} target="_blank"><Image src={el.product.m2m_preview || el.product.image} width="60px" height="60px" borderRadius="4px" /></Link>
                    </Box>
                </Hide>
                <Flex justifyContent={"space-between"} alignItems={{ base: "flex-start", md: "center" }} gap={{ base: "8px" }} flex={1}>
                    <AppTypography {...themes[shopTheme].text} width={{ base: "75%", md: "50%" }}>
                        {`${el.product.title || ""} ${el.options.size.caption ? `- ${el.options.size.caption}` : ""} ${el.options.color.caption ? `- ${el.options.color.caption}` : ""}`}
                    </AppTypography>
                    <Hide below="md">
                        <AppTypography {...themes[shopTheme].text}>{`x${el.options.quantity}`}</AppTypography>
                    </Hide>
                    <AppTypography whiteSpace={"nowrap"} {...themes[shopTheme].text}>{`$ ${el.totals.priceItem ? el.totals.priceItem.toFixed(2) : 0} USD`}</AppTypography>
                </Flex>
            </Flex >
            )}
        </VStack>
    )
}

export default PurchaseDefault