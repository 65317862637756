import React from 'react'
import { Box, VStack } from '@chakra-ui/react'
import appThemes from 'lib/models/themes/appThemes'
import { useShopStore } from 'lib/stores/shop/shop';
import HomepageContentDefault from './parts/content/HomepageContent';

function Homepage() {
    const { shopTheme } = useShopStore()

    return (
        <VStack align="stretch">
            <Box>{appThemes.page.homepage.hiro[shopTheme] || appThemes.page.homepage.hiro['theme-1']}</Box>
            <Box paddingTop={{ base: "20px", md: "40px" }}><HomepageContentDefault /></Box>
        </VStack>
    )
}

export default Homepage