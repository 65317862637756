import { VStack } from '@chakra-ui/react'
import AppSeperator from 'components/common/seperator/AppSeperator'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'

const PurchaseHistoryHeader = () => {
  return (
    <VStack w={'full'} align="start">
        <AppTypography fontSize={{base:'20px',md:'26px'}} fontWeight='700'>Purchase history</AppTypography>
        <AppSeperator/>
    </VStack>
  )
}

export default PurchaseHistoryHeader