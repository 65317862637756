import { Text } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'

interface IProps {
    message: string
}
function AppErrorBadge({ message }: IProps) {
    return (
        <>
            {message && <AppTypography fontSize="12px" color='red.300'>{message}</AppTypography>}
        </>
    )
}

export default AppErrorBadge