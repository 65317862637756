import { ShopThemeEnum } from "lib/models/shop";
import CasperBasicButton from "./themes/casper/CasperBasicButton";
import ThemeBasedComponent from "../themeBasedComponent/ThemeBasedComponent";
import UnstoppableBasicButton from "./themes/unstoppable/UnstoppableBasicButton";
import ThemeTwoBasicButton from "./themes/theme-two/ThemeTwoBasicButton";

export const BUTTON_TYPE = {
  DARK: "DARK",
  LIGHT: "LIGHT",
};

const BasicButton = ({
  width,
  maxWidth,
  type,
  click,
  children,
  loading,
  borderRadius,
  isDisable,
  height,
}: {
  width: any;
  maxWidth: any;
  type: string;
  click: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  borderRadius?: string;
  isDisable?: boolean;
  height?: string;
}) => {
  const isLight = type === BUTTON_TYPE.LIGHT;

  const componentsList = [
    {
      theme: ShopThemeEnum.THEME_UNSTOPPABLE,
      component: (
        <UnstoppableBasicButton
          click={click}
          children={children ? children : undefined}
          isLight={isLight}
          width={width ? width : undefined}
          maxWidth={maxWidth ? maxWidth : undefined}
          loading={loading ? loading : undefined}
          borderRadius={borderRadius ? borderRadius : undefined}
          isDisabled={isDisable ? isDisable : undefined}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_POLYGON,
      component: (
        <UnstoppableBasicButton
          click={click}
          children={children ? children : undefined}
          isLight={isLight}
          width={width ? width : undefined}
          maxWidth={maxWidth ? maxWidth : undefined}
          loading={loading ? loading : undefined}
          borderRadius={borderRadius ? borderRadius : undefined}
          isDisabled={isDisable ? isDisable : undefined}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_POLYGON,
      component: (
        <UnstoppableBasicButton
          click={click}
          children={children ? children : undefined}
          isLight={isLight}
          width={width ? width : undefined}
          maxWidth={maxWidth ? maxWidth : undefined}
          loading={loading ? loading : undefined}
          borderRadius={borderRadius ? borderRadius : undefined}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_CASPER,
      component: (
        <CasperBasicButton
          click={click}
          children={children ? children : undefined}
          isLight={isLight}
          width={width ? width : undefined}
          maxWidth={maxWidth ? maxWidth : undefined}
          loading={loading ? loading : undefined}
          borderRadius={borderRadius ? borderRadius : undefined}
          isDisabled={isDisable ? isDisable : undefined}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_SKALE,
      component: (
        <UnstoppableBasicButton
          click={click}
          children={children ? children : undefined}
          isLight={isLight}
          width={width ? width : undefined}
          maxWidth={maxWidth ? maxWidth : undefined}
          loading={loading ? loading : undefined}
          borderRadius={borderRadius ? borderRadius : undefined}
          isDisabled={isDisable ? isDisable : undefined}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_2,
      component: (
        <ThemeTwoBasicButton
          click={click}
          children={children ? children : undefined}
          isLight={isLight}
          width={width ? width : undefined}
          maxWidth={maxWidth ? maxWidth : undefined}
          loading={loading ? loading : undefined}
          borderRadius={borderRadius ? borderRadius : undefined}
          isDisabled={isDisable ? isDisable : undefined}
          height={height ? height : undefined}
        />
      ),
    },
    {
      theme: ShopThemeEnum.THEME_1,
      component: (
        <UnstoppableBasicButton
          click={click}
          children={children ? children : undefined}
          isLight={isLight}
          width={width ? width : undefined}
          maxWidth={maxWidth ? maxWidth : undefined}
          loading={loading ? loading : undefined}
          borderRadius={borderRadius ? borderRadius : undefined}
          isDisabled={isDisable ? isDisable : undefined}
        />
      ),
    },
  ];
  return <ThemeBasedComponent componentsList={componentsList} />;
};

export default BasicButton;
