export enum WALLET_TYPES {
    CASPER = "CASPER",
    UNSTOPPABLEDOMAIN = "UNSTOPPABLEDOMAIN",
    STACK = "STACK",
    UNISAT = "UNISAT",
    POLYGON = "POLYGON",
    XUMM = "XUMM",
    XRPLSIDECHAIN = "XRPLSIDECHAIN",
    BINANCE = "BINANCE",
    NEAR = "NEAR",
    BASE = "BASE",
    LINEA = "LINEA"
}

export interface IUser {
    walletAddress: string | "";
    customerShop: string;
    email: string | "";
    emailNotificationEnabled: boolean;
    publicKey: string;
    status: string;
    stripeCustomerID: string;
    walletType?: WALLET_TYPES;
    _id: string;
}

/*
{
  [shopname]:{token , user}
}
*/
export interface IUserData {
    [key: string]: {
        token: string;
        user: IUser;
    };
}
