import { Stack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";

interface ISummaryRow {
    label: string;
    content: string;
}

function DisplayRow({ label, content }: ISummaryRow) {
    return <Stack flex={2} direction={{ base: "column", md: "row" }} w="full" justify="start" fontSize={{ base: "14px", md: "16px" }}>
        <AppTypography flex={0.5}>{label}:</AppTypography>
        <AppTypography flex={1.5} textAlign="left">
            {content}
        </AppTypography>
    </Stack>
}

export default DisplayRow
