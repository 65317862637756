import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import AppContainer from 'components/common/container/AppContainer'
import HompageProducts from './parts/products/HompageProducts'
import HomepageSidebar from './parts/sidebar/HomepageSidebar'
import homepageDefaultContext, { homepageDefaultState, IhomepageDefaultState } from './context'
import homepageContentDefaultModel from './model'
import { useShopStore } from 'lib/stores/shop/shop'

function HomepageContentDefault() {
  const { shopTheme, shopDesign } = useShopStore();
  const [States, setStates] = useState<IhomepageDefaultState>(homepageDefaultState)
  const { themes } = homepageContentDefaultModel

  const updateState = (key: string, value: any) => setStates((prev: IhomepageDefaultState) => ({ ...prev, [key]: value }))

  useEffect(() => setStates((prev: IhomepageDefaultState) => ({ ...prev, theme: themes(shopTheme, shopDesign) })), [shopTheme, shopDesign])

  return (
    <homepageDefaultContext.Provider value={{ States, updateState }}>
      <Flex justifyContent="center">
        <AppContainer props={{ gap: "40px", flexDir: { base: "column", md: "row" } }}>
          <Box width={{ base: "100%", md: "33%", lg: "23%" }}><HomepageSidebar /></Box>
          <Box width={{ base: "100%", md: "67%", lg: "77%" }}><HompageProducts /></Box>
        </AppContainer>
      </Flex>
    </homepageDefaultContext.Provider>
  )
}

export default HomepageContentDefault