import { FlexProps } from "@chakra-ui/react"
import { IAppButton } from "components/common/form/button/AppButton"
import { ShopThemeEnum } from "lib/models/shop"

interface Itheme {
    buttons?: IAppButton
    flex?: FlexProps
    addressBox?: FlexProps
}

interface IthemesOutput {
    [propname: string]: Itheme
}

namespace checkoutAddress {
    const theme: IthemesOutput = {
        default: {},
        custom: {
            buttons: { width: "200px" },
            flex: { gap: "30px" },
            addressBox: { backgroundColor: "#FFF", border: "1px solid rgb(233, 233, 226)" }
        },
    }
    export const themes: IthemesOutput = ({
        [ShopThemeEnum.THEME_1]: theme.default,
        [ShopThemeEnum.THEME_2]: theme.default,
        [ShopThemeEnum.THEME_SKALE]: theme.custom,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: theme.custom,
        [ShopThemeEnum.THEME_CASPER]: theme.custom,
        [ShopThemeEnum.THEME_POLYGON]: theme.custom,
    })
}

export default checkoutAddress