import AppTypography from 'components/common/typography/AppTypography'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React from 'react'
import { Navigate } from 'react-router-dom'
import CheckoutCard from '../../parts/layout/card/CheckoutCard'
import CheckoutLayoutPage from '../../parts/layout/page/CheckoutLayoutPage'
import CheckoutShipping from '../../parts/steps/shipping/CheckoutShipping'

function ShippingPage() {
  const { shopNavigate, route } = useCustomNavigate()
  const { states: { cart } } = useAppStore()

  return cart?.address?._id ? (
    <CheckoutLayoutPage title='Shipping page'>
      <CheckoutCard>
        <CheckoutShipping
          buttons={[
            {
              children: <AppTypography>Back</AppTypography>,
              props: { variant: 'outline', onClick: () => shopNavigate('checkout/address') }
            }
          ]}
          onSuccess={() => shopNavigate('checkout/payment')}
        />
      </CheckoutCard>
    </CheckoutLayoutPage>
  ) : <Navigate to={route('checkout/address')} />
}

export default ShippingPage