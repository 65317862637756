import { IAppButton } from "components/common/form/button/AppButton"
import { ShopThemeEnum } from "lib/models/shop"

interface Itheme {
    buttons?: IAppButton
}

interface IthemesOutput {
    [propname: string]: Itheme
}

namespace checkoutShippingModel {
    const theme: IthemesOutput = {
        default: {},
        custom: {
            buttons: { width: "200px" },
        },
    }
    export const themes: IthemesOutput = ({
        [ShopThemeEnum.THEME_1]: theme.default,
        [ShopThemeEnum.THEME_2]: theme.default,
        [ShopThemeEnum.THEME_SKALE]: theme.custom,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: theme.custom,
        [ShopThemeEnum.THEME_CASPER]: theme.custom,
        [ShopThemeEnum.THEME_POLYGON]: theme.custom,
    })
}

export default checkoutShippingModel