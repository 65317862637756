import { Box, Flex, VStack } from '@chakra-ui/layout'
import AppTypography from 'components/common/typography/AppTypography'
import useAppTheme from 'hooks/_refactor/theme/useAppTheme'
import { useShopStore } from 'lib/stores/shop/shop'
import variantIDs from 'lib/utils/statics/variantID'
import productModel from 'pages/_refactors/homepage/parts/content/parts/products/parts/product/model'
import productPageContext from 'pages/_refactors/product/context'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import ProductDetailTitles from '../titles/ProductDetailTitles'
import productSizesModel from './model'

function ProductSizes() {
    const { shopTheme, shopDesign } = useShopStore();
    const { themes } = productSizesModel
    const { states: { product, option: { size, color }, sku }, methods: { updateOption } } = useContext(productPageContext)
    const { isDefaultTheme } = useAppTheme()

    const skuIDsMatchColor = useMemo(() => product?.skuIDs.filter((sku: any) => sku?.options.find((options: any) => options.caption === color)).map((el: any) => el?.options.find((opt: any) => opt.variantID === variantIDs.size._id)).map((el: any) => el?.caption), [product, color])

    const sizes = useMemo(() => productModel.getOptions({ skuIDs: product?.skuIDs, type: "size" }), [product, skuIDsMatchColor, color])

    useEffect(() => {
        if (!sku && sizes.length && color) updateOption('size', skuIDsMatchColor[0])
    }, [sku, sizes, color])

    const hasColor = useCallback(() => product?.skuIDs.find((el: any) => el.options.find((item: any) => item.variantID === variantIDs.color._id)), [product])

    return (
        <>
            {sizes.length ? (
                <VStack align="stretch">
                    <ProductDetailTitles text='Size' />
                    <Flex flexWrap="wrap" gap="10px">
                        {sizes.map((el, key) => {
                            const isActive = hasColor() ? skuIDsMatchColor.includes(el.caption) : true
                            return (
                                <Box key={key}
                                    opacity={isActive ? '1' : '0.3'}
                                    backgroundColor={el.caption === size ? themes(shopDesign)[shopTheme].active.box : ""}
                                    onClick={() => isActive ? updateOption('size', el.caption) : {}}
                                    padding={{ base: "6px 15px", lg: "10px 20px" }}
                                    cursor={isActive ? "pointer" : "auto"}
                                    borderRadius="100px"
                                    position="relative"
                                    overflow="hidden"
                                    {...themes(shopDesign)[shopTheme].box}
                                >
                                    <AppTypography {...(!isDefaultTheme && el.caption === size) ? { color: themes(shopDesign)[shopTheme].active.textColor } : {}} >{el.caption}</AppTypography>
                                </Box>
                            )
                        })}
                    </Flex>
                </VStack>
            ) : null}
        </>
    )
}

export default ProductSizes