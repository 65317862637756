import { createContext } from "react";

export interface ImintToMerchStates {
    source: string
    position: string
    artwork: string
    step: 'source' | 'position' | 'artwork' | 'preview'
    removeBackground: boolean
}

export const mintToMerchStates: ImintToMerchStates = {
    source: '',
    step: 'source',
    artwork: '',
    position: '',
    removeBackground: false
}

interface IProps {
    states: ImintToMerchStates
    methods: {
        updateStates: Function,
        closed: Function
    }
}

const mintToMerchContext = createContext<IProps>({
    states: mintToMerchStates,
    methods: {
        updateStates: () => { },
        closed: () => { }
    }
})

export default mintToMerchContext