import { Flex, VStack } from '@chakra-ui/react'
import AppSeperator from 'components/common/seperator/AppSeperator'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'
import AppTypography from 'components/common/typography/AppTypography'

function OrderModalSkeleton() {
    return (
        <VStack spacing={"24px"} align="stretch">
            <Flex direction={"column"} gap={"24px"}>
                <AppTypography w={'full'} textAlign={'left'} fontSize={{ base: "16px", md: "18px" }} fontWeight='bold'>Order Summary</AppTypography>
                <AppSkeleton isLoaded={false} width={"100%"} height={{ base: "21px", md: "24px" }} />
                <AppSkeleton isLoaded={false} width={"100%"} height={{ base: "21px", md: "24px" }} />
                <AppSkeleton isLoaded={false} width={"100%"} height={{ base: "21px", md: "24px" }} />
            </Flex>
            <AppSeperator />
            <Flex direction={"column"} gap={"24px"}>
                <AppTypography w={'full'} textAlign={'left'} fontSize={{ base: "16px", md: "18px" }} fontWeight='bold'>Items</AppTypography>
                <Flex align={"flex-start"} gap={"12px"}>
                    <AppSkeleton flexShrink={0} isLoaded={false} width={"48px"} height={"48px"} />
                    <AppSkeleton flex={1} isLoaded={false} height={{ base: "21px", md: "24px" }} />
                </Flex>
            </Flex>
            <AppSeperator />
            <Flex direction={"column"} gap={"24px"}>
                <AppTypography w={'full'} textAlign={'left'} fontSize={{ base: "16px", md: "18px" }} fontWeight='bold'>Payment Details</AppTypography>
                <Flex direction={"column"} gap={"18px"}>
                    <AppSkeleton isLoaded={false} width={"100%"} height={{ base: "21px", md: "24px" }} />
                    <AppSkeleton isLoaded={false} width={"100%"} height={{ base: "21px", md: "24px" }} />
                    <AppSkeleton isLoaded={false} width={"100%"} height={{ base: "21px", md: "24px" }} />
                    <AppSkeleton isLoaded={false} width={"100%"} height={{ base: "21px", md: "24px" }} />
                </Flex>
                <AppSkeleton isLoaded={false} width={"100px"} height={"18px"} />
            </Flex>
        </VStack>
    )
}

export default OrderModalSkeleton