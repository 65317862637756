import React, { useContext, useRef } from 'react'
import { Box, Flex, VStack } from '@chakra-ui/layout'
import AppTypography from 'components/common/typography/AppTypography'
import { useShopStore } from 'lib/stores/shop/shop'
import mintPositionUploadModel from './model'
import { Input } from '@chakra-ui/input'
import { useMutation } from 'react-query'
import { cdnUploadService } from 'apis/cdn/services'
import mintToMerchContext from '../../../../../context'
import { Image } from '@chakra-ui/image'
import AppButton from 'components/common/form/button/AppButton'
import AppLoading from 'components/common/loading/AppLoading'

function MintPositionUpload() {
    const { mutateAsync, isLoading } = useMutation((props: any) => cdnUploadService(props))
    const { states: { artwork }, methods: { updateStates } } = useContext(mintToMerchContext)
    const { shopTheme, shopDesign: { textColorParagraphs, backgroundBody } } = useShopStore()
    const { icon } = mintPositionUploadModel
    const element = useRef<any>(null)

    const upload = async (e: any) => {
        try {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append("image", file);
            const query = await mutateAsync(formData)
            updateStates("artwork", query?.data?.original);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <VStack align="stretch">
                <AppTypography fontSize="14px">Upload your own artwork</AppTypography>
                {artwork ? (
                    <Flex justifyContent="space-between" alignItems="center">
                        <Image src={artwork} width="50px" height="50px" borderRadius="8px" />
                        <AppButton size="sm" isLoading={isLoading} onClick={() => element.current.click()}>Upload Again</AppButton>
                    </Flex>
                ) : (
                    <Flex alignItems="center" cursor="pointer" onClick={() => element.current.click()} padding="17px 20px" borderRadius="8px" gap="15px" backgroundColor={backgroundBody}>
                        {isLoading ? <Box width="100%"><AppLoading /></Box> : (
                            <>
                                {icon(textColorParagraphs)}
                                <AppTypography fontSize="14px">Please upload JPG, JPEG, PNG</AppTypography>
                            </>
                        )}
                    </Flex>
                )}
            </VStack>
            <Input type="file" onChange={upload} ref={element} hidden />
        </>
    )
}

export default MintPositionUpload