import { ReactComponent as Discount } from "./discountProduct.svg";
import { ReactComponent as Gated } from "./gated.svg";
import { ReactComponent as Copy } from "./copy.svg";

const AppIcons = {
    Discount,
    Gated,
    Copy
};

export default AppIcons
