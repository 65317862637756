import { VStack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";

const CartPageMobileItemDetailEach = ({ title, value }: { title: string; value: React.ReactNode }) => {
    return (
        <VStack>
            <AppTypography>{title}</AppTypography>
            <AppTypography>{value}</AppTypography>
        </VStack>
    );
};
export default CartPageMobileItemDetailEach