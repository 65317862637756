import AppTypography from 'components/common/typography/AppTypography'
import { useCustomNavigate } from 'hooks/useCustomNavigate/useCustomNavigate'
import { useUserStore } from 'lib/stores/user/userStore'
import useAppStore from 'lib/stores/_refactor/cart/cartStore'
import React from 'react'
import { Navigate } from 'react-router-dom'
import CheckoutCard from '../../parts/layout/card/CheckoutCard'
import CheckoutLayoutPage from '../../parts/layout/page/CheckoutLayoutPage'
import CheckoutAddress from '../../parts/steps/address/CheckoutAddress'

function AddressPage() {
  const { route, shopNavigate } = useCustomNavigate()
  const { states: { cart } } = useAppStore();
  const { user } = useUserStore()

  return cart?.email || user?.email ? (
    <CheckoutLayoutPage title='Address page'>
      <CheckoutCard>
        <CheckoutAddress
          buttons={[
            {
              children: <AppTypography>Back</AppTypography>,
              props: { variant: 'outline', onClick: () => shopNavigate('checkout/cart') }
            }
          ]}
          onSuccess={() => shopNavigate('checkout/shipping')}
        />
      </CheckoutCard>
    </CheckoutLayoutPage>
  ) : <Navigate to={route('checkout/cart')} />
}

export default AddressPage