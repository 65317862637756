import { HStack, Image, VStack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import CasperCloud from "assets/icons/footer/casper/casper-cloud-image.png";
import DroplinkedLogo from "assets/icons/footer/casper/droplinked-logo.png";

const FooterCasperPowered = () => {
    return (
        <VStack>
            <HStack>
                <AppTypography fontSize="15px" fontWeight="700" color="#fff" fontFamily="Montserrat">
                    Powered By
                </AppTypography>
                <Image src={CasperCloud} w={{ base: "100px", xl: "120px" }} h={{ base: "27px", xl: "36px" }} />
            </HStack>
            <Image src={DroplinkedLogo} w={{ base: "180px", xl: "180px" }} h="auto" mx="auto" />
        </VStack>
    );
};

export default FooterCasperPowered;
