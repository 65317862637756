import { Td, Tr } from '@chakra-ui/react'
import AppTypography from 'components/common/typography/AppTypography'
import { ICartStoreItem } from 'lib/stores/_refactor/cart/interfaces'
import CartDeleteItemButton from '../../../parts/delete/CartDeleteItemButton'
import CartPageQuantity from '../../../parts/quantity/CartPageQuantity'
import CartPageProduct from '../product/CartPageProduct'

interface IProps {
  cartItem: ICartStoreItem
}
const CartPageTableRow = ({ cartItem }: IProps) => {
  return (
    <Tr>
      <Td><CartPageProduct cartItem={cartItem} /></Td>
      <Td><CartPageQuantity cartItem={cartItem} /></Td>
      <Td><AppTypography fontWeight="400" fontSize="14px" whiteSpace="nowrap">${cartItem?.totals.priceItem ? cartItem?.totals.priceItem.toFixed(2) : 0} USD</AppTypography></Td>
      <Td><AppTypography fontWeight="400" fontSize="14px" whiteSpace="nowrap">${cartItem?.totals.subTotal ? cartItem?.totals.subTotal.toFixed(2) : 0} USD</AppTypography></Td>
      <Td><CartDeleteItemButton itemId={cartItem?._id} /></Td>
    </Tr>
  )
}

export default CartPageTableRow