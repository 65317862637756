import { useState, useMemo } from "react";
import { Buffer } from "buffer";
import { useAuth, useOpenContractCall } from "@micro-stacks/react";
import { uintCV, bufferCV, standardPrincipalCV } from "micro-stacks/clarity";
//
import {
    postCreateCheckout,
    postCreateAnonymousCheckout,
    postCreateCryptoCheckout,
    postCreateAnonymousCryptoCheckout,
    postCreateOrder,
    postAnonymousCreateOrder,
    confirmPayment,
} from "apis/checkoutsApi";

import { check_balance_on_product } from "utils/casper/login-casper-wallet-utils";
import { postOrder, postPublicOrder } from "apis/orderApi";
import { useApi } from "hooks/useApi/useApi";
import { useToastify } from "context/toastify/ToastifyContext";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import { useUserStore } from "lib/stores/user/userStore";
import { useShopStore } from "lib/stores/shop/shop";
import { PaymentsTypeEnum } from "lib/models/shop";
import { PostConditionMode } from "@stacks/transactions";
import { appDeveloment } from "lib/utils/app/variables";
import { WALLET_TYPES } from "lib/models/user";
import { checkProductBalanceBase, checkProductBalanceXRP } from "lib/blockchain/xrp/xrpLogin";
import { checkProductBalancePolygon } from "lib/blockchain/polygon/polygon-login";
import { checkProductBalanceBinance } from "lib/blockchain/binance/binanceLogin";
import { getChain } from "hooks/useProfile/utils";
import { getNetworkProvider } from "lib/chains/chainProvider";
import { Chain, Network } from "lib/chains/Chains";
import { checkoutService, createClientSecretService } from "apis/payment/service";
import useAppStore from "lib/stores/_refactor/cart/cartStore";
import { checkoutPaymentChainsService, checkoutPaymentService } from "apis/checkout/service";
import useAppCart from "hooks/_refactor/cart/useAppCart";
import { orderPaymentService } from "apis/orders/services";

export function usePayment() {
    const { openAuthRequest } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [clientSecret, setClientSecret] = useState(null);
    const { postApi } = useApi();
    const { fetch, clear } = useAppCart();
    const { openContractCall } = useOpenContractCall();

    const isLoggedIn = useUserStore((state) => state.isLoggedIn);
    const profile = useUserStore((state) => state.user);
    const { states: { cart } } = useAppStore()
    const shopData = useShopStore((state) => state.shopData);
    const casperWalletAddress = useMemo(() => {
        let res = shopData?.paymentMethods?.find((e) => e.type === PaymentsTypeEnum.CASPER);
        return res && res?.isActive ? res?.destinationAddress : "";
    }, [shopData]);
    const { errorToast } = useToastify();
    const { shopNavigate } = useCustomNavigate();

    const getPaymentsTypes = () => {
        return shopData?.paymentMethods || [];
    };

    const confirmOrder = async () => {
        setIsLoading(true);
        let result;
        const cartId = cart?._id;
        if (isLoggedIn) {
            result = await postApi(postCreateOrder());
        } else {
            result = await postApi(postAnonymousCreateOrder(cartId, cart.email));
        }
        if (result && result?.orderID) {
            const orderId = result?.orderID;
            await postApi(confirmPayment(cartId, orderId));
            shopNavigate("success-payment/" + orderId);
        }
        setIsLoading(false);
    };

    // payment via stripe
    const payViaStripe = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setIsLoading(true);
                let result;
                result = await createClientSecretService({ cartId: cart._id, anonymous: !isLoggedIn, params: { email: cart.email } })
                const data = result?.data?.data
                if (result && data?.orderID && data?.client_secret) {
                    localStorage.setItem("orderId", JSON.stringify(data?.orderID));
                    setClientSecret(data?.client_secret);
                    resolve(data?.client_secret)
                }
                setIsLoading(false);
            } catch (error) {
                reject(error)
            }
        })
    };

    const donePayment = async (orderID, paymentIntent) => {
        try {
            setIsLoading(true);
            const anonymous = !isLoggedIn
            if (paymentIntent) await checkoutService({ anonymous, ...anonymous && { cartID: cart._id }, params: { orderID, ...anonymous && { email: cart.email } } });
            shopNavigate("success-payment/" + orderID);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const payViaStacks = async () => {
        let checkout;
        let sender;

        if (isLoggedIn) {
            checkout = await postApi(postCreateCryptoCheckout(PaymentsTypeEnum.STACKS));
        } else {
            checkout = await postApi(postCreateAnonymousCryptoCheckout(cart._id, cart.email, PaymentsTypeEnum.STACKS));
        }
        if (!profile || !profile.walletAddress || profile.walletType !== WALLET_TYPES.STACK) {
            await openAuthRequest({
                onFinish(session) {
                    if (appDeveloment) {
                        sender = session?.addresses?.testnet;
                    } else {
                        sender = session?.addresses?.mainnet;
                    }
                },
            });
        } else {
            sender = profile.walletAddress;
        }
        const heightBuff = Uint8Array.from(Buffer.from(checkout.heightBuff, "hex"));
        const rateBuff = Uint8Array.from(Buffer.from(checkout.rateBuff, "hex"));
        const signature = Uint8Array.from(Buffer.from(checkout.signature, "hex"));

        const amount = parseFloat(cart.totalPriceItems) * 1000;
        const shipping = parseFloat(cart.totalShippingAmount) * 1000;
        const tax = parseFloat(cart.taxAmount) * 1000;

        const receive = shopData.paymentMethods.find((method) => (method.type === "STACKS" ? method : null));
        const receiver = receive?.destinationAddress;
        const result = await openContractCall({
            contractAddress: appDeveloment ? "STTYMZEAQ7CGTGF8Z7EP62CEP1WCFSBH6E5YRJB1" : "SPTYMZEAQ7CGTGF8Z7EP62CEP1WCFSBH6D1J0NRB",
            contractName: "droplinked-gateway-v100",
            functionName: "pay",
            functionArgs: [
                uintCV(Number(Math.floor(amount))),
                uintCV(Number(Math.floor(shipping))),
                uintCV(Number(Math.floor(tax))),
                bufferCV(rateBuff),
                bufferCV(heightBuff),
                bufferCV(signature),
                standardPrincipalCV(sender),
                standardPrincipalCV(receiver),
            ],
            postConditionMode: PostConditionMode.Allow,
        });
        let orderResult;
        if (result) {
            if (isLoggedIn) {
                orderResult = await postApi(postOrder(result.txId, checkout.orderID, "stacks"));
            } else {
                orderResult = await postApi(postPublicOrder(result.txId, checkout.orderID, "stacks"));
            }
            if (orderResult) {
                clear();
                shopNavigate(`success-payment/${checkout.orderID}?txid=${result.txId}`);
            }
        }
    };

    const checkBalance = (walletType) => {
        switch (walletType) {
            case WALLET_TYPES.POLYGON:
                return checkProductBalancePolygon;
            case WALLET_TYPES.BINANCE:
                return checkProductBalanceBinance;
            case WALLET_TYPES.XRPLSIDECHAIN:
                return checkProductBalanceXRP;
            case WALLET_TYPES.BASE:
                return checkProductBalanceBase; //?
            case WALLET_TYPES.CASPER:
                return check_balance_on_product;
            default:
                return checkProductBalancePolygon;
        }
    };

    const payViaCasper = async () => {
        let sender;
        let publicKey;
        if (!profile || !profile.walletAddress || profile.walletType !== PaymentsTypeEnum.CASPER) {
            const provide = getNetworkProvider(getChain(PaymentsTypeEnum.CASPER), appDeveloment ? Network.TESTNET : Network.MAINNET, "");
            let data = await provide.walletLogin();
            sender = await data.account_hash;
            publicKey = await data.publicKey;
        } else {
            sender = profile.walletAddress;
            publicKey = profile?.publicKey;
        }

        const anonymous = !isLoggedIn
        let checkout = await (await checkoutPaymentService({ anonymous, cartId: cart._id, paymentType: PaymentsTypeEnum.CASPER, token: "CSPR", params: { email: cart.email || profile.email, walletAddress: sender } })).data

        if (checkout) {
            const balanceFunction = checkBalance(PaymentsTypeEnum.CASPER);
            const hasBalance = await balanceFunction(parseFloat(checkout.totalPrice), sender);
            if (!hasBalance) {
                // setIsLoading(false);
                // return errorToast("Payment failed due to insufficient funds.");
            }
        }

        let paymentResult;
        let deploy_hash;
        if (checkout) {
            const paymentData = {
                ...checkout?.paymentData,
                // eslint-disable-next-line no-undef
                amounts: checkout?.paymentData.amounts.map((amount) => BigInt(amount)),
            };
            paymentResult = getNetworkProvider(getChain(PaymentsTypeEnum.CASPER), appDeveloment ? Network.TESTNET : Network.MAINNET, publicKey);
            deploy_hash = await paymentResult.payment(
                // ["1000000000000000", "1000000000000000"],
                paymentData?.amounts,
                paymentData?.receivers,
                paymentData?.tokenIds,
                paymentData?.tokenAmounts,
                paymentData?.tokenReceiver,
                paymentData?.tokenSenders,
                paymentData?.erc20TokenContract,
                paymentData?.timestamp,
                paymentData?.signature
            );
        }

        let orderResult;
        if (deploy_hash) {
            if (isLoggedIn) {
                orderResult = await postApi(postOrder(deploy_hash, checkout?.orderID, PaymentsTypeEnum.CASPER.toLowerCase()));
            } else {
                orderResult = await postApi(postPublicOrder(deploy_hash, checkout?.orderID, PaymentsTypeEnum.CASPER.toLowerCase()));
            }
        }

        if (orderResult) {
            clear();
            shopNavigate(`success-payment/${checkout?.orderID}?${PaymentsTypeEnum.CASPER?.toLowerCase()}=${deploy_hash}`);
        }
    };

    const crypto_payment = async (walletType) => {
        const token = {
            [PaymentsTypeEnum.BASE]: 'ETH',
            [PaymentsTypeEnum.CASPER]: 'CSPR',
            [PaymentsTypeEnum.NEAR]: 'NEAR',
            [PaymentsTypeEnum.XRPLSIDECHAIN]: 'XRP',
            [PaymentsTypeEnum.POLYGON]: 'MATIC',
            [PaymentsTypeEnum.BINANCE]: 'BNB',
            [PaymentsTypeEnum.LINEA]: 'ETH',
            [PaymentsTypeEnum.BASE]: 'ETH',
        }
        let sender;
        if (!profile || !profile.walletAddress || profile.walletType !== walletType) {
            sender = await (await getNetworkProvider(getChain(walletType), appDeveloment ? Network.TESTNET : Network.MAINNET, "").walletLogin())[walletType === PaymentsTypeEnum.CASPER ? 'publicKey' : 'address']
        } else {
            sender = profile.walletAddress;
        }

        const anonymous = !isLoggedIn
        let checkout = await (await checkoutPaymentService({ anonymous, cartId: cart._id, paymentType: walletType, token: token[walletType], params: { email: cart.email || profile.email, walletAddress: sender } })).data?.data

        const paymentResult = getNetworkProvider(getChain(walletType), appDeveloment ? Network.TESTNET : Network.MAINNET, sender);
        const payment = await paymentResult?.payment(checkout?.paymentData);
        const body = {
            anonymous,
            chain: walletType.toLowerCase(),
            params: {
                cryptoAmount: parseInt(payment.cryptoAmount),
                deploy_hash: payment.deploy_hash,
                orderID: checkout?.orderID
            }
        }
        await orderPaymentService(body)
        shopNavigate(`success-payment/${checkout?.orderID}?${walletType?.toLowerCase()}=${payment.deploy_hash}`);
    };

    const payViaXumm = async () => {

    }

    const checkValidation = (selectedType) => {
        if (selectedType === PaymentsTypeEnum.XRPLSIDECHAIN) {
            return true
        }
        if (!PaymentsTypeEnum.hasOwnProperty(selectedType)) {
            return false;
        }
        return true;
    };

    const submitPayment = (selectedType) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!checkValidation(selectedType)) throw Error()
                setIsLoading(true);
                let result;
                if ([PaymentsTypeEnum.BINANCE, PaymentsTypeEnum.POLYGON, PaymentsTypeEnum.NEAR, PaymentsTypeEnum.CASPER, PaymentsTypeEnum.XRPLSIDECHAIN, PaymentsTypeEnum.SKALE, PaymentsTypeEnum.BASE, PaymentsTypeEnum.LINEA].includes(selectedType)) {
                    result = await crypto_payment(selectedType);
                } else if (selectedType === PaymentsTypeEnum.STRIPE) {
                    if (cart?.totalPriceCart === "0.00") {
                        result = confirmOrder();
                    } else {
                        result = await payViaStripe();
                    }
                } else if (selectedType === PaymentsTypeEnum.STACKS) {
                    result = await payViaStacks();
                } else if (selectedType === PaymentsTypeEnum.RIPPLE) {
                    result = await payViaXumm();
                }
                if (result) resolve(result)
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                reject(error)
            }
        })
    };

    // ****************************************************************  //

    return {
        paymentTypes,
        isLoading,
        clientSecret,
        donePayment, // call when payment done like after redirect
        getPaymentsTypes, // get avaialbe payments methods
        submitPayment, // this funciton get payment type and execute related functions
    };
}