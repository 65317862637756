import { Box } from '@chakra-ui/react'
import useAppCart from 'hooks/_refactor/cart/useAppCart'
import React from 'react'

interface IProps {
    icon: any
}
function HeaderIconCart({ icon }: IProps) {
    const { countItems } = useAppCart()
    return (
        <Box position="relative">
            {countItems ? <Box position="absolute" top="-5px" right="-5px" textAlign="center" justifyContent="center" fontSize="12px" backgroundColor="#111" color="#FFF" width="20px" height="20px" borderRadius="100%">{countItems}</Box> : null}
            {icon}
        </Box>
    )
}

export default HeaderIconCart