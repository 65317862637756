import * as React from "react";
import { Button, Spinner } from "@chakra-ui/react";
import { useShopStore } from "lib/stores/shop/shop";

const ThemeTwoBasicButton = ({
  width,
  maxWidth,
  isLight,
  click,
  children,
  loading,
  borderRadius,
  isDisabled,
  height,
}: {
  width: any;
  maxWidth: any;
  isLight: boolean;
  click: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  borderRadius?: string;
  isDisabled?: boolean;
  height?: string;
}) => {
  const design = useShopStore((state) => state?.shopData?.shopDesign);
  return (
    <Button
      w={width}
      height={height ? height : "auto"}
      maxW={maxWidth}
      p="8px 16px"
      color={design?.textColorParagraphs || '#FFFFFF'}
      bg={isLight ? "none" : "#161616"}
      border={isLight ? "1px solid" : "none"}
      borderRadius={borderRadius ? borderRadius : "4px"}
      fontFamily={design?.fontfamily || "Manrope"}
      fontSize="16px"
      fontWeight="400"
      borderColor={isLight ? "#FFFFFF" : "none"}
      onClick={click}
      _hover={{
        bg: isLight ? "#111" : "#fff",
        color: isLight ? "#fff" : "#111",
      }}
      isDisabled={isDisabled}
    >
      {loading ? <Spinner size="sm" thickness="2px" color={isLight ? "#FFFFFF" : "#FFFFFF"} /> : <>{children ? children : <></>}</>}
    </Button>
  );
};

export default ThemeTwoBasicButton;
