import { Box, ButtonProps, HStack, VStack } from '@chakra-ui/react'
import React from 'react'
import AppButton from '../form/button/AppButton'
import AppModal from '../modal/AppModal'
import AppTypography from '../typography/AppTypography'

interface IButtons {
    children: any,
    onClick: Function
    buttonProps?: ButtonProps
}

export interface IAppDialog {
    open: boolean
    close: Function
    text?: any
    title: string
    buttons: Array<IButtons>
}

function AppDialog({ open, close, text, title, buttons }: IAppDialog) {
    return (
        <AppModal isOpen={open} onClose={close} props={{ modal: { size: "lg", isCentered: true } }}>
            <VStack color="#FFF" align="stretch" spacing="15px">
                <AppTypography fontSize='20px' fontWeight='bold'>{title}</AppTypography>
                {text && <AppTypography fontSize='14px' whiteSpace="pre-wrap">{text}</AppTypography>}
                <HStack justifyContent="end">
                    {buttons && buttons.map((el: IButtons, key) => (
                        <Box key={key}><AppButton onClick={() => {
                            el.onClick()
                            close()
                        }} {...el.buttonProps}>{el.children}</AppButton></Box>
                    ))}
                </HStack>
            </VStack>
        </AppModal>
    )
}

export default AppDialog