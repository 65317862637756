import { Box, Flex, Link } from '@chakra-ui/react';
import React from 'react'
import navigationCasperModel from './model';

function NavigationCasper() {
    const { links } = navigationCasperModel

    return (
        <Flex gap={{ base: "10px", lg: "20px", xl: "30px" }} alignItems={{ base: "end", lg: "center" }}>
            {links.map((el, key) => (
                <>
                    {key !== 0 && <Box borderLeft="1px solid #FFF" padding={{ base: "10px 0", lg: "16px 0" }}></Box>}
                    <Box key={key}>
                        <Link href={el.link} color="#FFF" fontSize={{ base: "12px", lg: "14px", xl: "16px" }} fontFamily="Montserrat, sans-serif" target="_blank">{el.text}</Link>
                    </Box>
                </>
            ))}
        </Flex>
    )
}

export default NavigationCasper