import { Flex, Icon, Input } from '@chakra-ui/react';
import useDebounce from 'hooks/useDebounce/useDebounce';
import { useContext, useEffect, useState } from 'react';
import { IoSearch } from "react-icons/io5";
import homepageDefaultContext from '../../../../context';

function SidebarSearch() {
    const { updateState, States: { theme: { sidebarBoxes, textColor } } } = useContext(homepageDefaultContext)
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm)

    useEffect(() => {
        updateState('search', debouncedSearchTerm)
    }, [debouncedSearchTerm])

    return (
        <Flex {...sidebarBoxes} alignItems="center" padding="5px 10px" borderRadius="8px">
            <Icon as={IoSearch} width="32px" height="32px" fill={textColor} />
            <Input color={textColor} width="100%" border="none" placeholder="Search ..." onChange={(e) => setSearchTerm(e.currentTarget.value)} boxShadow="unset !important" outline="none" />
        </Flex>
    )
}

export default SidebarSearch