import { Box, VStack } from '@chakra-ui/react'
import { useShopStore } from 'lib/stores/shop/shop'
import { Outlet, useLocation } from 'react-router-dom'
import InitialStore from './parts/initialStore/InitialStore'
import AppDevBar from "components/common/devbar/AppDevBar";
import Header from './parts/header/Header'
import Footer from './parts/footer/Footer'
import { useEffect } from 'react';
import useAppTheme from 'hooks/_refactor/theme/useAppTheme';

function AppLayout() {
    const { shopDesign: { backgroundBody }, shopData: { template_options } } = useShopStore()
    const isNotfound = useLocation().pathname.includes('not-found')
    const { isDefaultTheme } = useAppTheme()

    // Change background as zustand
    useEffect(() => {
        document.body.style.backgroundColor = isDefaultTheme && backgroundBody ? backgroundBody : '#f9f9f6'
        return () => { document.body.style.backgroundColor = '' }
    }, [backgroundBody, isDefaultTheme])

    return (
        <InitialStore>
            <AppDevBar />
            <VStack align="stretch" spacing="0" minHeight="100%" position="relative">
                {!isNotfound && <Header />}
                <Box minHeight="70vh" paddingBottom="50px" {...template_options?.['--dlk-wrp']?.['--dlk-wrp-styles']}><Outlet /></Box>
                {!isNotfound && <Footer />}
            </VStack>
        </InitialStore>
    )
}

export default AppLayout