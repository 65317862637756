import { Button, ButtonProps } from '@chakra-ui/react'
import { useShopStore } from 'lib/stores/shop/shop';
import React from 'react'
import appButtonModel from './model';

export interface IAppButton extends ButtonProps { }
function AppButton(props: IAppButton) {
    const { shopTheme, shopDesign } = useShopStore();
    const { themes, variants } = appButtonModel
    const variant: any = props.variant

    return (
        <Button
            padding="20px 10px"
            {...themes(shopDesign)[shopTheme]}
            {...props?.variant && ['outline'].includes(variant) && variants(shopDesign)[shopTheme][variant]}
            {...props}>
            {props.children}
        </Button>
    )
}

export default AppButton