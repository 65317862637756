import { ButtonProps, FlexProps, TextProps } from "@chakra-ui/react"
import { ShopThemeEnum } from "lib/models/shop"

interface Itheme {
    flex?: FlexProps
    button?: ButtonProps
    text?: TextProps
}

interface IthemesOutput {
    [propname: string]: Itheme
}

namespace giftCardDefaultModel {
    const theme: IthemesOutput = {
        default: {
            flex: {
                direction: { base: "column", md: "row" },
                gap: { base: "8px", md: "17px" }
            },
            button: {
                height: { base: "40px", md: "50px" }
            }
        },
        custom: {
            flex: {
                flexDirection: "column",
                gap: "10px"
            },
            button: {
                height: { base: "40px", md: "40px" }
            },
            text: {
                backgroundColor: "#FFF !important" 
            }
        },
    }
    export const themes: IthemesOutput = ({
        [ShopThemeEnum.THEME_1]: theme.default,
        [ShopThemeEnum.THEME_2]: theme.default,
        [ShopThemeEnum.THEME_SKALE]: theme.custom,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: theme.custom,
        [ShopThemeEnum.THEME_CASPER]: theme.custom,
        [ShopThemeEnum.THEME_POLYGON]: theme.custom,
    })
}

export default giftCardDefaultModel