import { Flex, VStack } from "@chakra-ui/react";
import { getOrdersService } from "apis/orders/services";
import AppContainer from "components/common/container/AppContainer";
import { useShopStore } from "lib/stores/shop/shop";
import { useMemo } from "react";
import { useQuery } from "react-query";
import purchaseHistoryModel from "./model";
import PurchaseHistoryHeader from "./parts/header/PurchaseHistoryHeader";
import PurchaseHistoryLoading from "./parts/loading/PurchaseHistoryLoading";
import PurchaseHistoryOrders from "./parts/orders/PurchaseHistoryOrders";

const PurchaseHistoryPage = () => {
    const { shopData, shopTheme } = useShopStore();
    const design = useMemo(() => purchaseHistoryModel.design(shopTheme, shopData), [shopTheme, shopData]);
    const { isLoading, error, data: orders, } = useQuery("purchase-history", () => getOrdersService({ page: 1, limit: 10 }), { refetchOnWindowFocus: false });

    return (
        <Flex justifyContent="center" padding="30px 0">
            <AppContainer props={{ flexDirection: "column" }}>
                <VStack color={design?.color} spacing="30px">
                    <PurchaseHistoryHeader />
                    {isLoading ? <PurchaseHistoryLoading /> : <PurchaseHistoryOrders orders={orders?.data.data} />}
                </VStack>
            </AppContainer>
        </Flex>
    );
};

export default PurchaseHistoryPage;
