interface ArrayProps {
    theme: string;
    component: any;
}

export const getCorrectComponent = (array: ArrayProps[], theme: string) => {
    const findObject = array.find((obj) => obj.theme === theme) || array[0];
    return findObject.component;
};

export const isCustomDomain = !['droplinked.io', 'localhost'].some(domain => window.location.origin.includes(domain));