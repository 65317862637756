import { Box, Flex, Image, SimpleGrid, useDisclosure, VStack } from '@chakra-ui/react'
import AppMagnifier from 'components/common/magnifier/AppMagnifier'
import AppTypography from 'components/common/typography/AppTypography'
import { useContext, useState } from 'react'
import productPageContext from '../../context'
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function ProductSlider() {
    const { states: { product } } = useContext(productPageContext)
    const [ImageSlider, setImageSlider] = useState(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <VStack align="stretch" spacing="20px">
            <AppMagnifier src={ImageSlider || product?.media.find((el: any) => el.isMain === "true")?.url} magnifierRadius={50} zoom={2} />
            <SimpleGrid columns={5} spacing="18px">
                {product?.media && product?.media?.length ? product?.media?.slice(0, 5).map((el: any, key: number) => (
                    <Box key={key} position="relative" onClick={() => setImageSlider(el.url)}>
                        {[product?.media?.length - 1, 4]?.includes(key) && (
                            <Flex alignItems="center" onClick={onOpen} cursor="pointer" justifyContent="center" textAlign="center" backgroundColor="rgba(0,0,0,.6)" position="absolute" top="0" left="0" right="0" bottom="0">
                                <AppTypography fontSize={{base: '8px',sm: '12px', md: '10px'}} style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>See More</AppTypography>
                            </Flex> 
                        )}
                        <Image src={el.url} borderRadius="8px" cursor={"pointer"} width="100%" />
                    </Box>
                )) : null}
            </SimpleGrid>
            <Lightbox plugins={[Thumbnails]} thumbnails={{ position: "bottom", border: 0, imageFit: "contain", gap: 0 }} open={isOpen} close={onClose} slides={product?.media && product?.media?.length ? product?.media?.map((el: any) => ({ src: el.url })) : []} />
        </VStack>
    )
}

export default ProductSlider