import { Button, Flex, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import { useCustomParams } from "hooks/useCustomParams/useCustomParams";

function ErrorPage() {
  const [hasError, setHasError] = useState(false);
  const { shopNavigate } = useCustomNavigate();
  useEffect(() => {
    window.addEventListener("error", handleWindowError);
    return () => {
      window.removeEventListener("error", handleWindowError);
    };
  }, []);

  function handleWindowError() {
    setHasError(true);
  }

  const clickHandler = () => {
    localStorage.clear();
    shopNavigate("");
  };

  return (
    <Flex w="100%" justifyContent="center" alignContent="center" pt="150px">
      <Flex w="auto" justifyContent="center" flexDir="column">
        <Text
          fontFamily="Montserrat"
          fontSize="54px"
          fontWeight="700"
          w="100%"
          textAlign="center"
        >
          Oops
        </Text>
        <Text
          fontFamily="Montserrat"
          fontSize="36px"
          fontWeight="500"
          w="100%"
          textAlign="center"
          mb="40px"
        >
          Seems like something went wrong
        </Text>

        <Button
          fontFamily="Montserrat"
          fontWeight="400"
          size="16px"
          color="white"
          bg="#0d67fe"
          p="8px 24px 8px 24px"
          onClick={clickHandler}
        >
          Back To Home Page
        </Button>
      </Flex>
    </Flex>
  );

  // return null;
}

export default ErrorPage;
