import React from "react";
import { Stack, Divider, Hide, VStack } from "@chakra-ui/react";
import PrivacyNavbar from "./parts/privacyNavbar/PrivacyNavbar";
import PrivacyContent from "./parts/content/PrivacyContent";
import PrivacyHeader from "./parts/header/PrivacyHeader";
import AppSeperator from "components/common/seperator/AppSeperator";

function PrivacyPage() {
    return (
        <VStack>
            <PrivacyHeader/>
            <Stack direction={{ base: "column", md: "row" }} py={26}>
                <PrivacyNavbar />
                <Hide below="md">
                    <AppSeperator orientation={"vertical"} mt={"30px"} height="100vh" position={"sticky"} top={0} />
                </Hide>
                <PrivacyContent />
            </Stack>
        </VStack>
    );
}

export default PrivacyPage;
