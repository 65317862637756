import FooterCasper from "components/layout/app/parts/footer/themes/casper/FooterCasper"
import FooterDefault from "components/layout/app/parts/footer/themes/default/FooterDefault"
import HeaderCasper from "components/layout/app/parts/header/parts/themes/casper/HeaderCasper"
import HeaderDefault from "components/layout/app/parts/header/parts/themes/default/HeaderDefault"
import { ShopThemeEnum } from "lib/models/shop"

const themeLayouts = {
    header: {
        [ShopThemeEnum.THEME_1]: <HeaderDefault />,
        [ShopThemeEnum.THEME_2]: <HeaderDefault />,
        [ShopThemeEnum.THEME_SKALE]: <HeaderDefault />,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: <HeaderDefault />,
        [ShopThemeEnum.THEME_CASPER]: <HeaderCasper />,
        [ShopThemeEnum.THEME_POLYGON]: <HeaderDefault />,
    },
    footer: {
        [ShopThemeEnum.THEME_1]: <FooterDefault />,
        [ShopThemeEnum.THEME_2]: <FooterDefault />,
        [ShopThemeEnum.THEME_SKALE]: <FooterDefault />,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: <FooterDefault />,
        [ShopThemeEnum.THEME_CASPER]: <FooterCasper />,
        [ShopThemeEnum.THEME_POLYGON]: <FooterDefault />,
    }
}

export default themeLayouts