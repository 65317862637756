import { Box, HStack, Text, TextProps } from '@chakra-ui/react'
import AppSkeleton from 'components/common/skeleton/AppSkeleton'
import AppTypography from 'components/common/typography/AppTypography'
import React from 'react'
import AppInputLabelStar from './parts/required/AppInputLabelStar'

export interface IAppInputLabel {
    label: string
    isRequired?: boolean
    loading?: boolean
    color?: string
    textProps?: TextProps
}

function AppInputLabel({ isRequired, label, loading, color, textProps }: IAppInputLabel) {
    return (
        <>
            {label && (
                <Box width={"fit-content"}>
                    <AppSkeleton isLoaded={loading}>
                        <HStack>
                            <AppTypography fontSize='16px' fontWeight="600" {...textProps}>{label}</AppTypography>
                            {isRequired && <AppInputLabelStar />}
                        </HStack>
                    </AppSkeleton>
                </Box>
            )}
        </>
    )
}

export default AppInputLabel