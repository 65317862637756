import { Input, InputProps } from '@chakra-ui/react'
import { useShopStore } from 'lib/stores/shop/shop';
import React, { useMemo } from 'react'
import appFormMakerModel from '../../model'

export interface IAppInput extends InputProps { }
function AppInput(props: IAppInput) {
    const { shopTheme, shopDesign } = useShopStore();
    const theme = useMemo(() => appFormMakerModel.themes(shopDesign)[shopTheme], [shopTheme, shopDesign])

    return (
        <Input style={{ ...theme }} border={theme.border} _placeholder={{ color: theme.color, opacity: '.4' }} type="text" variant="nostyle" {...props} />
    )
}

export default AppInput