import { Link as ChakraLink } from "@chakra-ui/react";
import { useCustomParams } from "hooks/useCustomParams/useCustomParams";
import { isCustomDomain } from "lib/utils/theme/theme-utils";
import { Link } from "react-router-dom";

const FooterLinks = ({ link, caption, hyperlink }: { caption: string; link: string, hyperlink?: boolean }) => {
    const { shopName } = useCustomParams();
    return <>
        {link.includes(".") || hyperlink ? <ChakraLink href={link} target="_blank">{caption}</ChakraLink> : <Link to={!isCustomDomain ? `${shopName}/${link}` : link}>{caption}</Link>}
    </>;
};

export default FooterLinks;
