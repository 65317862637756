import { Box, chakra, Flex, Link } from "@chakra-ui/react";
import { useShopStore } from "lib/stores/shop/shop";
import React, { useMemo } from "react";
import policyModel from "./model";

const PolicyContent = () => {
    const shop = useShopStore();
    const { links, texts } = useMemo(() => policyModel.getColors(shop.shopTheme, shop.shopData), [shop.shopTheme, shop.shopData]);
    return (
        <Flex mt={26} mb="100px" justifyContent="flex-start" flexDirection={"column"} gap={16} py={12} px={{ base: 6, md: 24, lg: 56 }}>
            <chakra.section>
                <chakra.article fontFamily={"Montserrat"} color={texts}>
                    Any claims for misprinted/damaged/defective items must be submitted within 30 days after the product has been received. For packages lost in transit, all claims must be submitted
                    no later than 30 days after the estimated delivery date. Claims deemed an error on our part are covered at our expense.
                    <br />
                    <br />
                    If you or your customers notice an issue on the products or anything else on the order,{" "}
                    <chakra.span color={links} textDecor={"underline"}>
                        <Link href="https://help.printful.com/hc/en-us/articles/360014007620-how-do-i-report-a-problem-with-my-order-" target={"_blank"}>
                            please submit a problem report.
                        </Link>
                    </chakra.span>
                    <br />
                    <br />
                    The return address is set by default to the Printful facility. When we receive a returned shipment, an automated email notification will be sent to you. Unclaimed returns get
                    donated to charity after 30 days. If Printful's facility isn't used as the return address, you would become liable for any returned shipments you receive.
                    <br />
                    <br />
                    <chakra.span fontWeight={700}>Wrong Address</chakra.span> - If you or your end customer provide an address that is considered insufficient by the courier, the shipment will be
                    returned to our facility. You will be liable for reshipment costs once we have confirmed an updated address with you (if and as applicable).
                    <br />
                    <br />
                    <chakra.span fontWeight={700}>Unclaimed</chakra.span> - Shipments that go unclaimed are returned to our facility and you will be liable for the cost of a reshipment to yourself or
                    your end customer (if and as applicable).
                    <br />
                    <br />
                    If you haven't registered an account on{" "}
                    <chakra.span color={links} textDecor={"underline"}>
                        <Link href="https://www.printful.com/" target={"_blank"}>
                            printful.com
                        </Link>
                    </chakra.span>{" "}
                    and added a billing method, you hereby agree that any returned orders due to the wrong shipping address or a failure to claim the shipment won’t be available for reshipping and
                    will be donated to charity at your cost (without us issuing a refund).
                    <br />
                    <br />
                    Printful does not accept returns of sealed goods, such as but not limited to face masks, which are not suitable for return due to health or hygiene reasons. You hereby agree that
                    any returned orders with face masks won’t be available for reshipping and will be disposed of.
                    <br />
                    <br />
                    <chakra.span fontWeight={700}>Returned by Customer</chakra.span> - It is best to advise your end customers to contact you before returning any products. Except for Customers
                    residing in Brazil, we do not refund orders for buyer’s remorse. Returns for products, face masks, as well as size exchanges are to be offered at your expense and discretion. If
                    you choose to accept returns or offer size exchanges to your end customers, you would need to place a new order at your expense for a face mask or a product in another size.
                    <br />
                    Customers residing in Brazil and regretting a purchase must contact our Customer Service and express their will to return the item within 7 consecutive days after receiving it,
                    providing a picture of the item. The withdrawal request will undergo an evaluation to verify whether the product was used or destroyed, even if partial. In these cases, a refund
                    will not be possible.
                    <br />
                    <br />
                    <chakra.span fontWeight={700}>Notification for EU consumers:</chakra.span> According to Article 16(c) and (e) of the Directive 2011/83/EU of the European Parliament and of the
                    Council of 25 October 2011 on consumer rights, the right of withdrawal may not be provided for:
                    <br />
                    <br />
                    1. the supply of goods that are made to the consumer's specifications or are clearly personalized;
                    <br />
                    <br />
                    2. sealed goods which were unsealed after delivery and thus aren't suitable for return due to health protection or hygiene reasons, therefore Printful reserves rights to refuse
                    returns at its sole discretion.
                    <br />
                    <br />
                    This Policy shall be governed and interpreted in accordance with the English language, regardless of any translations made for any purpose whatsoever.
                    <br />
                    <br />
                    For more info on returns, <chakra.span fontWeight={700}>please read our</chakra.span>{" "}
                    <chakra.span color={links} textDecor={"underline"} fontWeight={700}>
                        <Link href="https://help.printful.com/hc/en-us/sections/360004088720-returns" target={"_blank"}>
                            FAQs.
                        </Link>
                    </chakra.span>
                </chakra.article>
            </chakra.section>
        </Flex>
    );
};

export default PolicyContent;
