import { ShopThemeEnum } from "lib/models/shop";

interface ComponentObject {
  theme: ShopThemeEnum;
  component: JSX.Element;
}

export function getComponentByTheme(
  theme: ShopThemeEnum,
  components: ComponentObject[]
): JSX.Element | null {
  const matchingComponent = components.find(
    (componentObj) => componentObj.theme === theme
  );
  return matchingComponent ? matchingComponent.component : null;
}
