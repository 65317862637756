import { FlexProps, TextProps } from "@chakra-ui/react"
import { ShopThemeEnum } from "lib/models/shop"

interface Itheme {
    flex?: FlexProps
    typo?: TextProps
}

interface IthemesOutput {
    [propname: string]: Itheme
}

namespace checkoutShippingProductModel {
    const theme: IthemesOutput = {
        default: {
            flex: { alignItems: { base: "center", lg: "self-start" } },
            typo: { fontSize: "12px" }
        },
        custom: {
            flex: { alignItems: "center" },
            typo: { fontSize: { base: "12px", sm: "16px" }, fontWeight: "bold" }
        },
    }
    export const themes: IthemesOutput = ({
        [ShopThemeEnum.THEME_1]: theme.default,
        [ShopThemeEnum.THEME_2]: theme.default,
        [ShopThemeEnum.THEME_SKALE]: theme.custom,
        [ShopThemeEnum.THEME_UNSTOPPABLE]: theme.custom,
        [ShopThemeEnum.THEME_CASPER]: theme.custom,
        [ShopThemeEnum.THEME_POLYGON]: theme.custom,
    })
}

export default checkoutShippingProductModel