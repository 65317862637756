import { FlexProps, TextProps } from "@chakra-ui/react"
import { IShopDesign, ShopThemeEnum } from "lib/models/shop"

interface IThemes {
    [propname: string]: {
        button: FlexProps
        typography?: TextProps
    }
}

interface ITheme {
    shopDesign: IShopDesign
}

namespace appWalletsModel {
    export const theme = ({ shopDesign }: ITheme) => {
        const themes: IThemes = {
            defaults: {
                button: {
                    backgroundColor: shopDesign.backgroundBody,
                    justifyContent: "flex-start"
                }
            },
            unstoppable: {
                button: {
                    backgroundColor: '#0d67fe',
                    justifyContent: "center",
                },
                typography: {
                    color: "#FFF",
                    fontWeight: "bold"
                }
            },
            other: {
                button: {
                    backgroundColor: '#FFF',
                    justifyContent: "center"
                }
            }
        }

        return {
            [ShopThemeEnum.THEME_1]: themes.defaults,
            [ShopThemeEnum.THEME_2]: themes.defaults,
            [ShopThemeEnum.THEME_SKALE]: themes.other,
            [ShopThemeEnum.THEME_UNSTOPPABLE]: themes.unstoppable,
            [ShopThemeEnum.THEME_CASPER]: themes.other,
            [ShopThemeEnum.THEME_POLYGON]: themes.other
        }
    }
}

export default appWalletsModel