import { HStack } from "@chakra-ui/react";
import AppTypography from "components/common/typography/AppTypography";
import { useCustomNavigate } from "hooks/useCustomNavigate/useCustomNavigate";
import React from "react";
import { Link } from "react-router-dom";

const CartPageHeader = () => {
    const { route } = useCustomNavigate();
    return (
        <HStack justify={'space-between'}>
            <AppTypography fontSize={{base: '18px',md:'26px'}} fontWeight='bold'>Your cart</AppTypography>
            <Link to={route('')}><AppTypography fontSize={{ base: "10px", sm: "16px" }} textDecor={"underline"}>Continue Shopping</AppTypography></Link>
        </HStack>
    )
};

export default CartPageHeader;
