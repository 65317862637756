import { IUser, WALLET_TYPES } from "lib/models/user";

export function convertToUserModel(data: any): IUser {
    const user: IUser = {
        walletAddress: data?.xrplSidechainAddress || data?.baseAddress || data?.lineaAddress || data?.casperAddress || data?.stacksAddress || data?.unstoppableDomainAddress || data?.polygonAddress || data?.rippleAddress || data?.binanceAddress || data?.nearAddress || data?.xummAddress || null,
        customerShop: data.customerShop || "",
        email: data?.email || null,
        emailNotificationEnabled: data?.emailNotificationEnabled,
        publicKey: data.publicKey || "",
        status: data.status || "",
        walletType: data?.walletType || "",
        stripeCustomerID: data?.stripeCustomerID || "",
        _id: data?._id || "",
    };

    return user;
}

export const getChain = (walletType: WALLET_TYPES) => {
    switch (walletType) {
        case WALLET_TYPES.CASPER:
            return 0;
        case WALLET_TYPES.POLYGON:
            return 1;
        case WALLET_TYPES.BINANCE:
            return 2;
        case WALLET_TYPES.XRPLSIDECHAIN:
            return 4;
        case WALLET_TYPES.NEAR:
            return 5;
        case WALLET_TYPES.BASE:
            return 7;
        case WALLET_TYPES.LINEA:
            return 8;
        default:
            return 1;
    }
};
