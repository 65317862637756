namespace navigationCasperModel {
    export const links = [
        { text: "Gen 0", link: "https://casperpunks.io/gen0boxes" },
        { text: "Mystery Box", link: "https://casperpunks.io/mysterybox" },
        { text: "Gen 1", link: "https://casperpunks.io/gen1boxes" },
        { text: "My Portfolio", link: "https://casperpunks.io/portfolio" },
        { text: "Gen 1 Attributes", link: "https://casperpunks.io/attributes" },
    ]
}

export default navigationCasperModel